import React, { useEffect, useState } from 'react'
import Header from '../components/Header/PanelHeader'
import Sidebar from '../components/Sidebar/PanelSidebar'
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { RentalPanel, ServicerPanel } from './SidebarObjects';
import { useDispatch, useSelector } from 'react-redux';
import DynamicPopup from '../utils/Popup/DynamicPopup';
import LogoutLoader from './LogoutLoader';
import NotificationChecker from './NotificationChecker';
import BookAppointment from '../components/RenatalPanelComp/Dashboard/BookAppointment';
import Feedback from '../components/GenericComp/Feedback';
import { database } from '../config/Firebase';
import { ref, onValue, off, get, onChildAdded, } from 'firebase/database';
import { setUsers } from '../Redux/UserAuthSlice/UserAuthSlice';
import { GetUsers } from '../components/GenericComp/GetcChatUser';
const Wrapper = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [property, setproperty] = useState({});
    const [type, setType] = useState("");
    const { userRole, userid } = useSelector((state) => state.userAuth.userInfo)


    const location = useLocation().pathname
    const id = useParams().id

    const dispatch = useDispatch()

    useEffect(() => {
        if (!userid) return; // Ensure user ID exists
        debugger
        const messagesRef = ref(database, `Messages/${userid}`);
        const chatListener = onValue(messagesRef, async (snapshot) => {
            const conversations = snapshot.val();
            if (!conversations) return; // Early return if no conversations exist

            let ConversationArr = Object.values(conversations);
            let userListArr = []; // Array to accumulate users

            // Fetch chats for all conversations
            const fetchChats = async () => {

                for (let index = 0; index < ConversationArr?.length; index++) {
                    const element = ConversationArr[index];
                    const chatRef = ref(database, `Chat/${element.chatID}`);
                    try {
                        const chatSnapshot = await get(chatRef); // Fetch messages for each chat
                        if (chatSnapshot.exists()) {
                            const chats = chatSnapshot.val();
                            let unReadCount = 0;

                            // Count unread messages
                            Object.values(chats).forEach((chat) => {
                                console.log("chat", chat)
                                if (chat?.isRead === false && chat?.receiverID === userid) {
                                    unReadCount++;
                                }
                            });

                            console.log("count" , unReadCount)

                            // Determine whether current user is sender or receiver
                            let isCurrentUserSender = userid === element?.senderID;
                            let otherUserId = isCurrentUserSender ? element?.receiverID : element?.senderID;

                            // Fetch user details
                            let userList = await GetUsers(otherUserId);

                            // Build the conversation object
                            let conversationObj = {
                                chatID: element?.chatID,
                                lastMessage: element?.lastMessage,
                                image: userList?.image,
                                first_name: userList?.first_name,
                                receiverID: element?.receiverID,
                                senderID: element?.senderID,
                                timestamp: element?.timestamp,
                                unReadCount: unReadCount // Include unread count
                            };
                            // Accumulate user conversation objects
                            userListArr.push(conversationObj);
                        } else {
                            console.log("No chat data available");
                        }
                    } catch (error) {
                        console.error("Error fetching chat data:", error);
                    }
                }
                console.log("userListArr", userListArr)
                dispatch(setUsers(userListArr));
            };

            // Fetch chats asynchronously
            fetchChats();

        }, (error) => {
            console.error("Error fetching messages:", error);
        });

        // Cleanup listener on unmount
        return () => {
            off(messagesRef, 'value', chatListener);
        };
    }, [userid, database, location, dispatch]);

    // useEffect(() => {
    //     if (!userid) return; // Ensure user ID exists
    //     const messagesRef = ref(database, `Messages/${userid}`);

    //     const processConversation = async (element) => {
    //         const chatRef = ref(database, `Chat/${element.chatID}`);
    //         try {
    //             const chatSnapshot = await get(chatRef); // Fetch messages for each chat
    //             if (chatSnapshot.exists()) {
    //                 const chats = chatSnapshot.val();
    //                 let unReadCount = 0;
    //                 Object.values(chats).forEach((chat) => {
    //                     if (chat?.isRead === false && chat?.receiverID === userid) {
    //                         unReadCount++;
    //                     }
    //                 });
    //                 let isCurrentUserSender = userid === element?.senderID;
    //                 let otherUserId = isCurrentUserSender ? element?.receiverID : element?.senderID;
    //                 let userList = await GetUsers(otherUserId);
    //                 let conversationObj = {
    //                     chatID: element?.chatID,
    //                     lastMessage: element?.lastMessage,
    //                     image: userList?.image,
    //                     first_name: userList?.first_name,
    //                     receiverID: element?.receiverID,
    //                     senderID: element?.senderID,
    //                     timestamp: element?.timestamp,
    //                     unReadCount: unReadCount // Include unread count
    //                 };
    //                 return conversationObj;
    //             } else {
    //                 console.log("No chat data available");
    //             }
    //         } catch (error) {
    //             console.error("Error fetching chat data:", error);
    //         }
    //         return null;
    //     };

    //     const chatListener = onChildAdded(messagesRef, async (snapshot) => {
    //         const conversations = snapshot.val();
    //         console.log("new message", conversations)
    //         if (!conversations) return;
    //         debugger
    //         let ConversationArr = Object.values(conversations);
    //         let userListArr = [];

    //         const fetchChats = async () => {
    //             const promises = ConversationArr.map(async (element) => processConversation(element));
    //             const results = await Promise.all(promises);
    //             userListArr = results.filter(conversation => conversation !== null);
    //             dispatch(setUsers(userListArr));
    //         };

    //         fetchChats();
    //     }, (error) => {
    //         console.error("Error fetching messages:", error);
    //     });

    //     return () => {
    //         off(messagesRef, 'child_added', chatListener);
    //     };
    // }, [userid, database, dispatch]);
    // ;




    return (
        <>
            {show && <DynamicPopup modalOpen={show} data={type === "logout" ? <LogoutLoader setShow={setShow} /> : type === "appointment" ? <BookAppointment property={property} /> : type === "feedback" ? <Feedback setShow={setShow} /> : <NotificationChecker data={property} setShow={setShow} setType={setType} type={type} />} close={() => setShow(false)} />}
            <div className="h-screen overflow-hidden max-w-[2000px] m-auto  ">
                <Header setShow={setShow} sidebarOpen={sidebarOpen} setType={setType} setproperty={setproperty} setSidebarOpen={setSidebarOpen} />
                <div className="lg:grid lg:grid-cols-5 bg-white">
                    <div className={`lg:block lg:h-screen lg:pb-20 bg-[#fcf8f4]  lg:w-full border-r overflow-y-auto pt-10 `} >
                        <Sidebar list={userRole === "servicer" ? ServicerPanel : RentalPanel} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} setShow={setShow} setType={setType} />
                    </div>
                    <div className={`col-span-4 overflow-y-auto ${location === `/message/${id}` ? 'h-auto' : 'h-screen'} overflow-x-hidden mb-20 pb-20 pt-10`} >
                        <div className='lg:px-5 px-5 py-5 relative h-full'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Wrapper