import React, { useEffect, useState } from 'react'
// import { BsFillTagFill } from 'react-icons/bs'
// import star from '../../assests/images/star.png'
// import king from '../../assests/images/king.png'
import flash from '../../assests/images/flash.png'
import Purchase from '../../components/ServicerPanelComp/Flash/Purchase'
import { GetPrices, GetSubscription } from '../../lib/Api/RentalAppApi'
import { Loader } from '../../utils/loaderButton'
import { GetPricesServicer } from '../../lib/Api/RantelServiceApi'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FlashSidebar from '../RentalPanelPages/FlashSidebar'
import ServicerFlashpass from './FlashPass'
import PropertiesList from './PropertiesList'
import { t } from 'i18next'
// import DynamicPopup from '../../utils/Popup/DynamicPopup'
// import PostAppHowWork from '../../components/GenericComp/PostAppHowWork'
// import { useLocation } from 'react-router-dom'
const Flash = () => {
    const [packages, setPackage] = useState("")
    const [property, setProperty] = useState("")
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [priecs, setPrices] = useState([])
    const [allRentals, setAllRentals] = useState([])
    const [purchaseInfo, setPurchaseInfo] = useState({})

    const location = useLocation().pathname
    // const navigate = useNavigate()


    let { userRole } = useSelector((state) => state?.userAuth?.userInfo)

    let fetchRenterSubsc = async () => {
        setLoadingStatus(true)
        let { res } = await GetSubscription()
        if (res) {
            setLoadingStatus(false)
            setPurchaseInfo(res)
        }
    }

    let fetchRenterFlashLane = async () => {
        setLoading(true)
        let { res } = await GetPrices()
        if (res) {
            setLoading(false)
            setPrices(res)
        } else {
            setLoading(false)
        }
    }
    let fetchServicerFlashLane = async () => {
        setLoading(true)
        let { res } = await GetPricesServicer()
        if (res) {
            setLoading(false)
            setPrices(res)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (userRole === "renter") {
            fetchRenterSubsc()
            fetchRenterFlashLane()
        }
        else {
            fetchRenterSubsc()
            fetchServicerFlashLane()
        }

    }, [])


    return (
        <>
            {
                loadingStatus ? <div className="pt-5"><Loader type="loadData" /> </div>
                    : purchaseInfo?.purchased === true ?
                        <>
                            {location === "/flash" ?
                                <FlashSidebar info={purchaseInfo} />
                                :
                                <div className=''>
                                    <ServicerFlashpass
                                        setPurchaseInfo={(val) => setPurchaseInfo(val)}
                                        info={purchaseInfo}
                                        allRentals={allRentals} />
                                </div>
                            }
                        </>
                        :
                        <>
                            {
                                packages ? <Purchase
                                    data={packages}
                                    property={property}
                                    setPackage={() => setPackage(false)} />
                                    :
                                    <>
                                        {property === "" && location === "/servicer_flash" ?
                                            <PropertiesList setProperty={setProperty} setAllRentals={setAllRentals} />
                                            :
                                            <div className=' lg:max-w-[550px] m-auto bg-cover   h-full w-full  '>
                                                <div className='lg:p-[3rem] p-[20px]'>
                                                    <h2 className='text-[30px] font-semibold text-center'>{t('welcom_flash')}</h2>
                                                    <h2 className='text-[15px] text-center cursor-pointer'>{t('skip_sr')}</h2>

                                                    <h2 className='text-[23px] font-semibold'>{t('how_work')}</h2>
                                                    <div className='pt-4'>
                                                        <h2 className='text-[15px] '>{t('flash_desc')}</h2>
                                                    </div>
                                                    <ul className='my-3'>
                                                        {
                                                            location !== "/flash" || location === "/servicer_flash" &&
                                                            <li className='flex items-center '>
                                                                <img src={flash} alt="flash" />
                                                                <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('reazy_inf')}</h2>
                                                            </li>
                                                        }
                                                        <li className='flex items-center my-2'>
                                                            <img src={flash} alt="flash" />
                                                            <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('flash_book')}</h2>
                                                        </li>
                                                        <li className='flex items-center my-2 '>
                                                            <img src={flash} alt="flash" />
                                                            <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('submit_rent')}</h2>
                                                        </li>
                                                        <li className='flex items-center '>
                                                            <img src={flash} alt="flash" />
                                                            <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('have_all')}</h2>
                                                        </li>
                                                    </ul>
                                                    <h2 className='text-[23px] font-semibold'>{t('pricing')}</h2>
                                                    <ul className='my-2'>
                                                        {

                                                            !loading && priecs?.map((list, i) => {
                                                                let addDot = list?.unit_amount_decimal / 100
                                                                return (
                                                                    <li key={i} onClick={() => setPackage(list)} className='border cursor-pointer p-1 px-2  flex justify-between items-center bg-gradient-to-r from-[#FFC000] to-orange-500 h-full rounded-full '>
                                                                        <div className='flex mx-3 items-center'>
                                                                            <img src={list?.metadata?.image} className='object-cover w-6 h-6' alt="icon_img" />
                                                                            <div className='flex flex-col items-start '>
                                                                                <h2 className='text-white mx-1 text-[18px]'>{
                                                                                    list?.metadata?.name
                                                                                }</h2>
                                                                                <p className='text-[12px] text-white px-2'>{list?.metadata?.desc}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <p className='text-white px-2'>${addDot}</p>
                                                                        </div>
                                                                    </li>


                                                                )
                                                            })

                                                        }
                                                        {loading ? <Loader type="loadData" /> : ""}
                                                    </ul>
                                                </div>

                                            </div>
                                        }
                                    </>
                            }

                        </>
            }
        </>
    )
}

export default Flash