import React from 'react'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { API } from '../../config/GoolePlace';

const containerStyle = {
    width: '100%',
    height: '400px'
};

function GoogleMapTacker({ loc }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API
    })
    const center = {
        lat: 41.55221,
        lng: -72.64076
    };
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // if (loc && loc.length > 0) {
        //     bounds.extend(center);
        // }
        // map.fitBounds(bounds);
        map.setCenter(center)
        map.setZoom(13)
        setMap(map);
    }, [loc, center]);
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{ streetViewControl: false, mapTypeControl: false, zoomControl: false, fullscreenControl: false }}
        >
            <MarkerF position={center}></MarkerF>
        </GoogleMap>
    ) : <></>
}

export default React.memo(GoogleMapTacker)