import React from 'react'
// import card from '../../assests/images/master.png'
// import { BsPlus } from 'react-icons/bs'
import AddPaymentPopup from '../ServicerPanelComp/Flash/AddPaymentPopup'
import { useState, useEffect } from 'react'
import { GetBillingDetail, GetSessionCheckout } from '../../lib/Api/RentalAppApi'
import { useSelector } from 'react-redux'
import { Loader } from '../../utils/loaderButton'
import { stripePromise } from '../../config/Stripe'
import { useTranslation } from 'react-i18next'
// import { AddPayment } from '../../Redux/UserAuthSlice/RentalSlice'
const Purchase = ({ type }) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingSession, setLoadingSession] = useState(false)
    const [bills, setBills] = useState({})
    const user = useSelector((state) => state.userAuth?.userInfo)

    // const dispatch = useDispatch()

    const websiteLink = window.location.origin;



    const handleClick = async () => {
        const stripe = await stripePromise;
        setLoadingSession(true)
        let { res } = await GetSessionCheckout(websiteLink)
        let updatedData = Object.assign({}, ...res)
        const result = await stripe.redirectToCheckout({
            sessionId: updatedData?.checkout_session?.id,
        });
        setLoadingSession(false)
        if (result.error) {
        }

    };

    useEffect(() => {
        let checkApp = async () => {
            setLoading(true)
            let { res } = await GetBillingDetail()
            if (res) {
                setLoading(false)
                setBills(res[0])
            }
            else {
                setLoading(false)
            }
        }
        checkApp()
    }, [])


    const { t } = useTranslation()

    return (
        <>
            <AddPaymentPopup open={open} setOpen={setOpen} />

            <div className='p-5 max-w-[550px] m-auto'>
                <div className=''>
                    <h2 className='text-[16px]'>{user?.fname} {user?.lname}</h2>
                    <p>{user?.address}</p>
                    <p>{user?.email}</p>
                </div>
                <div>
                    {/* <h2 className='font-medium text-[18px] py-2'>Payment Method</h2> */}

                    {/* <div className='flex items-center justify-between border rounded-md my-2 px-2 py-2 bg-white shadow-md'>
                        <div className='flex '>
                            <img src={card} alt="card" />
                            <div className='px-2'>
                                <h2 className='text-[18px] font-medium'>Master Card 8048</h2>
                                <p className='text-gray-400'>Exp: 11/26</p>
                            </div>
                        </div>
                        <div>
                            <input type="radio" />
                        </div>
                    </div>
                    <div className='flex items-center py-2'>
                        <div className='bg-[#F79F15] p-1 rounded-md cursor-pointer' onClick={() => setOpen(true)}>
                            <BsPlus className='text-white text-[20px]' />
                        </div>
                        <h2 className='px-2 text-[18px]'>Add New Card</h2>
                    </div> */}

                    {
                        loading ?
                            <Loader type="loadData" />
                            :
                            <div>
                                <h2 className='font-medium text-[20px] py-2'>{t('bill_de')}</h2>

                                <div className="bg-gray-300 rounded-md p-4">
                                    <div className='flex justify-between items-center'>
                                        <div>
                                            <h2 className='text-[18px] font-semibold'>{type === "rental_app" ? t('rental_application') : t('flash_lane')}</h2>
                                            {/* <p className='text-[14px]'>Valid up to 11/26/22</p> */}
                                        </div>
                                        <h2 className='text-[18px] text-[#F79F15]'>${bills?.price}</h2>
                                    </div>

                                    <div className='flex justify-between items-center pt-6 pb-2'>
                                        <div>
                                            <h2 className='text-[18px] font-semibold'>{t('tax_lbl')}</h2>
                                        </div>
                                        <h2 className='text-[18px] text-[#F79F15]'>${bills?.tax}</h2>
                                    </div>
                                    <div className="w-full border border-gray-500"></div>

                                    <div className='flex justify-between items-center pt-6 pb-2'>
                                        <div>
                                            <h2 className='text-[18px] font-semibold'>{t('total_lbl')}</h2>
                                        </div>
                                        <h2 className='text-[18px] text-[#F79F15]'>${bills?.total}</h2>
                                    </div>
                                </div>

                                <div className='my-4'>
                                    {
                                        loadingSession ? <Loader type="saved" />
                                            :
                                            <button onClick={() => handleClick()} className='w-full  py-3 bg-[#F79F15] text-white text-[18px] rounded-md'>{t('pur_lbl')}</button>
                                    }
                                </div>


                            </div>
                    }

                </div>
            </div>
        </>
    )
}

export default Purchase