import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    language: "en",
    locateDirect: "all",
    isNotLeasedBuild: false,
    LeasedBuild: false,
    ConfirmData: null,
    isLeasedCreate: { page: "", data: false },
    removeId: "",
    leasebuildData: {},
    chatUsers :[],
    location: {},
    loginInfo: {
        token: null,
    },
    userInfo: {
        fname: null,
        lname: null,
        email: null,
        city: null,
        countryState: null,
        phone: null,
        zipcode: null,
        address: null,
        userid: null,
        userimg: null,
        userRole: null,
        CreatedAt: null,
        id_verified_at:null
    },


}
export const UserAuthSlice = createSlice({
    name: 'UserAuthSlice',
    initialState,
    reducers: {
        changeLang: (state, action) => {
            state.language = action.payload
        },

        signin: (state, action) => {
            state.loginInfo.token = action?.payload?.token
            state.userInfo.fname = action?.payload?.user?.first_name
            state.userInfo.lname = action?.payload?.user?.last_name
            state.userInfo.email = action?.payload?.user?.email
            state.userInfo.phone = action?.payload?.user?.phone_number
            state.userInfo.city = action?.payload?.user?.city
            state.userInfo.countryState = action?.payload?.user?.state
            state.userInfo.zipcode = action?.payload?.user?.zip
            state.userInfo.address = action?.payload?.user?.address
            state.userInfo.userimg = action?.payload?.user?.user_image
            state.userInfo.userid = action?.payload?.user?.id
            state.userInfo.userRole = action?.payload?.user?.role
            state.userInfo.CreatedAt = action?.payload?.user?.created_at
            state.userInfo.idVerified = action?.payload?.user?.id_verified_at

        },
        updateUser: (state, action) => {
            state.userInfo.fname = action?.payload?.user?.first_name
            state.userInfo.lname = action?.payload?.user?.last_name
            state.userInfo.email = action?.payload?.user?.email
            state.userInfo.phone = action?.payload?.user?.phone_number
            state.userInfo.city = action?.payload?.user?.city
            state.userInfo.countryState = action?.payload?.user?.state
            state.userInfo.zipcode = action?.payload?.user?.zip
            state.userInfo.address = action?.payload?.user?.address
            state.userInfo.userimg = action?.payload?.user?.user_image
            state.userInfo.userid = action?.payload?.user?.id
            state.userInfo.userRole = action?.payload?.user?.role
            state.userInfo.CreatedAt = action?.payload?.user?.created_at
            state.userInfo.idVerified = action?.payload?.user?.id_verified_at
        },
        setlocation: (state, action) => {
            state.location = action?.payload
        },

        setLocateNotification: (state, action) => {
            state.locateDirect = action?.payload
        },
        setUploadLease: (state, action) => {
            state.isNotLeasedBuild = action?.payload
        },
        setUploadLeaseData: (state, action) => {
            state.leasebuildData = action?.payload
        },
        setRemoveId: (state, action) => {
            state.removeId = action?.payload
        },
        setLeased: (state, action) => {
            state.LeasedBuild = action?.payload
        },
        setUsers: (state, action) => {
            state.chatUsers = action?.payload
        },
        setIsLeaseCreate: (state, action) => {
            state.isLeasedCreate = action?.payload
        },
        setConfirmData: (state, action) => {
            state.ConfirmData = action?.payload
        },
        signout: (state) => {
            return {
                language: state.language,
                locateDirect: "all",
                removeId: "",
                ConfirmData: null,
                leasebuildData: null,
                isLeasedCreate: false,
                isNotLeasedBuild: false,
                LeasedBuild: false,
                loginInfo: {
                    token: null
                },
                location: {},
                userInfo: {
                    fname: null,
                    lname: null,
                    email: null,
                    city: null,
                    countryState: null,
                    phone: null,
                    zipcode: null,
                    address: null,
                    userid: null,
                    userimg: null,
                    userRole: null,
                    CreatedAt: null,


                }
            }
        },

    },
})

// Action creators are generated for each case reducer function
export const { changeLang, signin, signout, updateUser, setlocation, setLocateNotification, setUploadLease, setUploadLeaseData, setRemoveId, setLeased, setIsLeaseCreate,
    setConfirmData , setUsers } = UserAuthSlice.actions

export default UserAuthSlice.reducer