import React from 'react'
import Layout from '../../components/Layout/Layout'
import keys from '../../assests/images/keys.png'
import hands from '../../assests/images/hands.png'
import keys2 from '../../assests/images/key2.png'
import hands2 from '../../assests/images/hand2.png'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const Rent = () => {

    const location = useLocation().pathname

    const { t } = useTranslation()

    
    return (
        <Layout>
            <div className="bg-[#F5F6FC] w-full h-full pb-8">
                <div className="container mx-auto">

                    {location === "/rent" ?
                        <div className="grid lg:grid-cols-2 gap-5  pt-[3rem] ">
                            <Link to={`/rent/rentals`} className='group'>
                                <div className='bg-white group-hover:bg-gradient-to-r from-[#F79F15]  to-[#FFC000]   shadow-md h-full  max-w-[500px] mx-auto rounded-md flex justify-center flex-col items-center p-6'>
                                    <img src={keys} className="w-[30%] group-hover:hidden block" alt="keys" />
                                    <img src={keys2} className="w-[30%] group-hover:block hidden" alt="keys" />
                                    <h2 className='text-[2rem] font-bold group-hover:text-white'>{t('servicer_rentals')}</h2>
                                    <p className='text-center pb-6 group-hover:text-white'>
                                        {t('rent_rental')}
                                    </p>

                                </div>
                            </Link>
                            <Link to={`/rent/rommate`} className='group'>
                                <div className='bg-white  group-hover:bg-gradient-to-r from-[#F79F15]  to-[#FFC000]   shadow-md h-full  max-w-[500px] mx-auto rounded-md flex justify-center flex-col items-center p-6'>
                                    <img src={hands} className="w-[30%] group-hover:hidden block" alt="keys" />
                                    <img src={hands2} className="w-[30%] group-hover:block hidden" alt="keys" />
                                    <h2 className='text-[2rem] font-bold group-hover:text-white'>{t('rommates')}</h2>
                                    <p className='text-center pb-6 group-hover:text-white'>
                                        {/* {t('rent_rommate')} */}
                                        {t('rent_rommate')}
                                    </p>
                                </div>
                            </Link>
                        </div>
                        :
                        <div className="grid lg:grid-cols-2 gap-5  pt-[3rem] ">
                            <Link to={`/list/rentals `} className='group'>
                                <div className='bg-white group-hover:bg-gradient-to-r from-[#F79F15]  to-[#FFC000]   shadow-md h-full  max-w-[500px] mx-auto rounded-md flex justify-center flex-col items-center p-6'>
                                    <img src={keys} className="w-[30%] group-hover:hidden block" alt="keys" />
                                    <img src={keys2} className="w-[30%] group-hover:block hidden" alt="keys" />
                                    <h2 className='text-[2rem] font-bold group-hover:text-white'>{t('servicer_rentals')}</h2>
                                    <p className='text-center pb-6 group-hover:text-white'>
                                    {t('list1_rental')}
                                    </p>
                                </div>
                            </Link>
                            <Link to={`/list/rommate`} className='group'>
                                <div className='bg-white  group-hover:bg-gradient-to-r from-[#F79F15]  to-[#FFC000]   shadow-md h-full  max-w-[500px] mx-auto rounded-md flex justify-center flex-col items-center p-6'>
                                    <img src={hands} className="w-[30%] group-hover:hidden block" alt="keys" />
                                    <img src={hands2} className="w-[30%] group-hover:block hidden" alt="keys" />
                                    <h2 className='text-[2rem] font-bold group-hover:text-white'>{t('rommates')}</h2>
                                    <p className='text-center pb-6 group-hover:text-white'>{
                                        t('list1_rommate')}</p>
                                </div>
                            </Link>
                        </div>
                    }
                </div>
            </div>

        </Layout >
    )
}

export default Rent