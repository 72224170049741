import React from 'react'
import { useTranslation } from 'react-i18next'

const Shop = ({ shopData }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className="container mx-auto mt-[4rem]">
                <h2 className='text-center font-bold text-[2rem] my-9'>{t('web_rental_11')}</h2>
                {
                    shopData?.lists.map((item, i) => (
                        <div className="grid lg:grid-cols-2 " key={i}>
                            {
                                <>
                                    <div className={`${i % 2 ? 'lg:order-0 order-1' : 'lg:order-1 order-1'}  lg:pt-[12rem] py-5 mx-6 `}>
                                        <h2 className='text-[28px] font-bold '>{item?.name}</h2>
                                        <p className='py-2 max-w-[550px]'>{item?.title}</p>

                                        <button className='px-8 py-2 border-2 rounded-md my-2 text-[18px] border-[#F79F15] text-[#F79F15] hover:bg-[#F79F15] hover:text-white'>{t('web_rental_20')}</button>
                                    </div>
                                    <div className={`relative group cursor-pointer ${i % 2 ? 'lg:order-1 order-0' : 'order-0'}`}>
                                        <img src={item.hoverImg} alt="shop_image" />
                                        <div className='absolute bg-black group-hover:opacity-[0.3] opacity-0 top-0 border w-full h-full'></div>
                                        <div className='absolute top-[40%] text-white group-hover:opacity-100 opacity-0 left-[25%] flex flex-col justify-center items-center'>
                                            <img src={item.original} alt="hover_image" />
                                            <h2 className='py-2 font-bold text-[2rem]'>{item.name}</h2>
                                        </div>
                                    </div>
                                </>


                            }
                        </div>

                    ))
                }
            </div>

        </>
    )
}

export default Shop