import React, { useEffect, useState } from 'react'
import Choose from './Choose'
import Condition from './Condition'
import Information from './Information'
import Aminities from './Aminities'
import LeasedPeriod from './LeasedPeriod'
import UploadFiles from './UploadFiles'
import { CreateRantel } from '../../../lib/Api/RantelServiceApi'
import { Loader } from '../../../utils/loaderButton'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import DynamicPopup from '../../../utils/Popup/DynamicPopup'
// import IdVerified from '../../GenericComp/IdVerified'
import { useSelector } from 'react-redux'
const RegisterServicer = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeIndexTab, setActiveIndexTab] = useState([1])
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({})
  const [back, setBack] = useState(false)
  const [uplaodData, setUploadData] = useState({})
  const [showVerify, setShowVerify] = useState(false)
  const isVerify = useSelector((state) => state.userAuth.userInfo?.idVerified)

  // const [address, setAddress] = useState({})

  // const [imagesData, setImagesData] = useState({})
  // const [prvIndex, setPrvIndex] = useState([{index: 0 , value: true  }])

  const handleNext = (data) => {
    setState((prevState) => {
      // Update the state first
      const updatedState = {
        ...prevState,
        ...data
      };
      return updatedState
    })
    let incValue = activeIndex + 1
    setActiveIndexTab((prev) => [...prev, incValue])
    setActiveIndex(incValue)
  }

  const handleBack = () => {
    let incValue = activeIndex - 1
    let removeIndex = activeIndexTab.filter((_, i) => i !== incValue)
    setActiveIndexTab(removeIndex)
    setActiveIndex(incValue)
  }


  const handleSubmit = async (e) => {
    setLoading(true)
    // state.images = imagesData.images
    // state.video = imagesData.video
    let { res, error } = await CreateRantel(state);
    if (res) {
      setLoading(false)
      setState({})
      setActiveIndex([1])
      // setActiveIndex(0)
      setBack(true)
    }
    else if (error) {
      setLoading(false)
      setActiveIndex([1])
      setActiveIndex(0)
    }
    setLoading(false)
  }


  useEffect(() => {
      if (isVerify === null) {
          setShowVerify(true)
      }
  }, [isVerify])

  useEffect(() => {
    if (activeIndex === 6) {
      handleSubmit()
    }
  }, [activeIndex])

  const {t} = useTranslation()

  return (
    <>
      
    
      { back && <Navigate to="/servicerPanel" />}

      <div className='flex flex-wrap justify-center items-center lg:px-14 px-1 lg:mb-12 p-8 mb-5'>

        {
          Array(6).fill(6).map((_, i) => (
            <>
              {i > 0 && <div  className={`${activeIndexTab[i] ? 'bg-[#F79F15] ' : 'bg-gray-100'} md:w-[25%] md:-ml-5 h-1 lg:w-[10%] w-[10.6%]`}  ></div>}
              <div className='lg:w-[4%] w-[8%] text-center relative' key={i}>
                <div className={`${activeIndexTab[i] ? 'rounded-full border bg-[#F79F15] text-white' : 'bg-white'} pt-2 text-sm w-[40px] h-[40px] rounded-full border  font-bold text-[17px]`}>{i + 1}</div>
              </div>
            </>
          ))
        }

        <div className='py-5 w-full'>
          <div className='pt-[4rem] '>
            {activeIndex === 0 ? <Choose handleNext={(data) => handleNext(data)} State={state} t={t} />
              : activeIndex === 1 ? <Condition handleNext={(data) => handleNext(data)} handleBack={handleBack} State={state}   t={t}/>
                : activeIndex === 2 ? <LeasedPeriod handleNext={(data) => handleNext(data)} handleBack={handleBack} State={state} t={t} />
                  : activeIndex === 3 ? <Information handleNext={(data) => handleNext(data)} handleBack={handleBack} State={state} t={t}  />
                    : activeIndex === 4 ? <Aminities handleNext={(data) => handleNext(data)} handleBack={handleBack} State={state} t={t} />
                      : activeIndex === 5 ? <UploadFiles uplaodData={uplaodData} setUploadData={setUploadData} loading={loading} handleNext={(data) => handleNext(data)} handleBack={handleBack} State={state}  t={t}/>
                        : <Loader type="loadData" />
            }
          </div>

        </div>

      </div>

    </>
  )
}

export default RegisterServicer