import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { database } from '../../config/Firebase';
import { ref, onValue, push, update, off, set, child, get, remove } from 'firebase/database';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { GetUsers } from '../../components/GenericComp/GetcChatUser';
import { Truncate } from '../../utils/TrucateString';
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import { IoArrowBackOutline } from "react-icons/io5";
const Mesaages = () => {
    const [users, setUsers] = useState([]);
    const [users2, setUsers2] = useState([]);
    const [messages, setMessages] = useState([]);
    const [TopUsers, setTopUsers] = useState([]);
    const [TopUsersArr, setTopUsersArr] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [singleUser, setSingleUser] = useState({});
    const [singleMessage, setSingleMessage] = useState({});
    const [loading, setLoading] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(false)
    const [UnReadCount, setUnReadCount] = useState(0);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const chatContainerRef = useRef(null);
    const ContRef = useRef(null);
    const reciverid = useParams().id
    const location = useLocation().pathname

    const user = useSelector((state) => state?.userAuth?.userInfo)
    const userInfo = useSelector((state) => state?.rental?.rentalUserInfo)
    const lng = useSelector((state) => state?.userAuth?.language)
    const allUsers = useSelector((state) => state?.userAuth?.chatUsers)


    const { t } = useTranslation()

    const calculateHeight = () => {
        if (windowSize.width === 1366) return 300;
        if (windowSize.width === 1517) return 350;
        return windowSize.height - 200;
    };

    function sanitizeUserId(userId) {
        return userId.toString().replace(/[.#$[\]]/g, '');
    }

    async function createUser(userId, data) {
        const newUserData = {
            "first_name": data?.first_name,
            "image": data?.image,
            "last_name": data?.last_name,
            "user_type": data?.user_type
        };
        const userRef = ref(database, `user/${userId}`);
        await set(userRef, newUserData);

    }

    const userExist = async (e) => {
        if (newMessage.trim() !== '') {
            let generateIdMessage = Math.floor(100000 + Math.random() * 900000);
            const utcTime = moment.utc().format('');
            let senderId = user?.userid;
            let receiverId = Number(reciverid)
            let find = users?.find((f) => f.reciverID === receiverId)
            const messagesRef = ref(database, 'Messages');
            const receiverPath = `${user?.userid}/${singleUser?.chatID}`;
            const receiverNodeRef = child(messagesRef, receiverPath);
            const receiverSnapshot = await get(receiverNodeRef);
            if (receiverSnapshot?.exists()) {
                const utcTime = moment().utc().format('YYYY-MM-DD HH:mm:ss +0000')
                await update(receiverNodeRef, { lastMessage: newMessage, timestamp: utcTime, messagesCount: singleMessage?.messagesCount + 1 })
                    .then(() => {
                        //console.log("Data updated successfully.");
                    })
                    .catch((error) => {
                        //console.error("Data could not be updated.", error);
                    });
            } else {

                // const utcTime = moment.utc().toISOString();
                const initialMessage = {
                    chatID: `${generateIdMessage}`,
                    lastMessage: newMessage,
                    messagesCount: 0,
                    senderID: user?.userid,
                    receiverID: Number(reciverid),
                    timestamp: moment().utc().format('YYYY-MM-DD HH:mm:ss +0000')
                };
                const chatMessage = ref(database, `Messages/${user?.userid}`);
                const snapshotReciver = await get(chatMessage);
                if (snapshotReciver.exists()) {
                    // If the node exists, add a new message
                    const newMessageRef = ref(database, `Messages/${user?.userid}/${generateIdMessage}`);
                    await set(newMessageRef, initialMessage);

                } else {
                    // If the node does not exist, create a new node
                    await set(chatMessage, { [generateIdMessage]: initialMessage });
                }

                // const chatMessageRef = ref(database, `Messages/${user?.userid}`);
                // await set(chatMessageRef, { [generateIdMessage]: initialMessage });
            }

            const receiverPath2 = `${receiverId}/${singleUser?.chatID}`;
            const receiverNodeRef2 = child(messagesRef, receiverPath2);
            const receiverSnapshot2 = await get(receiverNodeRef);
            if (receiverSnapshot2?.exists()) {
                const utcTime = moment().utc().format('YYYY-MM-DD HH:mm:ss +0000')
                await update(receiverNodeRef2, { lastMessage: newMessage, timestamp: utcTime, messagesCount: singleMessage?.messagesCount + 1 })
                    .then(() => {
                        //console.log("Data updated successfully.");
                    })
                    .catch((error) => {
                        //console.error("Data could not be updated.", error);
                    });

            } else {
                // const utcTime = moment.utc().toISOString();
                const initialMessage = {
                    chatID: `${generateIdMessage}`,
                    lastMessage: newMessage,
                    messagesCount: 0,
                    senderID: user?.userid,
                    receiverID: Number(reciverid),
                    timestamp: moment().utc().format('YYYY-MM-DD HH:mm:ss +0000')
                };
                const chatMessage = ref(database, `Messages/${receiverId}`);
                const snapshotReciver = await get(chatMessage);
                // if (snapshotReciver.exists()) {
                //     // If the node exists, add a new message
                //     const newMessageRef = ref(database, `Messages/${receiverId}/${generateIdMessage}`);
                //     await set(newMessageRef, initialMessage);

                // } else {
                // If the node does not exist, create a new node
                await set(chatMessage, { [generateIdMessage]: initialMessage });
                // }

            }
            // Update the last message for the receiver
            if (receiverSnapshot?.exists() && receiverSnapshot2?.exists()) {
                const chatRef = ref(database, `Chat/${singleUser?.chatID}`);
                const newMessageRef = push(chatRef);
                // const utcTime = moment.utc().format('YYYY-MM-DD HH:mm:ss +0000');
                const newMessages = {
                    message: newMessage,
                    isRead: false,
                    senderID: senderId,
                    receiverID: receiverId,
                    time: moment().utc().format('YYYY-MM-DD HH:mm:ss +0000')
                };

                set(newMessageRef, newMessages)
                    .then(() => {
                        console.log("Message sent successfully.");
                    })
                    .catch((error) => {
                        console.error("Error sending message:", error);
                    });


                // Clear the input field or update state as needed
            }
            else {
                const chatRef = ref(database, `Chat/${generateIdMessage}`);
                const newMessageRef = push(chatRef);
                // const utcTime = moment.utc().toISOString();
                const newMessages = {
                    message: newMessage,
                    isRead: false,
                    senderID: senderId,
                    receiverID: receiverId,
                    time: moment().utc().format('YYYY-MM-DD HH:mm:ss +0000')
                };

                set(newMessageRef, newMessages)
                    .then(() => {
                        console.log("Message sent successfully.");
                    })
                    .catch((error) => {
                        console.error("Error sending message:", error);
                    });

            }
            setNewMessage('');

        }
    }

    const handleSendMessage = async (e) => {
        e.preventDefault()
        const usersRef = ref(database, 'user');
        let generateIdMessage = Math.floor(100000 + Math.random() * 900000);

        const senderID = sanitizeUserId(user.userid);
        const receiverID = sanitizeUserId(reciverid);
        // Check if sender exists
        const userSnapshot = await get(child(usersRef, senderID));
        if (!userSnapshot.exists()) {
            // If sender does not exist, create user
            const newUserData = {
                "first_name": user?.fname,
                "image": user?.userimg,
                "last_name": user?.lname,
                "user_type": user?.userRole
            };
            await createUser(senderID, newUserData);
        }
        // Check if receiver exists
        const reciverSnapshot = await get(child(usersRef, receiverID));
        if (!reciverSnapshot.exists()) {
            // If sender does not exist, create user
            const newUserData = {
                "first_name": userInfo?.first_name,
                "image": userInfo?.user_image,
                "last_name": userInfo?.last_name,
                "user_type": userInfo?.role
            };
            await createUser(receiverID, newUserData);
        }
        if (!userSnapshot.exists() && !reciverSnapshot.exists()) {
            // await push(chatRef);
            // await push(messagesRef);
            const initialMessage = {
                chatID: `${generateIdMessage}`,
                lastMessage: newMessage,
                messagesCount: 0,
                senderID: user?.userid,
                receiverID: Number(reciverid),
                timestamp: moment().utc().format('YYYY-MM-DD HH:mm:ss +0000')
            };

            const chatMessage = ref(database, `Messages/${reciverid}`);
            const snapshotReciver = await get(chatMessage);
            if (snapshotReciver.exists()) {
                // If the node exists, add a new message
                const newMessageRef = ref(database, `Messages/${reciverid}/${generateIdMessage}`);
                await set(newMessageRef, initialMessage);

            } else {
                // If the node does not exist, create a new node
                await set(chatMessage, { [generateIdMessage]: initialMessage });
            }

            // Check if the node already exists
            const chatMessageSenderRef = ref(database, `Messages/${user?.userid}`);
            const snapshot = await get(chatMessageSenderRef);
            if (snapshot.exists()) {
                // If the node exists, add a new message
                const newMessageRef = ref(database, `Messages/${user?.userid}/${generateIdMessage}`);
                await set(newMessageRef, initialMessage);

            } else {
                // If the node does not exist, create a new node
                await set(chatMessageSenderRef, { [generateIdMessage]: initialMessage });
            }

            const initialChat = {
                message: newMessage,
                isRead: false,
                senderID: user?.userid,
                receiverID: Number(reciverid),
                time: moment().utc().format('YYYY-MM-DD HH:mm:ss +0000')
            };
            const chatRef = push(ref(database, 'Chat'));
            const chatMessageRef = ref(database, `Chat/${generateIdMessage}`);
            await set(chatMessageRef, { [chatRef.key]: initialChat });
            setNewMessage('');
        }
        else {
            userExist()
        }

    }


    useEffect(() => {
        const messagesRef = ref(database, 'Messages');
        const chatListener = onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            if (data && data[user?.userid]) {
                const userArray = Object.keys(data[user?.userid]).map((key) => ({
                    userId: key,
                    ...data[user?.userid][key],
                }));
                setUsers(userArray);
                setSingleMessage(userArray[0])
            } else {
                setUsers([]);
            }
        }, (error) => {
            //console.error('Error fetching user messages:', error);
            setUsers([]); // Handle the error by setting users to an empty array
        });

        return () => {
            off(messagesRef, 'value', chatListener);
        };
    }, [user?.userid]);

    useEffect(() => {
        const messagesRef = ref(database, 'Messages');
        const chatListener = onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            if (data && data[user?.userid]) {
                const userArray = Object.keys(data[user.userid])
                    .map((key) => ({
                        userId: key,
                        ...data[user.userid][key],
                    }))
                    .filter((message) => message.messagesCount > 0)
                    .sort((a, b) => b.messagesCount - a.messagesCount) // Sort by messagesCount in descending order
                    .slice(0, 5); // Take the top 5 users
                setTopUsersArr(userArray)
            } else {
                setUsers([]);
            }
        }, (error) => {
            //console.error('Error fetching user messages:', error);
            setUsers([]); // Handle the error by setting users to an empty array
        });

        return () => {
            off(messagesRef, 'value', chatListener);
        };
    }, [user?.userid]);

    let findUser = async () => {
        let updatedUsersArray = [];
        try {
            for (let index = 0; index < users?.length; index++) {
                const element = users[index];
                let check = Number(user.userid) === element?.receiverID ? element?.senderID : element?.receiverID
                let userdata = await GetUsers(check);
                if (userdata) {
                    updatedUsersArray.push({ ...element, ...userdata });
                } else {
                }
            }
            updatedUsersArray.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            setUsers2(updatedUsersArray)
            setLoading(false)
        } catch (error) {
            //console.error('Error finding user:', error.message);
        }
    };

    let fetch = async () => {
        let updatedUsersArray = [];
        for (let index = 0; index < TopUsersArr?.length; index++) {
            const element = TopUsersArr[index];
            let check = Number(user.userid) === element?.receiverID ? element?.senderID : element?.receiverID
            let userdata = await GetUsers(check);
            if (userdata) {
                updatedUsersArray.push({ ...element, ...userdata });
            } else {

            }
        }
        setTopUsers(updatedUsersArray)
    }

    useEffect(() => {
        findUser()
        fetch()
    }, [users])


    useEffect(() => {
        const chatRef = ref(database, 'Chat');
        setLoadingMessages(true)
        debugger
        let find = users?.find((f) => f.receiverID === Number(reciverid) || f.senderID === Number(reciverid))
        let find2 = users2?.find((f) => f.receiverID === Number(reciverid) || f.senderID === Number(reciverid))
        if (reciverid == find?.receiverID || reciverid == find?.senderID) {
            setSingleUser(find2 || find)
            get(chatRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        if (data && data[find?.chatID]) {
                            const messagesArray = Object.keys(data[find?.chatID]).map((key) => ({
                                messageId: key,
                                ...data[find?.chatID][key],
                            }));
                            messagesArray.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
                            const groupedMessages = {};
                            let unReadCount = 0;
                            Object.values(data).forEach((chat) => {
                                if (chat?.isRead === false) {
                                    unReadCount++;
                                }
                            });
                            if (unReadCount > 0) {
                                setUnReadCount(unReadCount);
                            }
                            console.log("unread", unReadCount)
                            messagesArray.forEach((message) => {
                                const date = new Date(message.time).toLocaleDateString();
                                if (!groupedMessages[date]) {
                                    groupedMessages[date] = [];
                                }
                                groupedMessages[date].push(message);
                                if (message.receiverID === user?.userid && !message.isRead) {
                                    const messageRef = ref(database, `Chat/${find.chatID}/${message.messageId}`);
                                    update(messageRef, { isRead: true });
                                }
                            });
                            setLoadingMessages(false)
                            setMessages(groupedMessages);
                        } else {
                            setMessages([]);
                        }
                    }
                });
        } else {
            setMessages([]);
        }

    }, [location, reciverid, users]);

    useEffect(() => {
        if (ContRef.current) {
            ContRef.current.scrollTop = ContRef.current.scrollHeight;
        }
    }, [reciverid, location, messages]);




    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDel = async () => {

        const userRef = ref(database, `Messages/1139`);
        try {
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                console.log("Data before deletion:", snapshot.val()); // To check what data exists
                await remove(userRef);
                console.log(`Messages under 'Messages/420689' have been deleted.`);
            } else {
                console.log("No data exists at this path.");
            }
        } catch (error) {
            console.error("Error deleting messages:", error);
        }
    }

    moment.locale(lng === 'sp' ? 'es' : 'en');
    let singleUserInfo = users2.find((f) => f.senderID === Number(reciverid) || f.receiverID === Number(reciverid))


    console.log("messages", messages)

    return (
        <div className=' '>
            <div className='xl:flex lg:flex md:flex hidden  items-center xl:mt-10 lg:mt-10'>
                {
                    TopUsers?.map((userlist, index) => {
                        let check = Number(user.userid) === userlist?.receiverID ? userlist?.senderID : userlist?.receiverID
                        return (
                            <Link to={`/message/${check}`} key={index} className='flex justify-center items-center flex-col'>
                                <div className='w-[60px] shadow-md h-[60px] mx-2 flex justify-center items-center border rounded-full'>
                                    <img src={userlist?.image} alt="user_img" className='rounded-full w-[60px] h-[60px]' />
                                </div>
                                <div>
                                    <p className='text-center'>{userlist?.first_name}</p>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
            <div>
                <button className='border px-3' onClick={() => handleDel()}>Delete</button>
            </div>
            <div className="xl:grid lg:grid md:grid hidden lg:grid-cols-4 gap-2 ">
                <div className="bg-[white] rounded  border-0 ">
                    <div className=" px-2 flex items-center   ">
                        <div className="w-full overflow-y-auto overflow-x-hidden h-auto  user_list">
                            {
                                users2?.length > 0 ? users2?.map((userlist) => {
                                    const timeAgo = moment.utc(userlist?.timestamp)
                                        .fromNow();
                                    const translatedTimeAgo = t(timeAgo);
                                    let check = Number(user.userid) === userlist?.receiverID ? userlist?.senderID : userlist?.receiverID
                                    return (
                                        <button
                                            // onClick={}
                                            className={` ${Number(reciverid) === check && 'bg-gray-100'} hover:bg-gray-100 w-full  border-b-2  p-2 mb-2`}
                                        >
                                            <Link to={`/message/${check}`} className='w-100 flex   justify-between'>
                                                <div className='flex'>
                                                    <div
                                                        className="flex items-center justify-center border h-8 w-8 bg-indigo-200 rounded-full"
                                                    >
                                                        <img src={userlist?.image || userlist?.image} alt="user" className='rounded-full w-8 h-8' />
                                                    </div>
                                                    <div className='flex flex-col items-start'>
                                                        <h2 className="text-[12px] px-2 leading-[18px] font-semibold text-[#444444] truncate  lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full">
                                                            {userlist.first_name !== "" ? Truncate(userlist?.first_name, 15) : "N/A"}
                                                        </h2>
                                                        <div className='px-2'>
                                                            <p className="text-[12px] text-[#898989] leading-[18px] truncate lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full ">
                                                                {Truncate(userlist?.lastMessage, 15)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <h2 className="text-[#98A2B3] text-[10px] flex items-center">
                                                        {t(translatedTimeAgo)}
                                                    </h2>
                                                    {/* {userlist?.unReadCount > 0 &&
                                                        <div className='bg-red-500 text-center rounded-full w-6 h-6 pt-[2px] text-white'>
                                                            {userlist?.unReadCount}
                                                        </div>
                                                    } */}
                                                </div>

                                            </Link>
                                        </button>
                                    )
                                })
                                    : ""
                                // : <Loader type=""/>
                            }
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-3 ">
                    <div className='flex items-center bg-gray-200 h-[50px] px-2 rounded-md'>
                        <div className="h-[40px] w-[40px]  rounded-full overflow-hidden">
                            <img
                                src={singleUserInfo?.image || userInfo?.user_image}
                                alt="user"
                                className="object-cover  h-[40px] w-[40px]"
                            />
                        </div>
                        <div className='px-3'>
                            <h2 className="lg:text-[16px] lg:leading-[39px] text-[18px] font-semibold text-[#444444]">
                                {singleUserInfo ? `${singleUserInfo?.first_name} ${singleUserInfo?.last_name}` : `${userInfo?.first_name}${userInfo?.last_name}`}
                            </h2>
                            {/* {convId?.isOnline && (
                                <p className="text-[12px] text-[#898989] flex items-center leading-[18px]">
                                    <p className="w-[10px] mr-2 h-[10px] bg-[#06A551] rounded-full border-0" />
                                    Online
                                </p>
                            )} */}
                        </div>
                    </div>
                    <div
                        className={`bg-[white] relative p-5 rounded border-l `}
                    >
                        <div className="flex flex-col flex-auto overflow-y-auto "
                            style={{
                                height: `${calculateHeight()}px`, // Applying dynamic height based on the screen size
                                maxHeight: '100vh', // Ensure it never exceeds viewport height
                            }}
                            ref={ContRef}
                        >
                            {Object.keys(messages).map((date) => (
                                <>
                                    <div className={`pt-5 flex justify-between items-center`}>
                                        <p className='border border-[#AEB2C0] opacity-[0.5] w-full' />
                                        <p className='text-[12px] text-[#444444] w-[50%] text-center'>{date}</p>
                                        <p className='border border-[#AEB2C0] opacity-[0.5] w-full' />
                                    </div>

                                    <div className="flex flex-col" >
                                        {messages ? messages[date].map((message) => {
                                            return (
                                                <div className="grid grid-cols-12"  >
                                                    {
                                                        message?.senderID !== user?.userid ?
                                                            <div className={"col-start-1 lg:col-end-8 md:col-end-8 col-end-13 p-3 rounded-lg"} >
                                                                <div className="flex flex-row ">
                                                                    <div
                                                                        className=""
                                                                    >
                                                                        <img src={singleUserInfo?.image || singleUser?.image} alt="user" className='object-cover border lg:w-[40px] lg:h-[40px] md:w-[40%] w-[40%] rounded-full' />
                                                                    </div>
                                                                    <div className=''>
                                                                        <div
                                                                            className="relative ml-3 text-sm bg-gray-200 py-2 px-4 lg:w-auto w-full shadow rounded-xl rounded-bl-none"
                                                                        >
                                                                            <div
                                                                                style={{ whiteSpace: 'pre-line' }}
                                                                                className='text-[#444444] text-[14px]'>{message?.message}</div>
                                                                        </div>
                                                                        <div className='flex justify-start items-center pt-2 pl-4'>
                                                                            <p className='text-[#9F9F9F] text-[12px] '>{moment(message?.timestamp).format(
                                                                                "LT"
                                                                            )}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="lg:col-start-6 md:col-start-6 col-start-1 col-end-13 p-3 rounded-lg">
                                                                <div className="flex  justify-start flex-row-reverse">
                                                                    <div
                                                                        className=""
                                                                    >
                                                                        <img src={user?.userimg} alt="user" className='object-cover border lg:w-[40px] lg:h-[40px] md:w-[40%] w-[60%] rounded-full' />
                                                                    </div>
                                                                    <div className=' mr-3'>
                                                                        <div
                                                                            className="relative text-sm bg-[#F79F15] rounded-br-none  py-2 px-4 shadow rounded-xl"
                                                                        >
                                                                            <div className='text-white text-[14px]'> {message?.message}</div>
                                                                        </div>
                                                                        <div className='flex justify-end items-end pt-2 pl-4'>
                                                                            <p className='text-[#9F9F9F] text-[12px] '>
                                                                                {moment(message?.timestamp).format(
                                                                                    "LT"
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                </div>
                                            )
                                        })
                                            : <div>{t('no_con')}</div>
                                        }

                                    </div>

                                </>
                            ))}
                            {/* <form onSubmit={handleSendMessage}> */}
                            <div
                                className="flex flex-row fixed bottom-0  w-[54%] bg-white    items-center  "
                            >

                                <div className="flex-grow">
                                    <div className="relative w-full">
                                        <textarea
                                            type="text"
                                            rows={2}
                                            placeholder={t('Type_here')}
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            className="flex w-full border rounded-xl focus:outline-none  pl-4 "
                                        />

                                    </div>
                                </div>
                                <div className="ml-2">
                                    <button
                                        onClick={(e) => handleSendMessage(e)}
                                        type='submit'
                                        className="flex items-center justify-center bg-[#F79F15]  rounded-full text-white px-4 py-4 flex-shrink-0"
                                    >
                                        <span className="flex justify-center items-center">
                                            <svg
                                                className="w-5 h-5 transform rotate-45 -mt-px"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                                ></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>


            {/* mobile responsive */}
            <div className='xl:hidden lg:hidden md:hidden block'>
                {

                    <>
                        <div className='pb-3'>
                            <Link to="/userList">
                                <IoArrowBackOutline size={20} onClick={() => setShowInfo(false)} />
                            </Link>
                        </div>
                        <div className='flex items-center bg-gray-200 h-[50px] px-2 rounded-md'>
                            <div className="h-[40px] w-[40px]  rounded-full overflow-hidden">
                                <img
                                    src={singleUserInfo?.image || userInfo?.user_image}
                                    alt="user"
                                    className="object-cover  h-[40px] w-[40px]"
                                />
                            </div>
                            <div className='px-3'>
                                <h2 className="lg:text-[16px] lg:leading-[39px] text-[18px] font-semibold text-[#444444]">
                                    {singleUserInfo ? `${singleUserInfo?.first_name} ${singleUserInfo?.last_name}` : `${userInfo?.first_name}${userInfo?.last_name}`}
                                </h2>
                            </div>
                        </div>
                        <div className={`  relative  p-5 rounded  overflow-y-auto `} ref={chatContainerRef}>
                            <div className="flex flex-col flex-auto " >
                                {Object.keys(messages).map((date) => (
                                    <>
                                        <div className={`pt-5 flex justify-between items-center`}>
                                            <p className='border border-[#AEB2C0] opacity-[0.5] w-full' />
                                            <p className='text-[12px] text-[#444444] w-[50%] text-center'>{date}</p>
                                            <p className='border border-[#AEB2C0] opacity-[0.5] w-full' />
                                        </div>

                                        <div className="flex flex-col" >
                                            {messages ? messages[date].map((message) => {
                                                return (
                                                    <div className="grid grid-cols-12"  >
                                                        {
                                                            message?.senderID !== user?.userid ?
                                                                <div className={"col-start-1 lg:col-end-8 md:col-end-8 col-end-13  rounded-lg"} >
                                                                    <div className="flex flex-row ">
                                                                        <div
                                                                            className="w-[50px]"
                                                                        >
                                                                            <img src={singleUserInfo?.image || singleUser?.image} alt="user" className='object-cover border w-[43px] h-[43px] rounded-full' />
                                                                        </div>
                                                                        <div className=''>
                                                                            <div
                                                                                className="relative ml-3 text-sm bg-gray-200 py-2 px-4 lg:w-auto w-full shadow rounded-xl rounded-bl-none"
                                                                            >
                                                                                <div
                                                                                    style={{ whiteSpace: 'pre-line' }}
                                                                                    className='text-[#444444] text-[14px]'>{message?.message}</div>
                                                                            </div>
                                                                            <div className='flex justify-start items-center pt-2 pl-4'>
                                                                                <p className='text-[#9F9F9F] text-[12px] '>{moment(message?.timestamp).format(
                                                                                    "LT"
                                                                                )}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="lg:col-start-6 md:col-start-6 col-start-1 col-end-13  rounded-lg">
                                                                    <div className="flex  justify-start flex-row-reverse">
                                                                        <div
                                                                            className="w-[50px]"
                                                                        >
                                                                            <img src={user?.userimg} alt="user" className='object-cover border w-[43px] h-[43px] rounded-full' />
                                                                        </div>
                                                                        <div className=' mr-3'>
                                                                            <div
                                                                                className="relative text-sm bg-[#F79F15] rounded-br-none  py-2 px-4 shadow rounded-xl"
                                                                            >
                                                                                <div
                                                                                    style={{ whiteSpace: 'pre-line' }}
                                                                                    className='text-white text-[14px]'> {message?.message}</div>
                                                                            </div>
                                                                            <div className='flex justify-end items-end pt-2 pl-4'>
                                                                                <p className='text-[#9F9F9F] text-[12px] '>
                                                                                    {moment(message?.timestamp).format(
                                                                                        "LT"
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }

                                                    </div>
                                                )
                                            })
                                                : <div>{t('no_con')}</div>
                                            }

                                        </div>

                                    </>
                                ))}
                                {/* <form onSubmit={}> */}
                                <div
                                    className="flex flex-row fixed bottom-1 w-[80%] bg-white    items-center  "
                                >

                                    <div className="flex-grow">
                                        <div className="relative w-full">
                                            <textarea
                                                type="text"
                                                rows={2}
                                                placeholder={t('Type_here')}
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                className="flex w-full border rounded-xl focus:outline-none  pl-4 "
                                            />

                                        </div>
                                    </div>
                                    <div className="ml-2">
                                        <button
                                            onClick={(e) => handleSendMessage(e)}
                                            type='submit'
                                            className="flex items-center justify-center bg-[#F79F15]  rounded-full text-white px-3 py-3 flex-shrink-0"
                                        >
                                            <span className="flex justify-center items-center">
                                                <svg
                                                    className="w-5 h-5 transform rotate-45 -mt-px"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </>

                }
            </div>
        </div>
    )
}

export default Mesaages