import React, { useState, useEffect } from 'react'
import { Loader } from '../../utils/loaderButton'
import { useTranslation } from 'react-i18next'
import {
    // GetCountProperty,
    GetRental,
    GetServicerMainFlashProperty,
    GetServicerMainReasyProperty
} from '../../lib/Api/RantelServiceApi'
import propImg from '../../assests/svg/home.svg'
import { Link, useLocation } from 'react-router-dom'
import Select from 'react-select'
const PropertiesList = ({ setProperty, setAllRentals, setAddmore }) => {
    const [loading, setLoading] = useState(false)
    // const [loadingPro, setLoadingPro] = useState(false)
    const [propertyList, setPropertyList] = useState([])
    // const [selectedList, setSelectList] = useState("")
    const [selectedProperty, setSelectProperty] = useState({})

    const location = useLocation().pathname

    useEffect(() => {
        let fetchServicer = async () => {
            setLoading(true)
            let res = await (location === "/servicer_flash" ? GetServicerMainFlashProperty() : GetServicerMainReasyProperty())
            if (res) {
                let obj = res && Object?.assign({}, ...res)
                setLoading(false)
                let itemList = obj.my_rentals?.map((list) => {
                    return {
                        value: list?.id,
                        label: `${list?.apt !== null ? `Apt #${list?.apt}` : ''} ${list?.complete_address}`
                    }
                })
                setPropertyList(itemList)
                setAllRentals(itemList)
            } else {
                setLoading(false)
            }
        }
        fetchServicer()
    }, [])


    // const handleGetProperty = async (id) => {
    //     if (location === "/flash" || location === "/servicer_flash") {
    //         let property = propertyList?.find((f) => f.id == id)
    //         setSelectProperty(property)
    //         setLoadingPro(true)
    //         let paylaod = {
    //             "property_id": id
    //         }
    //         let res = await GetCountProperty(paylaod)
    //         let obj = Object.assign({}, ...res)
    //         if (res) {
    //             setLoadingPro(false)
    //             setSelectList(obj?.count)
    //         }
    //         else {
    //             setLoadingPro(false)
    //         }
    //     }
    // }

    const handleProperty = () => {
        setProperty(selectedProperty)
        setAddmore(false)
    }

    const { t } = useTranslation()
    return (
        <>
            {loading ? <Loader type="loadData" /> :
                <>
                    {!loading && propertyList?.length > 0 &&
                        <div className="bg-white rounded-md mt-5 ">
                            <div className="h-full  ">

                                <h2 className='py-3 font-semibold text-[20px]'> {location === "/flash" || location === "/servicer_flash" ? t('add_flash') : t('add_reasy')}</h2>
                                {/* <select onChange={(e) => handleGetProperty(e.target?.value)} className='w-full border rounded-md px-3 py-2 focus:outline-none'>
                                    <option>Select Property</option>
                                    {
                                        !loading && propertyList?.map((list) => (
                                            <option key={list?.id} value={list?.id}>
                                                {`${list?.apt !== null ? `Apt #${list?.apt}` : ''} ${list?.complete_address}`}
                                            </option>
                                        ))
                                    }
                                </select> */}
                                <Select
                                    isMulti
                                    placeholder={t('please_select')}
                                    options={propertyList}
                                    onChange={setSelectProperty}
                                />
                                {/* {loadingPro ? <Loader type="loadData" /> :
                                    selectedList !== "" ?
                                        <div className='pt-2 flex items-center justify-between'>
                                            <p>{t('flash_que2')}</p>
                                            <div className='bg-white rounded-md px-4 py-2 border shadow-md'>{selectedList}</div>
                                        </div>
                                        : null
                                } */}
                                <div className='max-w-[300px] mx-auto py-5'>
                                    <button disabled={selectedProperty !== null ? false : true} onClick={() => handleProperty()} className={` ${location === "/servicer_flash" ? 'bg-[#FFDD00] text-black' : 'bg-[#B913F0] text-white'}  flex justify-center items-center w-full disabled:bg-opacity-[0.5] px-6 py-3 rounded-md`}>{t('next_lbl')} </button>
                                </div>
                            </div>

                        </div>
                    }
                    {!loading && propertyList?.length === 0 &&
                        <div className='flex flex-col pt-10'>
                            <div className="flex flex-row justify-center items-center">
                                <div className="flex flex-col justify-center items-center">
                                    <img src={propImg} className='w-[40%]' alt="property_img" />
                                    <h2 className='font-semibold text-[20px] pt-3 max-w-[250px] text-center'>{location === "/servicer_reazy" ? t('reasy_not_found') : t('flash_not_found')}</h2>
                                </div>

                            </div>
                            <div className='w-[200px] mt-5  mx-auto'>
                                <Link to="/register" >
                                    <button className='border  w-full mt-5 py-2 px-6 rounded-md text-white   bg-[#F79F15]'>{
                                        t('add_rental')}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default PropertiesList