import React, { useRef } from 'react'
import profile from '../../../assests/images/f10.png'
import profileBg from '../../../assests/images/profileBg.png'
import { MdOutlinePhotoCamera } from 'react-icons/md'
import { IoIosArrowForward, IoMdSettings } from 'react-icons/io'
import { FaUserCircle } from 'react-icons/fa'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import { ConvertBase64 } from '../../../utils/ConvBase64'
import { EditUserProfile } from '../../../lib/Api/UserApi'
import { updateUser } from '../../../Redux/UserAuthSlice/UserAuthSlice'
import { Loader } from '../../../utils/loaderButton'
import moment from 'moment'
import { Truncate } from '../../../utils/TrucateString'
import LanguageDropdonw from '../../../utils/Dropdowns/LanguageDropdonw'
// import {ConvertBase64} from '../../../utils/ConvBase64'
const LeftSide = ({ setPic, setActiveTab, setActiveTabText }) => {
    const [active, setActive] = useState(0)
    const [loading, setLoading] = useState(false)
    const [activeItem, setActiveItem] = useState(0)
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };


    const { t } = useTranslation()


    const inputFile = useRef(null)
    let profilelist = [t('profile'), t('payment_info'), t('login_info'),]
    let setting = [t('about_leased'), t('contact_us'), t('faq')]
    let safty = [t('terms_service'), t('right_responsive'), t('leased_base'), t('eviction_guid'), t('privacy_policy')]

    let activeList = active === 0 ? profilelist : active === 1 ? setting : safty

    const user = useSelector((state) => state?.userAuth?.userInfo)
    const dispatch = useDispatch()

    const onButtonClick = () => {
        inputFile.current.click();

    }

    const handleImage = async (e) => {
        const file = e.target.files[0]
        // const base64 = await ConvertBase64(file)
        let formData = new FormData
        formData.append('user_image', file)
        setLoading(true)
        let { res, error } = await EditUserProfile(formData)
        if (res) {
            let UserData = Object.assign({}, ...res)
            dispatch(updateUser(UserData))
            setLoading(false)
            setPic(file)
        }
    }


    return (
        <>
            <div className="text-center w-full pb-5 h-full bg-[#F7E6CB] bg-opacity-[0.5] flex justify-center flex-col items-center pt-[4rem]">

                <div className='relative left-3 '>
                    <img src={profileBg} className="w-[80%]" alt="profile_img" />
                    <div className='absolute top-5 left-5 '>
                        {
                            loading ?
                                <div className=' absolute top-9 left-9'>
                                    <Loader type="socialLoder" />
                                </div>
                                :
                                <img
                                    src={ imageError ? profile : user?.userimg }
                                    onError={handleImageError}
                                    className="w-[104px] h-[104px] object-cover border rounded-full" alt="profile_img " />
                        }
                    </div>
                    <div className='absolute  top-[40%] right-[23%] cursor-pointer'>
                        <div onClick={onButtonClick} className='border bg-white bg-opacity-[0.7] p-1 rounded-full'>
                            <MdOutlinePhotoCamera className='text-[15px] text-[#F79F15]' />
                        </div>
                        <input type="file" ref={inputFile} onChange={handleImage} className='hidden' />
                    </div>
                </div>

                <div className='py-2'>
                    <h2 className='text-[1.8rem] font-bold'>{Truncate(user?.fname, 10)} {Truncate(user?.lname, 10)}</h2>
                    <p className='text-[#767676] text-[14px] font-semibold'>{user?.email}</p>
                    <span className='text-[#767676] text-[14px] font-semibold'>{t('leased_time')}{moment(user?.CreatedAt).format('ll')}</span>
                </div>
                {/*  */}

                <div className='flex justify-between  items-center'>
                    <div onClick={() => { return setActive(0), setActiveItem(0), setActiveTab(0), setActiveTabText(t('profile')) }} className={`w-full mx-3 cursor-pointer ${active === 0 && 'border-[#F79F15]'} border-b-2 text-gray-400 font-medium flex items-center`}>
                        <FaUserCircle className='text-[18px] mx-1' />
                        <h2 className={`${active === 0 && 'text-[#F79F15]'}`}>{t('profile')}</h2>
                    </div>
                    <div onClick={() => { return setActive(1), setActiveItem(0), setActiveTab(0), setActiveTabText(t('about_leased')) }} className={`w-full mx-3 cursor-pointer ${active === 1 && 'border-[#F79F15]'} border-b-2 text-gray-400 font-medium flex items-center`}>
                        <IoMdSettings className='text-[18px] mx-1' />
                        <h2 className={`${active === 1 && 'text-[#F79F15]'}`}>{t('settings')}</h2>
                    </div>
                    <div onClick={() => { return setActive(2), setActiveItem(0), setActiveTab(0), setActiveTabText(t('terms_service')) }} className={`w-full mx-3 cursor-pointer ${active === 2 && 'border-[#F79F15]'} border-b-2 text-gray-400 font-medium flex items-center`}>
                        <AiOutlineSafetyCertificate className='text-[18px] mx-1' />
                        <h2 className={`${active === 2 && 'text-[#F79F15]'}`}>{t('safety')}</h2>
                    </div>
                </div>


                <div className='mx-5 w-[90%] mt-7'>
                    <div className='border '>
                        <ul>
                            {
                                activeList.map((item, i) => (

                                    <>
                                        <li onClick={() => { return setActiveItem(i), setActiveTab(i), setActiveTabText(item) }} className={`p-2  ${i === activeItem ? 'bg-[#F79F15] border-[#F79F15] text-black  ' : 'border-gray-100 bg-white'} border`} key={i}>
                                            <div className={`flex justify-between cursor-pointer ${i === activeItem && 'text-[white]'}  text-black items-center`}>
                                                <h2 className={`normal-case text-left text-[16px] font-semibold`}>{item}</h2>
                                                <IoIosArrowForward />
                                            </div>
                                        </li>
                                    </>
                                ))
                            }
                            <li>
                                <LanguageDropdonw profile={profile} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeftSide