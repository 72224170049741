import React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/RommatesComp/Banner'
import Hosting from '../../components/RommatesComp/Hosting'
import Shop from '../../components/RommatesComp/Shop'
import Vacancy from '../../components/RommatesComp/Vacancy'
import rom1 from '../../assests/images/rent1.png'
import rom2 from '../../assests/images/rent2.png'
import rom3 from '../../assests/images/rent3.png'
import rom4 from '../../assests/images/rent4.png'

// import rom5 from '../../assests/images/rom5.png'
// import rom6 from '../../assests/images/rom6.png'
// import rom7 from '../../assests/images/rom7.png'
// import rom8 from '../../assests/images/rom8.png'
import rom5 from '../../assests/clientImgs/Home Filter.png'
import rom6 from '../../assests/clientImgs/Rental Application.png'
import rom7 from '../../assests/clientImgs/Calendar.png'
import rom8 from '../../assests/clientImgs/Messaging.png'

// import rombg1 from '../../assests/images/rent11.png'
// import rombg2 from '../../assests/images/rent22.png'
// import rombg3 from '../../assests/images/rent33.png'
// import rombg4 from '../../assests/images/rent44.png'
import { useTranslation } from 'react-i18next'
const Rommate = () => {
    const { t } = useTranslation()
    let space = {
        heading: t('web_rental_2'),
        lists: [t('web_rental_3'), t('web_rental_4'), t('web_rental_5')],
        title: "",
        icons: [{ name: t('web_rental_6'), original: rom5, hoverImg: rom1 }, { name: t('web_rental_7'), original: rom6, hoverImg: rom2 }, { name: t('web_rental_8'),original: rom7, hoverImg: rom3 }, { name: t('web_rental_9'), original: rom8, hoverImg: rom4 }],
    }

    let shopData = {
        heading: t('web_list_rommate_10'),
        lists: [
            {
                title: t('web_rental_12'),
                original: rom1,
                name: t('web_rental_16'),
                hoverImg: rom5
            },
            {
                title: t('web_rental_13'),
                name: t('web_rental_17'),
                original: rom2,
                hoverImg: rom6
            },
            {
                title: t('web_rental_14'),
                original: rom3,
                name: t('web_rental_18'),
                hoverImg: rom7
            },
            {
                title: t('web_rental_15'),
                original: rom4,
                name: t('web_rental_19'),
                hoverImg: rom8
            },
        ]

    }

    return (
        <Layout>
            <Banner />
            <Hosting space={space} />
            <Shop shopData={shopData} />
            <Vacancy />
        </Layout>
    )
}

export default Rommate