import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { MdMessage } from 'react-icons/md'
import { AddRentalAppUser } from '../../Redux/UserAuthSlice/RentalSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MylistDetail } from '../../components/GenericComp/MylistDetail'
import { Truncate } from '../../utils/TrucateString'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../utils/loaderButton'
import DeletePopup from '../../utils/Popup/DeletePopup'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import RentalsDetail from '../Property/PropertyDetail'
import { SavedListDecline, SavedListAcept } from '../../lib/Api/RantelServiceApi'
import gp4 from '../../assests/svg/Group 4188.svg'
import moment from 'moment'

const REasypass = ({ fetchProperty, properties, loading }) => {
  const [savedListLoader, setSavedListLoader] = useState(false)
  const [del, setDel] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [property, setProperty] = useState({})
  const [type, setType] = useState("")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOpen = (item) => {
    setProperty(item)
    setDel(true)
  }

  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }


  const handleDecline = async (list) => {
    setType("cancel")
    setSavedListLoader(true)
    let payload = {
      "list_id": property?.id
    }
    let { res } = await SavedListDecline(payload)
    if (res) {
      // await fetchProperty()
      setDel(false)
      setSavedListLoader(false)
    }
    else {
      setSavedListLoader(false)
    }
  }


  const handleDetail = (detail) => {
    setProperty(detail)
    setIsOpen(true)
  }

  const handleAcept = async (list) => {
    setProperty(list)
    setType("accept")
    setSavedListLoader(true)
    let payload = {
      "action": "accept",
      "list_id": list?.id
    }
    let { res } = await SavedListAcept(payload)
    if (res) {
      // await fetchProperty()
      setSavedListLoader(false)
    }
    else {
      setSavedListLoader(false)
    }
  }

  const { t } = useTranslation()

  return (
    <>

      {isOpen && <DynamicPopup modalOpen={isOpen} data={
        <RentalsDetail detail={property?.property} close={() => setIsOpen(false)} />
      } close={() => setIsOpen(false)} />}

      {del && <DeletePopup permition={del} loading={savedListLoader} Toggle={(val) => setDel(val)} callback={handleDecline} title={t('archive_this')} />}

      <div className='grid lg:grid-cols-3 md:grid-cols-2  gap-4'>
        {!loading && properties?.map((list, index) => (
          <div className='rounded-md bg-white shadow-md pb-4 mb-8 w-full relative' key={index}>
            <div className='relative'>
              <img src={list?.property?.image} className="w-full h-[200px] object-cover rounded-tl-xl rounded-tr-xl" alt="faverit_img" />
              <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5] rounded-tl-xl rounded-tr-xl'>
                <div className='absolute top-[7%]  right-[5%]'>
                  <div className={`cursor-pointer rounded-full p-[3px] w-8 h-8 flex justify-center items-center text-white bg-[#F63030] `} onClick={() => handleOpen(list)}>
                    <div className='text-[20px] ' >
                    </div>{property?.id === list?.id && savedListLoader === true && type === "cancel" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}
                  </div>
                  <div onClick={() => handleMessage(list?.user)} className=' mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full ' >
                    <div className='text-[18px] ' >
                    </div> <MdMessage />
                  </div>
                </div>

                <div className='absolute p-[2px] top-[7%] text-white bg-gray-200 rounded-full left-[5%]'>
                  <div className='text-[20px] cursor-pointer' onClick={() => handleDetail(list)}>
                    <img src={gp4} alt="detail" className='w-6 h-6' />
                  </div>
                </div>
                <MylistDetail list={list} />
              </div>
            </div>
            <div className='px-3 pt-2'>
              <p className='text-[12px]'>{list?.property?.distance}</p>
            </div>
            <div className='flex justify-between items-center p-3 h-[50px]'>
              <div className='flex items-center w-[80%] '>
                <img src={list.user?.user_image} className="w-[40px] h-[35px] border object-cover rounded-full" alt="user" />
                <h2 className='font-bold mx-2 text-[14px]  max-w-[120px]'>{Truncate(list?.user?.first_name, 10)}</h2>
              </div>
              <div className='w-[50%]'>
                <h2 className='text-[11px] font-semibold text-right'>{moment(list?.booking_details?.date_of_appointment).format('ll')}</h2>
                <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
              </div>
            </div>
            <div className={` w-full`}>
              <div className='px-4'>
                <button onClick={() => handleAcept(list)} className='px-6 py-1 w-full rounded-md bg-[#B913F0] text-white'>{
                  property?.id === list?.id && savedListLoader && type === "accept" ? <div className='flex items-center justify-center' ><Loader type="single" /> </div> : t('z_book')
                }</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default REasypass