import React, { useState, useEffect } from 'react'
// import rent from '../../../assests/images/rent.png'
// import user from '../../../assests/images/ap.png'
// import { Link } from 'react-router-dom'
import Home from '../../../assests/svg/home.svg'
import { Loader } from '../../../utils/loaderButton'
import { IoClose } from 'react-icons/io5'
// import moment from 'moment'
import { BookActions, GetBookedList, PenddingSendLease, RequestRentalApp, SavedListDecline } from '../../../lib/Api/RantelServiceApi'
import { Truncate } from '../../../utils/TrucateString'
import { useTranslation } from 'react-i18next'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import RentalsDetail from '../../../pages/Property/PropertyDetail'
import gp4 from '../../../assests/svg/Group 4188.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AddRentalAppUser } from '../../../Redux/UserAuthSlice/RentalSlice'
import BookAppointment from '../../RenatalPanelComp/Dashboard/BookAppointment'
import { MdMessage } from 'react-icons/md'
import { setConfirmData, setIsLeaseCreate } from '../../../Redux/UserAuthSlice/UserAuthSlice'
// import PasswordVerify from '../RentalRegister/PasswordVerify'
import PdfPreview from '../../RenatalPanelComp/RentalApp/PdfPreview'
import ViewRentalApp from './ViewRentalApp'
import { MylistDetail } from '../../GenericComp/MylistDetail'
import PropertyOffCampus from '../../RenatalPanelComp/FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../../RenatalPanelComp/FilterRommate/PropertyDetailOnCamp'
// import lists from '../../../lib/contentData.json'

const Booked = () => {
  const [bookList, setBookList] = useState([])
  const [detail, setDetail] = useState(false)
  const [type, setType] = useState("")
  const [loading, setLoading] = useState(false)
  const [checkLoading, setCheckLoading] = useState(false)
  const [property, setProperty] = useState({})
  const [leasePdf, setLeasePdf] = useState("")


  const location = useSelector((state) => state?.userAuth?.location)
  const { ConfirmData, isLeasedCreate } = useSelector((state) => state?.userAuth)
  // const lng = useSelector((state) => state?.userAuth?.language)
  let check = Object.keys(location)?.length > 0
  const locationData = useLocation().pathname

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }



  let fetchData = async () => {
    let { resBook } = await GetBookedList(location)
    if (resBook) {
      setBookList(resBook)
      return true
    }
  }


  const handleDetail = (list) => {
    setType("detail")
    setProperty(list)
    setDetail(true)
  }


  const handlePopup = (item, type) => {
    setType("pdf")
    setDetail(true)
    setLeasePdf(item)

  }

  // const handleReschdual = (list) => {
  //   setType("seducla")
  //   setProperty(list)
  //   setDetail(true)
  // }


  const handleSendRent = async (item) => {
    setType("sendRent")
    setProperty(item)
    let payload = {
      "property_id": item?.property_id,
    }
    setCheckLoading(true)
    let { res } = await RequestRentalApp(payload)
    if (res) {
      await fetchData()
      setCheckLoading(false)
    }

  }



  const handleCencelBooking = async (item) => {
    setType("cancel")
    setProperty(item)
    let payload = {
      "list_id": item?.id,
      "action": "decline"
    }

    setCheckLoading(true)
    let { res } = await BookActions(payload)
    if (res) {
      await fetchData()
      setCheckLoading(false)
    }
  }

  const checkVerify = (list) => {
    setProperty(list)
    // const hasDeniedPermission = sessionStorage.getItem('hasWatchLeaseApp');
    // if (!hasDeniedPermission) {
    //   setDetail(true)
    //   setType("verify")
    // }
    // else {
    // dispatch(setLeased(true))
    dispatch(setIsLeaseCreate({ page: "book", data: true }))
    dispatch(setConfirmData(list))
    navigate(`/createBuilder/${list?.property?.id}`)
    // }
  }

  const sendLease = async (list) => {
    let payload = {
      "list_id": list?.id
    }
    setType("signlease")
    setProperty(list)
    setCheckLoading(true)
    let res = await PenddingSendLease(payload)
    if (res) {
      await fetchData()
      dispatch(setConfirmData({}))
      setCheckLoading(false)
    }
  }

  // const verifyUser = () => {
  //   sessionStorage.setItem('hasWatchLeaseApp', 'true');
  //   setDetail(false)
  //   // dispatch(setLeased(true))
  //   dispatch(setIsLeaseCreate({ page: "book", data: true }))
  //   dispatch(setConfirmData(property))
  //   navigate(`/createBuilder/${property?.property?.id}`)
  // }


  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      // if (check) {
      let res = await fetchData()
      if (res) {
        setLoading(false)
      }
      // } else {
      //   setBookList([])
      // }
    }
    fetch()
  }, [location])



  useEffect(() => {
    if (locationData === `/mylist` && isLeasedCreate === true) {
      sendLease(ConfirmData)
      dispatch(setIsLeaseCreate({ page: "", data: false }))
    }
  }, [location])

  const { t } = useTranslation()



  return (
    <>
      {/* <Link to="/property"> */}
      {detail && <DynamicPopup modalOpen={detail} data={type === "detail" ? property?.property === null ? property?.roommate?.property_details !== null ? <PropertyOffCampus detail={property?.roommate} close={() => setDetail(false)} /> : <PropertyOnCampus detail={property?.roommate} close={() => setDetail(false)} /> :
        <RentalsDetail detail={property?.property} close={() => setDetail(false)} /> : type === "pdf" ? <ViewRentalApp list={leasePdf} /> : <BookAppointment property={property} />} close={() => setDetail(false)} />}


      <div className=' blog_list  mt-4'>
        {
          bookList && bookList?.length > 0 ?
            <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4'>
              {
                // !loading ?
                bookList?.map((list, i) => (
                  <div className='rounded-md bg-white shadow-md pb-4 mb-8 w-full relative' key={i}>
                    <div className='relative'>
                      <img src={list?.property !== null ? list?.property?.image : list?.roommate?.images?.length > 0 ? list?.roommate?.images[0]?.image : list?.roommate?.user?.user_image} className="w-full h-[200px] object-cover" alt="faverit_img" />
                      <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5]' />
                      <div className='absolute top-[7%]  right-[5%]'>
                        <div className=' cursor-pointer p-[3px] w-8 h-8 flex justify-center items-center  text-white bg-[#F63030]  rounded-full ' onClick={() => handleCencelBooking(list)}>
                          {property?.id === list?.id && checkLoading === true && type === "cancel" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}
                        </div>
                        {/* {list?.property?.lease_built !== null  */}
                        <div onClick={() => handleMessage(list?.user)} className=' mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full ' >
                          <div className='text-[18px] ' >
                          </div> <MdMessage />
                        </div>
                      </div>
                      <div className='absolute p-[2px] top-[7%] text-white bg-gray-200 rounded-full left-[5%]'>
                        <div className='text-[20px] cursor-pointer' onClick={() => handleDetail(list)}>
                          <img src={gp4} alt="detail" className='w-6 h-6' />
                        </div>
                      </div>
                      <MylistDetail list={list} />
                    </div>
                    {check &&
                      <div className='px-3 pt-2'>
                        <p className='text-[12px]'>{list?.property?.distance}</p>
                      </div>
                    }
                    <div className='flex justify-between items-center p-3 h-[50px]'>
                      <div className='flex items-center w-[80%] '>
                        <img src={list.user?.user_image} className="w-[40px] h-[35px] border object-cover rounded-full" alt="user" />
                        <h2 className='font-bold mx-2 text-[14px]  max-w-[120px]'>{Truncate(list?.user?.first_name, 10)}</h2>
                      </div>
                      <div className='w-[50%]'>
                        <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.date_of_appointment}</h2>
                        <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
                      </div>

                    </div>

                    <div className={` w-full`}>
                      {list?.roommate === null ?
                        <div className={` flex justify-between flex-wrap gap-2  items-center mx-2`}>
                          {
                            list?.lease_sent !== null ?
                              <button onClick={() => handlePopup(list, "lease")} className='px-2 py-1 rounded-md bg-[#F79F15] text-white w-full'>{t('view_lease')}</button>
                              :
                              list?.property?.lease_built === null ?
                                <>
                                  <button onClick={() => checkVerify(list)} className='px-1 w-full py-[3px] rounded-md bg-[#F79F15] text-white'>
                                    {t('snd_lease')}</button>
                                </>
                                :
                                <button onClick={() => sendLease(list)} className={`px-[6px] py-1   rounded-md bg-[#F79F15] w-full text-white ${property?.id === list?.id && 'bottom-0 px-1 py-1 '} `}>
                                  {property?.id === list?.id && type === "signlease" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('snd_lease')}
                                </button>
                          }
                          {
                            list?.rental_app_sent !== null ?
                              <>
                                <button onClick={() => handlePopup(list)} className={`px-[6px] py-1  rounded-md bg-blue-700 w-full text-white`}>
                                  {t('view_rent_app')}
                                </button>
                              </>
                              :
                              <>
                                <button onClick={() => handleSendRent(list)}
                                  className={`px-[5px] py-1  w-full  rounded-md bg-blue-700 text-white `}>
                                  {property?.id === list?.id && type === "sendRent" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('snd_req_rnt_ap')}
                                </button>
                              </>
                          }
                        </div>
                        :
                        ""
                        // <div className='flex justify-between flex-wrap gap-2 mx-2'>
                        //   <button onClick={() => handleReschdual(list)} className={`px-3 w-full py-1 rounded-md bg-green-500 text-white `}>
                        //     {t('reschedule')}
                        //   </button>
                        // </div>
                      }
                    </div>
                  </div>

                ))

              }

            </div>
            :
            loading ? <div className='flex justify-start items-start'><Loader type="loadData" /> </div> : <div className='text-center'>
              <div className='flex justify-center items-center w-full '>
                <div className='flex justify-center items-center'>
                  <img src={Home} alt="home" className='w-[70%]' />
                </div>
              </div>
              <div className='pt-5'>
                <h2 className='text-xl font-bold '>{t('record_not_found')}</h2>
              </div>
            </div>


        }
      </div>
      {/* </Link> */}
    </>
  )
}

export default Booked