import React, { useState, useRef, useEffect } from 'react';
import Transition from '../Transition';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import avatar from '../../assests/images/f10.png'
import { RentalPanelHome, ServicerPanelHome } from '../../Partials/SidebarObjects';
import { useTranslation } from 'react-i18next';
import { AddRommateIndex, IspopUp } from '../../Redux/UserAuthSlice/RommateSlice';

function UserProfile({ setShow }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [selected, setSelected] = useState(0);
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };
    const { userimg, fname, userRole } = useSelector((state) => state.userAuth.userInfo);
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const trigger = useRef(null);
    const dropdown = useRef(null);

    const handleShow = () => {
        dispatch(IspopUp(true))
        dispatch(AddRommateIndex(0))
        navigate('/filterRommate')
    }



    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });
    let check = userRole === "servicer" ? ServicerPanelHome : RentalPanelHome

    let { t } = useTranslation()
    return (
        <div className="relative">
            <button
                ref={trigger}
                className={` px-2 py-1  mx-3 shadow-md border w-full items-center flex justify-center bg-white border-slate-200 text-slate-500 `}
                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                <img
                    src={imageError ? avatar : userimg}
                    onError={handleImageError}
                    className="w-8 h-8 object-cover border rounded-full"
                    alt="user" />
                <h2 className='px-2 font-semibold xl:block lg:block md:block hidden'>{fname}</h2>
            </button>
            <Transition
                show={dropdownOpen}
                tag="div"
                className="z-10 absolute top-full left-3  w-full   bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-slate-600 "
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >

                    {

                        check?.map(option => {
                            return (
                                <>
                                    {option.link === "logout" ?

                                        <button
                                            key={option.id}
                                            tabIndex="0"
                                            className={`text-left hover:bg-slate-50 w-full py-2 px-4 cursor-pointer`}
                                            onClick={() => setShow(true)}
                                        >
                                            <span className='text-left' >{t(`${option.heading}`)}</span>

                                        </button>

                                        :
                                        option.link === "/filterRommate" ?
                                            <button
                                                key={option.id}
                                                tabIndex="0"
                                                className={`text-left hover:bg-slate-50 w-full py-2 px-4 cursor-pointer`}
                                                onClick={() => handleShow()}
                                            >
                                                <span className='text-left' >{t(`${option.heading}`)}</span>

                                            </button>
                                            :
                                            <Link className='text-left' to={option.link}>
                                                <button
                                                    key={option.id}
                                                    tabIndex="0"
                                                    className={`text-left hover:bg-slate-50 w-full py-2 px-4 cursor-pointer`}

                                                >
                                                    {t(`${option.heading}`)}

                                                </button>
                                            </Link>
                                    }
                                </>

                            )
                        })

                    }


                </div>
            </Transition>
        </div>
    );
}

export default UserProfile;



