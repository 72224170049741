import React, { useEffect, useState } from 'react'
import OffnCampus from './OffCampus'
import OnCampus from './OnCampus'
import Questions from './Questions'
import { useDispatch, useSelector } from 'react-redux'
import Decision from '../../RenatalPanelComp/FilterRommate/Decions'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import { GetOffRommatePriorities, GetRommatePriorities } from '../../../lib/Api/RentalAppApi'
import { IspopUp, RentQuestion } from '../../../Redux/UserAuthSlice/RommateSlice'
import PersonalInfo from './PersonalInfo'
// import CheckCollege from './CheckCollege'
// import CheckCampus from './CheckCampus'

const FilterRommate = () => {
  const [active, setActive] = useState(null)
  const [show, setShow] = useState(true)
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState({})
  const { romateIndex, isPopupShow } = useSelector((state) => state.rommate)
  const dispatch = useDispatch()

  let fetchQuestions = async () => {
    try {
      setLoading(true)
      let { resOn } = await GetRommatePriorities()
      let { resOff } = await GetOffRommatePriorities()
      if (resOn || resOff ) {
        setLoading(false)
        let obj = Object.assign({}, ...resOn)
        let obj2 = Object.assign({}, ...resOff)
        let payload = {
          "onCampus": obj,
          "offCampus": obj2
        }
        dispatch(RentQuestion(payload))
      }
    }
    catch (error) {

    }
  }

  const handleCLose = () => {
    dispatch(IspopUp(false))
  }

  useEffect(() => {
    fetchQuestions()
  }, [romateIndex])

  return (
    <div className=' '>
      {isPopupShow && <DynamicPopup data={<Decision loading={loading} setShow={handleCLose} setActivess={setActive} />} modalOpen={isPopupShow} close={() => setShow(false)} />}
      {romateIndex === 1 && <OnCampus  handleNext={(val) => setActive(val)} />}
      {romateIndex === 2 && <OffnCampus  handleNext={(val) => setActive(val)} />}
      {romateIndex === 3 && <Questions  handleNext={(val) => setActive(val)} />}
      {romateIndex === 4 && <PersonalInfo  handleNext={(val) => setActive(val)} />}
    </div>
  )
}

export default FilterRommate