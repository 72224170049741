import React, { useState } from 'react'
import logo from '../../assests/images/logobg.png'
import { Link, useLocation } from 'react-router-dom'
import Sidebar from '../../utils/Sidebar'
import { useSelector } from 'react-redux'
import UserProfile from '../../utils/Dropdowns/UserProfile'
import LanguageDropdonw from '../../utils/Dropdowns/LanguageDropdonw'
import { useTranslation } from 'react-i18next'
import { FaBars } from "react-icons/fa";
import UserProfileAdmin from '../../utils/Dropdowns/UserProfileAdmin'
import MessageDrop from '../../utils/Dropdowns/MessageDrop'
// import UserProfileAdmin from '../../utils/Dropdowns/UserProfileAdmin'
const Header = ({ setSignIn, setSignUp , setSidebarOpen, sidebarOpen , setShow , setproperty , setType }) => {
    const location = useLocation().pathname
    const token = useSelector((state) => state.userAuth.loginInfo.token);
    const { t } = useTranslation()
    return (
        <>
            {/* POPUP SIGNIN AND SIGNUP */}
            {/* {show && <DynamicPopup modalOpen={show} data={<LogoutLoader setShow={setShow} />} close={() => setShow(false)} />} */}
           
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className='border-b shadow-md'>
                <div className='container mx-auto h-[70px] w-full flex justify-between items-center'>
                    {/* Mobile View  */}
                    <div className='w-full lg:hidden cursor-pointer px-2 '>
                        <FaBars className=' text-[30px]' onClick={() => setSidebarOpen(true)} />
                    </div>
                    <div className='w-full lg:hidden flex justify-center '>
                        <Link to="/">
                            <img src={logo} className=" w-[90px] object-contain " alt="logo" />
                        </Link>
                    </div>
                    <div className='w-full lg:hidden text-center mx-2'>

                        {/* <ProfileDropdown setSignUp={setSignUp} setSignIn={setSignIn} /> */}
                        {
                            token ?
                                <UserProfile setShow={setShow} />
                                :
                                <ul className='flex cursor-pointer items-center justify-end font-semibold '>
                                    <li className='px-2'>
                                        <LanguageDropdonw />
                                    </li>
                                    {/* <li className='px-5' onClick={() => setSignIn(true)} >{t('login')}</li> */}
                                    <li>
                                        <button onClick={() => setSignUp(true)} className='px-3 py-1 rounded-md text-white text-[14px] bg-[#F79F15] border'>{t('register')}</button>
                                    </li>
                                </ul>
                        }

                    </div>

                    <div className='w-full hidden lg:block'>
                        {/* Desktop View  */}
                        <ul className={`lg:flex w-full  items-center cursor-pointer  font-semibold`}>
                            <li className='w-[90px] '>
                                <Link to="/">
                                    <img src={logo} className=" w-[90px] object-contain " alt="logo" />
                                </Link>
                            </li>
                            <li className={` px-4  py-4 `}> <Link to="/list"> {t('list')} </Link></li>
                            <div className={`${location === "/list" || location === "/list/rentals" || location === '/list/rommate' ? 'flex' : 'hidden'}`}>
                                <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/list/rentals" && 'border-b-2 border-[#F79F15]'}   `}> <Link to="/list/rentals"> {t('servicer_rentals')} </Link></li>
                                <li className={`px-4 py-5 bg-[#F7E6CB]  ${location === "/list/rommate" && 'border-b-2 border-[#F79F15]'} `}> <Link to="/list/rommate"> {t('rommates')} </Link></li>
                                {/* <li className={`px-4 py-5 bg-[#F7E6CB]  ${location === "/list" || "/rentals" ? 'block  ' : 'hidden'}`}> <Link to="/"> Features </Link></li> */}
                            </div>
                            <li className={` px-4  py-5 `}><Link to="/rent"> {t('rent_lbl')} </Link></li>
                            <div className={`${location === "/rent" || location === "/rent/rentals" || location === '/rent/rommate' ? 'flex' : 'hidden'}`}>
                                <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/rent/rentals" && 'border-b-2 border-[#F79F15]'}`}> <Link to="/rent/rentals"> {t('servicer_rentals')} </Link></li>
                                <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/rent/rommate" && 'border-b-2 border-[#F79F15]'}`}> <Link to="/rent/rommate"> {t('rommates')} </Link></li>

                            </div>
                            <li className={` px-4  py-5 `}> <Link to="/aboutus"> {t('about_us')}</Link></li>
                            <div className={`${location === "/aboutus" || location === "/aboutus/faq" || location === '/aboutus/contactus' ? 'flex' : 'hidden'}`}>
                                <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/aboutus/faq" && 'border-b-2 border-[#F79F15]'}`}> <Link to="/aboutus/faq"> {t('faq')} </Link></li>
                                <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/aboutus/contactus" && 'border-b-2 border-[#F79F15]'}`}> <Link to="/aboutus/contactus"> {t('contact_us')}  </Link></li>

                            </div>
                            {/* <li className={` px-4  py-5 `}> <Link to="/blog"> {t('highlight')}  </Link></li> */}
                            {!token &&
                                <li>
                                    <LanguageDropdonw />
                                </li>
                            }
                        </ul>
                    </div>
                    <div className={`${token ? 'w-full' : 'w-[50%]'} lg:block hidden`}>
                        {
                            token ?
                                <ul className='flex cursor-pointer items-center justify-end font-semibold '>
                                    <li>
                                        <MessageDrop setproperty={setproperty} setShow={setShow} setType={setType} />
                                    </li>

                                    <li className=''>
                                        {/* <BsBellFill className='text-[20px]' /> */}
                                        <MessageDrop type="notification" setproperty={setproperty} setShow={setShow} setType={setType} />

                                    </li>
                                    <li className=''>
                                        <UserProfileAdmin setShow={setShow} setType={setType} />
                                    </li>
                                </ul>
                                // <UserProfile setShow={setShow} />
                                :
                                <ul className='flex   cursor-pointer items-center justify-end font-semibold '>
                                    <li className='px-5' onClick={() => setSignIn(true)} >{t('login')}</li>
                                    <li>
                                        <button onClick={() => setSignUp(true)} className='px-6 py-2 rounded-md text-white bg-[#F79F15] border'>{t('register')}</button>
                                    </li>
                                </ul>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header