import React, { useState, useRef, useEffect } from 'react';
import Transition from '../Transition';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import avatar from '../../assests/images/f10.png'
// import { signout } from '../../Redux/UserAuthSlice/UserAuthSlice';
import { RentalPanel, RentalPanelHome, ServicerPanel, ServicerPanelHome } from '../../Partials/SidebarObjects';
import { useTranslation } from 'react-i18next';
import { Truncate } from '../TrucateString';
import { AddRommateIndex, IspopUp } from '../../Redux/UserAuthSlice/RommateSlice';
// import { logoutUser } from '../../lib/Api/UserApi';

function UserProfileAdmin({ setShow, setType }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [imageError, setImageError] = useState(false);
    const homeLocation = ["/", "/list", "/list/rentals", "/list/rommate", "/rent/rentals", "/rent/rommate", "/aboutus", "/aboutus/faq", "/aboutus/contactus", "/blog"]
    const location = useLocation().pathname


    const handleImageError = () => {
        setImageError(true);
    };

    const { userimg, fname, userRole } = useSelector((state) => state.userAuth.userInfo);

    const trigger = useRef(null);
    const dropdown = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleOpen = () => {
        setType("logout")
        setShow(true)
    }

    const handleShow = () => {
        dispatch(IspopUp(true))
        dispatch(AddRommateIndex(0))
        navigate('/filterRommate')
    }


    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });



    let listShower = homeLocation.includes(location)
    let check = userRole === "servicer" ? ServicerPanel : RentalPanel
    let showList = listShower && userRole === "servicer" ? ServicerPanelHome : listShower && userRole === "renter" ? RentalPanelHome : check
    let { t } = useTranslation()
    return (
        <div className="relative">
            <button
                ref={trigger}
                className={` px-2 py-1  mx-3 shadow-md border w-full items-center flex justify-center bg-white border-slate-200 text-slate-500 `}
                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                <img
                    src={imageError ? avatar : userimg}
                    onError={handleImageError}
                    className="w-8 h-8 object-cover border rounded-full"
                    alt="user" />
                <h2 className='px-2 xl:block lg:block md:block hidden font-semibold'>{Truncate(fname, 10)}</h2>

            </button>
            <Transition
                show={dropdownOpen}
                tag="div"
                className="z-10 absolute top-full xl:left-3 lg:left-3 md:left-3 -left-20   xl:w-full lg:w-full md:full w-[200px]   bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-slate-600 "
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >

                    {

                        showList?.map((option, i) => {
                            return (
                                <>
                                    {option.link === "logout" ?

                                        <button
                                            key={i}
                                            tabIndex="0"
                                            className={`text-left hover:bg-slate-50 w-full py-2 px-4 cursor-pointer`}
                                            onClick={() => handleOpen()}
                                        >
                                            <span className='text-left' >{t(`${option.heading}`)}</span>

                                        </button>
                                        :
                                        option.link === "/filterRommate" ?
                                            <button
                                                key={i}
                                                tabIndex="0"
                                                className={`text-left hover:bg-slate-50 w-full py-2 px-4 cursor-pointer`}
                                                onClick={() => handleShow()}
                                            >
                                                <span className='text-left' >{t(`${option.heading}`)}</span>

                                            </button>
                                            :
                                            <Link className='text-left' to={option.link}>
                                                <button
                                                    key={i}
                                                    tabIndex="0"
                                                    className={`text-left hover:bg-slate-50 w-full py-2 px-4 cursor-pointer`}

                                                >
                                                    {t(`${option.heading}`)}

                                                </button>
                                            </Link>

                                    }
                                </>

                            )
                        })

                    }


                </div>
            </Transition>
        </div>
    );
}

export default UserProfileAdmin;


