import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Dashboard from '../../components/ServicerPanelComp/Dashboard/Dashboard'
import { ChangeLanguage } from '../../lib/Api/UserApi'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import IdVerified from '../../components/GenericComp/IdVerified'
import { Loader } from '../../utils/loaderButton'
const ServicerDashboard = () => {
  const [loading, setLoading] = useState(false)
  const [showVerify, setShowVerify] = useState(false)
  const isVerify = useSelector((state) => state.userAuth.userInfo?.idVerified)
  const lng = useSelector((state) => state?.userAuth?.language)
  const handleChangeLanguage = async (check) => {
    await ChangeLanguage(lng)
  }
  useEffect(() => {
    if (isVerify === null) {
      setShowVerify(true)
    }
    else {
      setShowVerify(false)
    }
  }, [isVerify])

  useEffect(() => {
    handleChangeLanguage()
  }, [])
  return (
    <>
      {showVerify && <DynamicPopup modalOpen={showVerify} data={<IdVerified type="verify" setLoading={setLoading} close={() => setShowVerify(false)} />} close={() => setShowVerify(true)} />}
      {loading ? <Loader type="loadData" /> :
        <Dashboard />
      }
    </>
  )
}

export default ServicerDashboard