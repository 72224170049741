import React, { useState, useEffect } from 'react'
import { Loader } from '../../../utils/loaderButton'
import Home from '../../../assests/svg/home.svg'
import gp4 from '../../../assests/svg/Group 4188.svg'
import { CheckRentalApp, ConfirmContact, ConfirmListCancel, ConfirmReporting, ConfirmRequestLease, FlagRoommate, GetConfirmedList, LoardScreeningReport, PenddingSendRent } from '../../../lib/Api/RentalAppApi'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import PdfPreview from '../RentalApp/PdfPreview'
import RentalsDetail from '../../../pages/Property/PropertyDetail'
import { useDispatch, useSelector } from 'react-redux'
import { AddIndex, AddRentalAppUser, SendRent } from '../../../Redux/UserAuthSlice/RentalSlice'
import CreditConfirm from '../../GenericComp/CreditConfirmation'
import { useNavigate } from 'react-router-dom'
import { MylistDetail, MylistUserInfo } from '../../GenericComp/MylistDetail'
import { MdMessage } from 'react-icons/md'
import { IoClose } from 'react-icons/io5'
import { BsFlag } from 'react-icons/bs'
import Confirermation from '../../../pages/Property/Confirermation'
import PropertyOffCampus from '../FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../FilterRommate/PropertyDetailOnCamp'
import OwnerDetail from '../../ServicerPanelComp/MyList/OwnerDetail'

const Confirmed = ({ t }) => {
  const [confirmList, setConfirmList] = useState([])
  const [loading, setLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [remover, setRemover] = useState(false)
  const [checkLoading, setCheckLoading] = useState(false)
  const [property, setProperty] = useState({})
  const [viewLease, setViewLease] = useState(false)
  const [type, setType] = useState("")
  const [leasePdf, setLeasePdf] = useState("")
  const location = useSelector((state) => state?.userAuth?.location)
  const lng = useSelector((state) => state?.userAuth?.language)
  let check = Object.keys(location)?.length > 0

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { rentAppSend } = useSelector((state) => state?.rental)



  let fetchData = async () => {
    let { resConfirm } = await GetConfirmedList(location)
    if (resConfirm) {
      setConfirmList(resConfirm)
      return true
    }
  }

  const handleDetail = (list) => {
    setType("detail")
    setProperty(list)
    setViewLease(true)

  }

  let sendRent = async (item) => {
    setType("sendRent")
    setProperty(item)
    let payload = {
      "property_id": item?.property_id,
    }
    setCheckLoading(true)
    let { res } = await PenddingSendRent(payload)
    if (res) {
      dispatch(SendRent({}))
      await fetchData()
      setCheckLoading(false)
    }
  }

  const handleSendRent = async (item) => {
    setType("sendRent")
    setProperty(item)
    setCheckLoading(true)
    let { response } = await CheckRentalApp()
    if (response) {
      let credetCheck = response?.rental_application?.credits
      const isAnyFlagFalse = Object.values(response?.status).some(flag => flag === false);
      if (isAnyFlagFalse) {
        dispatch(AddIndex(2))
        navigate('/rentalApp')
        setCheckLoading(false)

      }
      else if (credetCheck === 0) {
        dispatch(SendRent({ id: item?.property_id, type: "confirm", item: item }))
        setViewLease(true)
        setCheckLoading(false)
      }
      else {
        sendRent(item)
      }
    }
  }

  const handleRequestLease = async (item, typedata) => {
    setType("reqlease")
    setProperty(item)
    // setType(typedata)
    let payload = {
      "property_id": item?.property_id,
    }
    setCheckLoading(true)
    let { res } = await ConfirmRequestLease(payload)
    if (res) {
      await fetchData()
      setCheckLoading(false)
    }
  }




  const handlePopup = async (item, type) => {
    setConfirmLoading(true)
    setProperty(item)
    if (type === "lease") {
      setType("pdf")
      setViewLease(true)
      setConfirmLoading(false)
      setLeasePdf(item?.lease_sent?.pdf)
    }
    else {
      // setLeasePdf(item?.rental_app_sent?.pdf)
      try {
        let res = await LoardScreeningReport("rental_application")
        if (res) {
          setType("pdf")
          setConfirmLoading(false)
          setViewLease(true)
          let obj = Object.assign({}, ...res)
          setLeasePdf(obj?.rental_application)
        }
        else {
          setViewLease(false)
        }
      }
      catch (err) { }
    }
  }


  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }


  const handleRemove = async (item) => {
    setType("remove")
    setProperty(item)
    let payload = {
      // "list": "archived",
      // "action": "send_to_archive"
    }
    if (item?.property === null) {
      payload.roommate_id = item?.roommate?.id
    }
    else {
      payload.property_id = item?.property_id

    }
    setRemover(true)
    let { res } = await ConfirmListCancel(payload)
    if (res) {
      let res = fetchData()
      if (res === true) {
        setRemover(false)
      }
      setRemover(false)

    }
  }


  // const handleFlag = async () => {
  //   try {
  //     setType("flag")
  //     let payload = {
  //       "roommate_id": property?.id,
  //     }
  //     setCheckLoading(true)
  //     let { res } = await FlagRoommate(payload)
  //     if (res) {
  //       await fetchData()
  //       setCheckLoading(false)
  //     }
  //   }
  //   catch (err) { }
  // }

  const ShowMessage = (item) => {
    setType('confirm')
    setProperty(item)
    setViewLease(true)
  }

  const handleConfirm = async () => {
    setConfirmLoading(true)
    let payload = {
      // "property_id": properties?.id
    }
    if (property?.property === null) {
      payload.roommate_id = property?.roommate?.id
    }
    else {
      payload.property_id = property?.property_id

    }
    let { res } = await ConfirmReporting(payload)
    if (res) {
      setConfirmLoading(false)
      setViewLease(false)
    }
    else {
      setConfirmLoading(false)

    }
    // toast.success(t('property_report'))
  }

  const handleContact = async (item) => {
    try {
      setType("contact")
      setProperty(item)
      let payload = {
        "roommate_id": item?.roommate_id
      }
      setCheckLoading(true)
      let res = await ConfirmContact(payload)
      if (res) {
        await fetchData()
        setCheckLoading(false)
      }
    }
    catch (err) { }
  }

  const handleViewConct = (list) => {
    setType("viewOwner")
    setProperty(list)
    setViewLease(true)
  }


  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      // if (check) {
        let res = await fetchData()
        if (res) {
          setLoading(false)
        }
      // } else {
      //   setConfirmList([])
      // }
    }
    fetch()
  }, [lng, location])


  useEffect(() => {
    if (rentAppSend?.item) {
      sendRent(rentAppSend?.item)
    }
  }, [])



  return (
    <>
      {viewLease && <DynamicPopup modalOpen={viewLease} data={type === "pdf" ? <PdfPreview pdf={leasePdf} t={t} /> : type === "sendRent" ? <CreditConfirm close={() => setViewLease(false)} /> :
        type === "viewOwner" ? <OwnerDetail type="hide" list={property} close={() => setViewLease(false)} /> :
          type === "confirm" ? <Confirermation handleConfirm={handleConfirm} close={() => setViewLease(false)} confirmLoading={confirmLoading} /> :
            property?.property === null ? property?.roommate?.property_details !== null ? <PropertyOffCampus detail={property?.roommate} close={() => setViewLease(false)} /> : <PropertyOnCampus detail={property?.roommate} close={() => setViewLease(false)} /> :
              <RentalsDetail detail={property?.property} close={() => setViewLease(false)} />} close={(val) => setViewLease(val)} />}

      {/* <Link to="/property"> */}
      <div className=' blog_list  mt-4'>
        {
          confirmList?.length > 0 ?
            <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4'>
              {

                confirmList?.map((list, i) => (
                  <div className='rounded-md bg-white shadow-md pb-4 mb-8 w-full relative' key={i}>
                    <div className='relative'>
                      <img src={list?.property !== null ? list?.property?.image : list?.roommate?.images?.length > 0 ? list?.roommate?.images[0]?.image : list?.roommate?.user?.user_image} className="w-full h-[200px] object-cover" alt="faverit_img" />
                      <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5] rounded-t-xl'>
                        <div className='absolute top-[7%]  right-[5%]'>
                          <div className=' cursor-pointer rounded-full p-[3px] w-8 h-8 flex justify-center items-center text-white bg-[#F63030] ' onClick={() => handleRemove(list)}>
                            <div className='text-[20px] ' >
                            </div>{property?.id === list?.id && remover === true && type === "remove" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}
                          </div>
                          <div onClick={() => handleMessage(list?.property !== null ? list?.property?.user : list?.roommate?.user)} className='mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full' >
                            <div className='text-[18px] ' >
                            </div> <MdMessage />
                          </div>
                        </div>
                        <div className='absolute p-[2px] top-[7%]   left-[5%]'>
                          <div className='text-[20px] text-white rounded-full  cursor-pointer' onClick={() => handleDetail(list)}>
                            <img src={gp4} alt="detail" className='w-6 h-6' />
                          </div>
                          {list?.roommate !== null &&
                            <div className="mt-2 text-white cursor-pointer flex justify-center items-center rounded-full bg-gray-200 w-8 h-8 p-[3px]" onClick={() => ShowMessage()}>
                              <BsFlag className='text-[20px] text-red-500' />
                            </div>
                          }
                        </div>
                        <MylistDetail list={list} />
                      </div>
                    </div>
                    {check &&
                      <div className='px-3 pt-2'>
                        <p className='text-[12px]'>{list?.property?.distance}</p>
                      </div>
                    }
                    <div className='flex justify-between items-center p-3 h-[50px]'>
                      <MylistUserInfo list={list} />
                      <div className='w-[50%]'>
                        <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.date_of_appointment}</h2>
                        <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
                      </div>

                    </div>

                    <div className={` w-full`}>
                      {list?.roommate === null ?
                        <div className='flex justify-between flex-wrap gap-2 mx-2'>
                          {
                            list?.lease_sent !== null ?
                              <button onClick={() => handlePopup(list, "lease")} className='px-2 py-1 w-full rounded-md bg-[#F79F15] text-white'>{t('view_lease')}</button>
                              :
                              <button onClick={() => handleRequestLease(list)} className={`px-[3px] py-1   w-full rounded-md bg-[#F79F15] text-white ${property?.id === list?.id && 'bottom-0 px-1 py-1 mr-1'} `}>
                                {property?.id === list?.id && type === "reqlease" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('req_lease')}
                              </button>
                          }

                          {list?.rental_app_sent !== null ?
                            <button onClick={() => handlePopup(list, "rentalapp")} className={`px-3  w-full py-1   rounded-md flex justify-center items-center bg-blue-500 text-white ${property?.id === list?.id && 'bottom-0   px-1 py-1 mr-1'} `}>
                              {confirmLoading && type === "pdf" && property?.id === list?.id ? <Loader type="single" /> : t('view_rent_app')}
                            </button>
                            :
                            <button onClick={() => handleSendRent(list)} className={`px-[3px] py-1  w-full  rounded-md bg-blue-500 text-white ${property?.id === list?.id && 'bottom-0 px-1 py-1 mr-1'} `}>
                              {property?.id === list?.id && type === "sendRent" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('send_rent_ap')}
                            </button>
                          }
                        </div>
                        :
                        list.roommate !== null && list?.roommate.property_details !== null && list?.contact_shared !== null ?
                          <div className='flex justify-between flex-wrap gap-2 mx-2'>
                            <button onClick={() => handleViewConct(list)} className='px-2 py-1 w-full rounded-md bg-[#F79F15] text-white'>
                              {t('view_owner_contact')}

                            </button>
                          </div>
                          :
                          <div className='flex justify-between flex-wrap gap-2 mx-2'>
                            <button onClick={() => handleContact(list)} className='px-2 py-1 w-full rounded-md bg-[#F79F15] text-white'>
                              {property?.id === list?.id && type === "contact" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('contact_owner')}

                            </button>
                          </div>

                      }
                    </div>
                  </div>


                ))

              }


            </div>
            :
            loading ? <div className='flex justify-start items-start'><Loader type="loadData" /> </div> :
              <div className='text-center'>
                <div className='flex justify-center items-center w-full mt-10 '>
                  {/* <div className='w-32 h-32 pb-2 bg-[#FFC000] rounded-full'>
                <div className='flex justify-center items-center w-full '>
                  <FaHome className="text-[100px] mt-3 text-white" />
                </div>
              </div> */}
                  <div className='flex justify-center items-center'>
                    <img src={Home} alt="home" className='w-[70%]' />
                  </div>
                </div>
                <div className='pt-5'>
                  <h2 className='text-xl font-bold '>{t('record_not_found')}</h2>
                </div>
              </div>


        }
      </div>
      {/* </Link> */}
    </>
  )
}

export default Confirmed