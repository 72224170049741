import React, { useEffect, useState } from 'react'
import Saved from './Saved'
import Booked from './Booked'
import Archived from './Archived'
import Confirmed from './Confirmed'
// import Pending from './Pending'
import { useTranslation } from 'react-i18next'
import { setlocation } from '../../../Redux/UserAuthSlice/UserAuthSlice'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import LocationAccess from '../../GenericComp/LocationAccess'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
// import { CheckLocation } from '../../GenericComp/CheckLocation'
// import { useParams } from 'react-router-dom'


const MyList = () => {
  const [tabIndex, setTabIndex] = useState(0)
  // const [error, setError] = useState(null);
  const [isLocation, setIsLocation] = useState(false)
  const [isLocationDenied, setIsLocationDenied] = useState(false);

  const locate = useSelector((state) => state?.userAuth?.locateDirect)
  const location = useSelector((state) => state?.userAuth?.location)

  let check = Object?.keys(location)?.length > 0

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const listTabs = [t('saved'), t('booked'), t('confirmed'), t('archived')]
  const list = [<Saved isLocationDenied={isLocationDenied} setIsLocation={setIsLocationDenied} />, <Booked />, <Confirmed />, <Archived />]


  const handleChange = (e) => {
    const id = e.target.value
    setTabIndex(id)
  }

  useEffect(() => {
    if (check) {
      return
    }
    const hasDeniedPermission = sessionStorage.getItem('hasDeniedPermission');
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude from the position object
          const { latitude, longitude } = position.coords;
          dispatch(setlocation({ latitude, longitude }))
          setIsLocationDenied(false)
        },
        (err) => {
          if (err.code === err.PERMISSION_DENIED) {
            if (!hasDeniedPermission) {
              setIsLocation(true)
              setIsLocationDenied(true)
              dispatch(setlocation({}));
              sessionStorage.setItem('hasDeniedPermission', 'true');
            } else {
              setIsLocationDenied(true)
              dispatch(setlocation({}));
            }
          }
        }
      );
    } else {
      //console.error('Geolocation is not supported in this browser.');
    }
  }, [check]);


  useEffect(() => {
    if (check) {
      return
    }
    const checkPermissionStatus = async () => {
      try {
        // Check the permission status using the Permissions API
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

        if (permissionStatus.state === 'granted') {
          // If permission is granted, get the current position
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              dispatch(setlocation({ latitude, longitude }));
              setIsLocationDenied(false)
            },
            (err) => {
              //console.error('Error retrieving location:', err);
            }
          );
        } else if (permissionStatus.state === 'denied') {
          // If permission is denied, check the session storage
          const hasDeniedPermission = sessionStorage.getItem('hasDeniedPermission');
          if (!hasDeniedPermission) {
            setIsLocation(true);
            setIsLocationDenied(true)
            sessionStorage.setItem('hasDeniedPermission', 'true');

          }
        }

        // Listen for changes to the permission status
        permissionStatus.onchange = () => {
          if (permissionStatus.state === 'granted') {
            sessionStorage.removeItem('hasDeniedPermission');
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                dispatch(setlocation({ latitude, longitude }));
                setIsLocationDenied(false)

              },
              (err) => {
                //console.error('Error obtaining location:', err);
              }
            );
          } else if (permissionStatus.state === 'denied') {
            setIsLocation(true)
            setIsLocationDenied(true)

          }
        };
      } catch (err) {
        //console.error('Error checking permission status:', err);
      }
    };

    if ('geolocation' in navigator) {
      checkPermissionStatus();
    } else {
      //console.error('Geolocation is not supported in this browser.');
    }
  }, [check]);


  useEffect(() => {
    if (locate === "all" || locate === "Saved") {
      setTabIndex(0)
    }

    else if (locate === "Booked") {
      setTabIndex(1)
    }
    else if (locate === "Confirmed") {
      setTabIndex(2)
    }
    else {
      setTabIndex(3)
    }
  }, [locate])


  return (
    <>

      {isLocation === true && <DynamicPopup data={<LocationAccess close={() => setIsLocation(false)} />} modalOpen={isLocation} close={() => setIsLocation(false)} />}


      <div className='lg:p-8 p-2 '>
        <ul className='xl:flex lg:flex hidden items-center border-b lg:max-w-[560px] md:max-w-[420px]  '>
          {
            listTabs.map((tab, i) => (
              <li key={i} className={`lg:ml-[3rem] ml-[1rem] cursor-pointer first:ml-0 ${i === 1 && "ml-9"}  pb-2  ${tabIndex === i && "border-[#F79F15] border-b-2"} font-bold text-[18px]`} onClick={() => setTabIndex(i)}>{tab}</li>

            ))
          }
        </ul>
        <div className='xl:hidden lg:hidden'>
          <select onChange={handleChange} className='border w-full focus:outline-none border-gray-100 rounded-md px-3 py-2 '>
            {listTabs.map((tab, i) => (
              <option kye={i} value={i}>{tab}</option>
            ))
            }
          </select>
        </div>
        <div className='mt-4 '>
          {
            tabIndex === 0 || tabIndex === 2 ?
              <div className='bg-[#FFCCCB] border-red-500 border rounded-md text-black text-[18px] p-2'>
                {t('rent_warning_1')} {listTabs[tabIndex]} {t('rent_warning_2')} {t('archived')}  {t('and')} {t('removed')} {t('rent_warning_3')}.
              </div>
              : tabIndex === 4 ?
                <div className='bg-[#FFCCCB] border-red-500 border rounded-md text-black text-[18px] p-2'>
                  {t('mesg_2')}
                </div>
                : null
          }
        </div>
        {

          list[tabIndex]}
      </div>

    </>
  )
}

export default MyList