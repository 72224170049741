import React, { useState } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import { Loader } from '../loaderButton';
const DeletePopup = ({ loading, permition, Toggle, callback, title }) => {
    const [show] = useState(permition)

    const handleClose = (info) => {
        if (info === "yes") {
            callback()
            if (!title) {
                Toggle(false)
            }
        }
        else {
            Toggle(false)

        }
    };

    const { t } = useTranslation()

    return (
        <div>
            <Modal open={show} onClose={() => handleClose("no")} center>
                <div className='p-20' >
                    <h1 className='text-[30px] font-bold'>{t('are_you_sure')} </h1>
                    <p className='text-[15px] text-center font-samibold mt-2'> {title ? t(title) : t('del_this')} </p>
                    <div className='flex flex-col'>
                        <button onClick={() => handleClose("yes")} className='bg-blue-500 w-[100%] py-2 text-white'>{loading ? <div className='flex justify-center items-center'><Loader type="single" /> </div>: t('yes')} </button>
                        <button onClick={() => handleClose("no")} className='bg-red-500 py-2 my-2 text-white'>{t('no')}</button>
                    </div>
                </div>
            </Modal>


        </div >
    )
}

export default DeletePopup
