import React from 'react'
import list from '../../assests/clientImgs/Room for Rent.png'
import { useTranslation } from 'react-i18next'
// import host1 from '../../assests/images/host1.png'
// import host2 from '../../assests/images/host2.png'
// import host3 from '../../assests/images/host3.png'
// import host4 from '../../assests/images/host4.png'

const Hosting = ({ space, setSignUp }) => {

    const { t } = useTranslation()
    return (
        <>

            <div className="container mx-auto">
                <div className="grid lg:grid-cols-2 pt-[3rem] gap-8 lg:mx-0 mx-4 ">

                    <div className='lg:py-2'>
                        <h2 className='lg:text-[2.7rem] text-[2rem] font-bold'>{space?.heading}</h2>
                        <ul className='list-disc mx-5 font-bold text-[18px]'>
                            <li>{t('web_rental_3')}</li>
                            <li>{t('web_rental_4')}</li>
                            <li>{t('web_rental_5')}</li>
                        </ul>

                        <div className='flex flex-col  items-start my-4'>
                            <button className='px-8 py-3 text-white bg-[#F79F15] rounded-md '>{t('get_started')}</button>
                            <p className='py-5 font-medium text-gray-400 text-[14px]'>{t('web_rental_10')}</p>
                        </div>
                    </div>

                    <div>
                        <img src={list} className="rounded-xl object-cover w-full h-[400px]" alt="list_image" />
                    </div>

                </div>
                <div className='grid lg:grid-cols-4  gap-4 lg:mx-0 mx-4 my-[2rem]'>
                    {
                        space?.icons?.map((item, i) => (
                            <div key={i} className='border cursor-pointer  bg-gradient-to-r hover:from-[#FCCB4A] hover:to-[#F79F15] hover:text-white flex-col items-center shadow-md rounded-md px-6 h-[300px] flex justify-center bg-white'>
                                <img src={item?.hoverImg} alt="icons_imaes" />
                                <h2 className='py-2 font-bold text-[20px]'>{item?.name}</h2>
                            </div>

                        ))
                    }
                </div>
            </div>

        </>
    )
}

export default Hosting