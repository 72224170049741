import { get, ref } from "firebase/database";
import { database } from "../../config/Firebase";

export const GetUsers = async (reciverid) => {
    const usersRef = ref(database, 'user');
    const snapshot = await get(usersRef);
    const users = snapshot.val()
    const user = users[reciverid];
    return user
}

// export const GetUsersList = async (usersList) => {
//     const usersRef = ref(database, 'user');
//     const snapshot = await get(usersRef);
//     const users = snapshot.val();
//     let findUsers = []
//     for (let index = 0; index < users.length; index++) {
//         const element = users[index];
//         for (let j = 0; j < usersList.length; j++) {
//             const element2 = usersList[index];
//             if (element?.receiverID === element2?.receiverID) {
//                 findUsers.push(element)
//             }
//         }

//     }
//     return findUsers
// }