import React, { useState, useEffect } from 'react'
import { Loader } from '../../../utils/loaderButton'
import { GetArchivedlist, RemoveArchived, RestoreArchived } from '../../../lib/Api/RentalAppApi'
import Home from '../../../assests/svg/home.svg'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import RentalsDetail from '../../../pages/Property/PropertyDetail'
import gp4 from '../../../assests/svg/Group 4188.svg'
import { useSelector } from 'react-redux'
import { MylistDetail, MylistUserInfo } from '../../GenericComp/MylistDetail'
import PropertyOffCampus from '../FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../FilterRommate/PropertyDetailOnCamp'
import DeletePopup from '../../../utils/Popup/DeletePopup'

const Archived = ({ t }) => {
  const [archiveList, setArchiveList] = useState([])
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState(false)
  const [checkLoading, setCheckLoading] = useState(false)
  const [property, setProperty] = useState({})
  const [type, setType] = useState("")
  const [del, setDel] = useState(false)

  const location = useSelector((state) => state?.userAuth?.location)
  const lng = useSelector((state) => state?.userAuth?.language)
  let check = Object.keys(location)?.length > 0


  let fetchData = async () => {
    let { resArchive } = await GetArchivedlist(location)
    if (resArchive) {
      setArchiveList(resArchive)
      return true
    }
  }



  const handleRestore = async () => {
    let payload = {
      // "property_id": data?.property_id,
      "list": "saved"
    }
    if (property?.property === null) {
      payload.roommate_id = property?.roommate?.id
    }
    else {
      payload.property_id = property?.property_id

    }
    setCheckLoading(true)
    let { res } = await RestoreArchived(payload)
    if (res) {
      await fetchData()
      setDel(false)
      setCheckLoading(false)
    }

  }


  const handleDetail = (list) => {
    setProperty(list)
    setDetail(true)

  }

  const handleOpen = (data, type) => {
    setProperty(data)
    setType(type)
    setDel(true)
  }


  const handleRemove = async () => {
    let payload = {
      // "property_id": data?.property_id,
    }
    if (property?.property === null) {
      payload.roommate_id = property?.roommate?.id
    }
    else {
      payload.property_id = property?.property_id

    }
    setCheckLoading(true)
    let { res } = await RemoveArchived(payload)
    if (res) {
      await fetchData()
      setDel(false)
      setCheckLoading(false)
    }
  }



  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      // if (check) {
      let res = await fetchData()
      if (res) {
        setLoading(false)
      }

      // }
      // else {
      //   setArchiveList([])
      // }
    }
    fetch()
  }, [lng, location])




  return (
    <>
      {detail && <DynamicPopup modalOpen={detail} data={property?.property === null ? property?.roommate?.property_details !== null ? <PropertyOffCampus detail={property?.roommate} close={() => setDetail(false)} /> : <PropertyOnCampus detail={property?.roommate} close={() => setDetail(false)} /> :
        <RentalsDetail detail={property?.property} close={() => setDetail(false)} />} close={() => setDetail(false)} />}
      <div className=' blog_list  mt-4'>
        {del && <DeletePopup permition={del} loading={checkLoading} Toggle={(val) => setDel(val)} callback={type === "remove" ? handleRemove : handleRestore} title={type === "remove" ? t('remove_this') : t('restore_this')} />}

        {


          archiveList?.length > 0 ?
            <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4'>
              {

                archiveList?.map((list, i) => (
                  <div className='rounded-md bg-white shadow-md pb-4  mb-8 w-full relative' key={i}>
                    <div className='relative'>
                      <img src={list?.property !== null ? list?.property?.image : list?.roommate?.images?.length > 0 ? list?.roommate?.images[0]?.image : list?.roommate?.user?.user_imagee} className="w-full h-[200px] object-cover" alt="faverit_img" />
                      <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5] rounded-t-xl'>
                        <div className='absolute p-[2px] top-[7%] text-white bg-gray-200 rounded-full right-[5%]'>
                          <div className='text-[20px] cursor-pointer' onClick={() => handleDetail(list)}>
                            <img src={gp4} alt="detail" className='w-6 h-6' />
                          </div>
                        </div>
                        <MylistDetail list={list} />
                      </div>
                    </div>
                    {check &&
                      <div className='px-3 pt-2'>
                        <p className='text-[12px]'>{list?.property?.distance}</p>
                      </div>
                    }
                    <div className='flex justify-between items-center p-3 h-[50px]'>
                      <MylistUserInfo list={list} />
                      <div className='w-[50%]'>
                        <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.date_of_appointment}</h2>
                        <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
                      </div>

                    </div>

                    <div className={` w-full`}>
                      <div className='flex justify-between flex-wrap gap-2 mx-2'>
                        <button onClick={() => handleOpen(list, "restore")} className={`px-3 py-[3px] w-full rounded-md bg-[#F79F15] text-white ${property?.id === list?.id && type === "restore" && 'bottom-0 px-6 py-1 '} `}>
                          {property?.id === list?.id && type === "restore" && checkLoading ? <div className='flex items-center justify-center' ><Loader type="single" /> </div> : t('restore')}
                        </button>
                        <button onClick={() => handleOpen(list, "remove")} className={`px-5 py-[3px] w-full  rounded-md bg-[#FF0000] text-white ${property?.id === list?.id && type === "remove" && 'bottom-0 px-6 py-1 '} `}>
                          {property?.id === list?.id && type === "remove" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('remove')}
                        </button>
                        {/* <button onClick={() => handleRemove(list)} className='px-5 py-[3px]  rounded-md bg-[#FF0000] text-white'>Remove</button> */}
                      </div>
                    </div>
                  </div>

                ))

              }

            </div>
            :
            loading ? <div className='flex justify-start items-start'><Loader type="loadData" /> </div> : <div className='text-center'>
              <div className='flex justify-center items-center w-full '>

                <div className='flex justify-center items-center'>
                  <img src={Home} alt="home" className='w-[70%]' />
                </div>
              </div>
              <div className='pt-5'>
                <h2 className='text-xl font-bold '>{t('record_not_found')}</h2>
              </div>
            </div>


        }
      </div>
    </>
  )
}

export default Archived