import React, { useEffect, useMemo, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../utils/loaderButton'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import moment from 'moment';
import { GetTimeSlots, UpdateTimeSlots } from '../../lib/Api/RentalAppApi';
import { useLocation } from 'react-router-dom';
import { GetTimeSlotes, GetTimeSlotsReasy, UpdateTimeSlotsReasy } from '../../lib/Api/RantelServiceApi';
import { useSelector } from 'react-redux';
const BlockTimeSettings = ({ selected, close }) => {
    const [loading, setLoading] = useState(false);
    const [selectedAll, setSelectedAll] = useState([]);
    const [dateArray, setDateArray] = useState([]);
    const [timeSlotsByDate, setTimeSlotsByDate] = useState({});
    const [currentDateIndex, setCurrentDateIndex] = useState(0);
    const [selectedTimesByDate, setSelectedTimesByDate] = useState({});
    const [loadingTimeSlot, setLoadingTimeSlot] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [preSelectedTimes, setPreSelectedTimes] = useState([]);

    const { language } = useSelector((state) => state.userAuth)

    moment.locale(language === "en" ? 'en' : 'es');
    const location = useLocation().pathname

    const fetchTime = async (currentDate) => {
        try {

            setLoadingTimeSlot(true)
            let payload = {
                "date": moment(currentDate).format('MM/DD/YYYY')
            }
            let res = await (location === "/servicer_reazy" ? GetTimeSlotsReasy(payload) : GetTimeSlots(payload))
            if (res) {
                setLoadingTimeSlot(false)
                setPreSelectedTimes(res)
            }
        }
        catch (err) { }
    }

    const updateTime = async (data) => {
        try {
            setLoadingSave(true)
            let payload = {
                "dates": data
            }
            let res = await (location === "/servicer_reazy" ? UpdateTimeSlotsReasy(payload) : UpdateTimeSlots(payload))
            if (res) {
                setLoadingSave(false)
                close()
            }
        }
        catch (err) { }
    }


    const getTimeSlots = async () => {
        let payload = { "duration": selected };
        setLoading(true)
        let res = await GetTimeSlotes(payload);
        if (res) {
            setLoading(false)
            let obj = Object.assign({}, ...res)
            const timeSlotsResponse = obj?.timeslots || [];
            const formattedDates = timeSlotsResponse.map(item => ({
                day: moment(item.day).format('YYYY-MM-DD'),
                timeslots: item.timeslots
            }));

            const dates = formattedDates.map(item => item.day);
            setDateArray(dates);

            const timeSlotsMap = {};
            formattedDates.forEach(item => {
                timeSlotsMap[item.day] = item.timeslots;
            });
            setTimeSlotsByDate(timeSlotsMap);
        }
    };

    const handleNextDate = () => {
        if (currentDateIndex < dateArray.length - 1) {
            let date = dateArray[currentDateIndex + 1]
            fetchTime(date)
            setCurrentDateIndex(currentDateIndex + 1);
        }
    };

    const handlePreviousDate = () => {
        if (currentDateIndex > 0) {
            let date = dateArray[currentDateIndex - 1]
            fetchTime(date)
            setCurrentDateIndex(currentDateIndex - 1);
        }
    };

    const handleSelect = (id, time) => {
        let payload = {
            time: time,
            id: id
        };
        const currentDisplayDate = moment(dateArray[currentDateIndex]).format('YYYY-MM-DD');

        setSelectedTimesByDate(prevState => {
            const currentTimes = prevState[currentDisplayDate] || []; // Get current selected times for the date
            const exists = currentTimes.some(item => item.id === id); // Check if the time is already selected
            const updatedTimes = exists
                ? currentTimes.filter(item => item.id !== id) // Deselect if already selected
                : [...currentTimes, payload]; // Select if not already selected

            const updatedSelectedTimesByDate = {
                ...prevState,
                [currentDisplayDate]: updatedTimes // Update the state for the current date
            };

            const result = Object.keys(updatedSelectedTimesByDate).map(date => ({
                date: moment(date).format('MM/DD/YYYY'), // Format the date as MM/DD/YYYY
                times: updatedSelectedTimesByDate[date].map(item => moment(item.time, 'h:mm A').format('hh:mm A')) // Keep times in 12-hour format
            }));

            setSelectedAll(result);
            return updatedSelectedTimesByDate;
        });
    };

    const saveSelectedTimes = () => {
        const result = Object.keys(selectedTimesByDate).map(date => ({
            date: moment(date).format('MM/DD/YYYY'), // Format the date as MM/DD/YYYY
            times: selectedTimesByDate[date].map(item => moment(item.time, 'h:mm A').format('HH:mm')) // Keep times in 12-hour format
        }));
        setSelectedAll(result)
        updateTime(result)
    };



    useEffect(() => {
        if (preSelectedTimes.length > 0 && dateArray.length > 0) {
            const formattedTimes = preSelectedTimes.map(time => moment(time, 'HH:mm').format('h:mm A')); // Ensure correct format

            const formattedDate = moment(dateArray[currentDateIndex]).format('YYYY-MM-DD');

            // Set selected times for the current date
            setSelectedTimesByDate(prevState => ({
                ...prevState,
                [formattedDate]: formattedTimes.map(time => ({ time }))
            }));

            // Update selectedAll
            setSelectedAll(prevSelectedAll => {
                const formattedDisplayDate = moment(dateArray[currentDateIndex]).format('MM/DD/YYYY');
                const existingDateIndex = prevSelectedAll.findIndex(dateItem => dateItem.date === formattedDisplayDate);

                if (existingDateIndex >= 0) {
                    // If the date already exists, update times
                    const updatedTimes = Array.from(new Set([
                        ...prevSelectedAll[existingDateIndex].times,
                        ...formattedTimes
                    ]));

                    // Return new state with updated times for the date
                    return prevSelectedAll.map((item, index) =>
                        index === existingDateIndex
                            ? { ...item, times: updatedTimes }
                            : item
                    );
                } else {
                    // Otherwise, add a new entry for the current date
                    return [
                        ...prevSelectedAll,
                        {
                            date: formattedDisplayDate,
                            times: formattedTimes
                        }
                    ];
                }
            });
        } else {
            // If there are no pre-selected times, clear the state for the current date
            const formattedDate = moment(dateArray[currentDateIndex]).format('YYYY-MM-DD');

            setSelectedTimesByDate(prevState => ({
                ...prevState,
                [formattedDate]: []
            }));

            setSelectedAll(prevSelectedAll => prevSelectedAll.filter(dateItem => dateItem.date !== moment(formattedDate).format('MM/DD/YYYY')));
        }
    }, [preSelectedTimes, dateArray, currentDateIndex]);



    useEffect(() => {
        const currentDate = new Date();
        fetchTime(currentDate)
        getTimeSlots();
    }, []);

    const { t } = useTranslation();


    const currentDisplayDate = dateArray[currentDateIndex];
    const currentTimeslots = timeSlotsByDate[currentDisplayDate] || [];

    return (
        <>
            {

                <div className='h-full p-5'>
                    {loading ?
                        <Loader type="loadData" />
                        :
                        <div className="flex justify-center  items-center flex-col">
                            <div className="bg-gradient-to-r from-[#B913F0] to-[#E63ECD] rounded-md p-3 text-white">
                                <h2>{t('reasy_date')}</h2>
                            </div>
                            <div className='border justify-between rounded-md mt-5 w-full flex items-center p-2 border-[#E63ECD]'>
                                <IoIosArrowBack
                                    onClick={handlePreviousDate}
                                    size={24}
                                    className={`cursor-pointer ${currentDateIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={currentDateIndex === 0}
                                />
                                <p className='flex justify-center items-center'>
                                    {moment(currentDisplayDate).format('DD MMMM YYYY')}
                                </p>
                                <IoIosArrowForward
                                    onClick={handleNextDate}
                                    size={24}
                                    className={`cursor-pointer ${currentDateIndex === dateArray.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={currentDateIndex === dateArray.length - 1}
                                />
                            </div>

                            <div className='flex flex-wrap max-w-[400px] items-center'>
                                {!loadingTimeSlot && currentTimeslots?.map((time, index) => {
                                    const currentDisplayDate = moment(dateArray[currentDateIndex]).format('YYYY-MM-DD');
                                    const currentTimes = selectedTimesByDate[currentDisplayDate] || [];
                                    return (
                                        <div
                                            onClick={() => handleSelect(index, time)}
                                            className={`border rounded-md w-[90px] flex justify-center items-center cursor-pointer mx-1 text-center py-2 my-2 ${currentTimes.some(item => item.time === time)
                                                ? 'bg-white text-black'
                                                : 'bg-white text-[#E63ECD] border border-[#E63ECD]'
                                                }`}
                                            key={index}
                                        >
                                            {currentTimes.some(item => item.time === time)
                                                ? <MdClose size={20} />
                                                : time}
                                        </div>
                                    );
                                })}

                                {
                                    loadingTimeSlot ? <Loader type="loadData" /> : ""
                                }

                            </div>

                            <div className='flex justify-between gap-5 items-center pt-3'>
                                <div className='flex items-center gap-2'>
                                    <div className='border flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                        <MdClose size={20} />
                                    </div>
                                    <p className='text-[16px] font-medium'>{t('booked')}</p>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <div className='border border-[#E63ECD] flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                    </div>
                                    <p className='text-[16px] font-medium'>{t('avail')}</p>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <div className='border flex justify-center items-center rounded-md w-[30px] bg-[#E63ECD] h-[30px]'>
                                    </div>
                                    <p className='text-[16px] font-medium'>{t('selected')}</p>
                                </div>
                            </div>
                            <div className='flex items-center flex-wrap gap-1 max-w-[400px] mt-5'>
                                {selectedAll?.map((item, index) => (
                                    <div key={index} className="mb-4">
                                        <p className="font-semibold text-[16px]">{item.date}</p>
                                        <div className="flex flex-wrap gap-2">
                                            {item.times.map((time, timeIndex) => (
                                                <div
                                                    key={timeIndex}
                                                    className="rounded-md w-[90px] cursor-pointer text-center py-2 my-2 bg-white text-[#E63ECD] border border-[#E63ECD]"
                                                >
                                                    {time}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='flex justify-center items-center mt-5 w-full'>
                                {
                                    <button disabled={selectedAll?.length === 0} onClick={() => saveSelectedTimes()} className=" w-full disabled:bg-opacity-[0.5] disabled:cursor-not-allowed flex justify-center items-center max-w-full my-4 bg-gradient-to-r from-[#B913F0] to-[#E63ECD] rounded-md p-3 text-white">
                                        {loadingSave ?
                                            <Loader type="socialLoder" /> : t('block_time')}
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div >
            }
        </>
    );
};


export default BlockTimeSettings;
