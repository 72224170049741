import React, { useState, useEffect } from 'react'
import blogImg from '../../../assests/images/blogImg.png'
import bath from '../../../assests/images/bath.png'
import bed from '../../../assests/images/bed.png'
import person from '../../../assests/svg/Icon ionic-ios-person.svg'
import locat from '../../../assests/svg/Icon material-location-on.svg'
import { BsFlag } from 'react-icons/bs'
// import { GetPropertyById } from '../../../lib/Api/RentalAppApi'
import { Loader } from '../../../utils/loaderButton'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next'
// import { Link } from 'react-router-dom'
import { GetLeasedPeriod } from '../../../lib/Api/RentalAppApi'
// import Property from '../../../pages/Property/Property'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import DynamicPopup from '../../../utils/Popup/DynamicPopup'

const PropertyOffCampus = ({ detail, swipeLeft, close }) => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [match, setMatch] = useState(false)
    const [properties, setProperties] = useState({})
    const [Leased, setLeased] = useState([])
    const [swiperHeight, setSwiperHeight] = useState('auto');

    const handleImageLoad = (e, i) => {
        const imageHeight = e.target.clientHeight;
        setSwiperHeight(imageHeight);
    };
    const { t } = useTranslation()

    // const handleConfirm = async () => {
    //     setConfirmLoading(true)
    //     let payload = {
    //         "property_id": properties?.id
    //     }
    //     let { res } = await ConfirmReporting(payload)
    //     if (res) {
    //         setConfirmLoading(false)
    //         swipeLeft(properties?.id)
    //         setType(false)
    //         close()
    //     }
    //     else {
    //         setConfirmLoading(false)

    //     }
    //     // toast.success(t('property_report'))
    // }

    const formatCurrency = (value) => {
        const formattedValue = new Intl.NumberFormat('en-US').format(value);
        return `$${formattedValue}`;
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                    setProperties(detail)
                }, 2000);
                // }
            } catch (error) {
            }
        })();
    }, [detail])

    useEffect(() => {
        let fetchLeased = async () => {
            let { res } = await GetLeasedPeriod();
            const ConvertObj = Object.assign({}, ...res)
            if (res) {
                setLeased(ConvertObj?.lease_periods)
            }
        }
        fetchLeased()
    }, [])

    const HandleMatch = () => {
        return (
            <div className='max-w-[300px] text-center'>
                <h2 className='text-center font-semibold text-[20px] text-black '>{t('match_info')}</h2>
            </div>
        )
    }

    let leasedFind = Leased?.find((f) => f.id === detail?.property_details?.lease_period_id)
    const url = `https://www.google.com/maps?q=${properties?.property_details?.latitude},${properties?.property_details?.longitude}`

    let estimated = properties?.property_details?.rent + properties?.current_information?.gas + properties?.current_information?.wifi + properties?.current_information?.electricity + properties?.current_information?.cable_bill

    let totalUtiliy = properties?.current_information?.gas + properties?.current_information?.wifi + properties?.current_information?.electricity + properties?.current_information?.cable_bill

    return (
        <>

            {match && <DynamicPopup modalOpen={match} data={<HandleMatch />} close={() => setMatch(false)} />}
            {
                loading ?
                    <div className="flex justify-center items-center text-[120px]">
                        <Loader type="loadData" />
                    </div>
                    :
                    <div className=" pt-4">
                        <div className="flex justify-center flex-col items-center">

                            {/* center section  */}

                            <div className="xl:w-[700px]  lg:w-[700px]  md:w-full w-[500px] max-w-full col-span-3  xl:mx-auto lg:mx-auto md:mx-auto  xl:px-10 lg:px-10   ">
                                <div className='relative  shadow'>
                                    <Swiper
                                        pagination={{
                                            type: 'progressbar',
                                        }}
                                        navigation={true}
                                        modules={[Pagination, Navigation]}
                                        className="h-[400px] w-full"
                                    // style={{ height: swiperHeight }}
                                    >
                                        {
                                            properties?.images?.length > 0 ?
                                                properties?.images?.map((item, i) => (
                                                    <SwiperSlide key={i} className='bg-[#efefef]'>
                                                        <img
                                                            src={item?.image || blogImg}
                                                            alt="blogImg"
                                                            className='h-full object-contain'
                                                            onLoad={(e) => handleImageLoad(e, i)}
                                                        />
                                                    </SwiperSlide>
                                                ))
                                                :
                                                <SwiperSlide className='bg-[#efefef]'>
                                                    <img src={properties?.user?.user_image || blogImg} alt="blogImg" className='h-full w-full' />
                                                </SwiperSlide>

                                        }

                                    </Swiper>
                                    <div className=' absolute bottom-10 z-40 left-4 '>
                                        <div className='bg-gray-100  rounded-full px-2'>
                                            {properties?.human_readable_time}
                                        </div>
                                    </div>
                                    <div className=' absolute bottom-10 z-40 right-4 '>
                                        <div onClick={() => setMatch(true)} className='bg-red-500  cursor-pointer text-white py-3 xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px]  rounded-full px-2'>
                                            {properties?.match} {t('matched')}
                                        </div>
                                    </div>

                                    <div className="absolute top-5 z-40  w-full px-4 ">
                                        <div className="flex justify-between items-center cursor-pointer">
                                            <BsFlag className='text-[20px] text-red-500' />
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2'>
                                    <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center  pb-2'>
                                        <div>
                                            <ul>
                                                <li>
                                                    <div className='flex items-center'>
                                                        <img src={person} alt="detail" className='object-cover' />
                                                        <h2 className='px-2 text-black font-bold xl:text-[25px] lg:text-[25px] md:text-[25px] text-[16px]'>{`${properties?.user?.first_name} ${properties?.user?.last_name}` || "N/A"}</h2>
                                                    </div>
                                                </li>

                                                <li >
                                                    <div className={`flex  `}>
                                                        <div>
                                                            <img src={locat} alt="detail" className='object-cover' />
                                                        </div>

                                                        {properties?.college_student === 1 && properties?.on_campus === 0 ?
                                                            <p className='max-w-[300px]'>{properties?.university_details?.university_name ||
                                                                "N/A"}</p>
                                                            :
                                                            <p className='px-2 max-w-[300px]'>{properties?.property_details?.street}</p>
                                                        }
                                                    </div>
                                                    <a href={url} target="_blank" className={`xl:px-3 lg:px-3 md:px-3  text-blue-400  text-[14px] `} rel="noopener noreferrer">
                                                        {t('view_map')}
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div>
                                            <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center'>
                                                <h2 className='xl:text-[25px] lg:text-[25px] md:text-[25px] text-[16px]  '>${properties?.property_details?.rent} <span className='text-[15px]'>/ {t('Month')}</span></h2>
                                            </div>
                                            <p className=''>${properties?.property_details?.security_deposit} {t('Sec_dp')} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <div className="flex  items-center justify-center">
                                        <div className='flex  items-center bg-gray-200 rounded-md xl:p-3 lg:p-3 md:p-3 p-1'>
                                            <img src={bed} alt="bed" className='object-cover ' />
                                            <h2 className='text-[16px] px-2 font-medium'>{properties?.property_details?.number_of_beds} {t('bedRom')}</h2>
                                        </div>
                                        <div className='flex items-center bg-gray-200 rounded-md xl:p-3 lg:p-3 md:p-3 p-1 mx-5'>
                                            <img src={bath} alt="bath" className='object-cover' />
                                            <h2 className='text-[16px] px-2 font-medium'>{properties?.property_details?.number_of_baths} {t('bathRom')}</h2>
                                        </div>
                                    </div>
                                </div>


                                <div className="xl:py-auto lg:py-auto md:py-auto py-4">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('desc')}</h2>
                                    </div>
                                    <p className='pt-2 '> {properties?.description}</p>
                                </div>
                                {properties?.college_student === 1 && properties?.on_campus === 0 &&
                                    <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap'>
                                        <div className='flex flex-col '>
                                            <h2 className='text-[14px] py-1 font-bold w-[200px]'>{t('rommate_uni_major')} :     </h2>
                                            <p>{properties?.university_details?.major || "N/A"}</p>
                                        </div>
                                        <div className='flex flex-col  xl:mx-5 lg:mx-5 md:mx-5'>
                                            <h2 className='text-[14px] py-1 font-bold  '>{t('rommate_uni_grad')} :</h2>
                                            <p> {properties?.university_details?.grade_level || "N/A"}</p>
                                        </div>
                                    </div>
                                }
                                <div className="pt-4">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('owner_info')}</h2>
                                    </div>
                                    <div>
                                        <h2 className='font-semibold xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px]'>{t('contact_first_name')}</h2>
                                        <p className='py-2 '> {properties?.current_information?.landlord_first_name || "N/A"}</p>
                                    </div>
                                    {/* <div>
                                        <h2 className='font-semibold xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px]'>{t('email')}</h2>
                                        <p className='py-2 '> {properties?.current_information?.landlord_email || "N/A"}</p>
                                    </div> */}
                                    <div>
                                        <h2 className='font-semibold xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px]'>{t('contact_phone')}</h2>
                                        <p className='py-2 '> {properties?.current_information?.landlord_phone_number || "N/A"}</p>
                                    </div>


                                </div>

                                <div className="pt-4">
                                    {
                                        properties?.property_details?.roommate_amenities
                                            ?.length > 0 &&

                                        <>
                                            <div className='flex items-center'>
                                                <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('amanity')}</h2>
                                            </div>
                                            <div className='grid grid-cols-3 gap-5 pt-2 bg-white rounded-md shadow-md p-3 mt-2'>
                                                {
                                                    properties?.property_details?.roommate_amenities?.map((c, i) => (
                                                        <div className='flex flex-col' key={i}>
                                                            <h2 className='text-gray-400'>{c?.name}</h2>

                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        </>
                                    }


                                </div>
                                <div className="pt-8 ">
                                    <div className='bg-white rounded-md shadow-md p-3 mt-2'>
                                        <div className='flex items-center justify-between py-2'>
                                            <h2 className='font-semibold text-[14px]'>{t('estimate')}</h2>
                                            <h2 className='font-semibold text-[14px]'>{formatCurrency(estimated || 0)}</h2>
                                        </div>
                                        <div className='flex items-center justify-between border-t py-2'>
                                            <h2 className='font-semibold text-[14px]'>{t('rent')}</h2>
                                            <h2 className='font-semibold text-[14px]'>{formatCurrency(properties?.property_details?.rent)}</h2>
                                        </div>
                                        <div className='flex items-center justify-between border-t py-2' onClick={() => setShow(!show)}>
                                            <h2 className='font-semibold text-[14px]'>{t('utility')}</h2>
                                            <div className='cursor-pointer flex items-center gap-1'>
                                                {show ? <IoIosArrowUp /> : <IoIosArrowDown />} <span className='text-[14px]'>({totalUtiliy})</span>
                                            </div>
                                        </div>
                                        {show &&
                                            <>
                                                <div className='flex items-center justify-between border-t py-2'>
                                                    <p className='text-[14px] font-normal text-gray-400'>{t('wifi_cable')}</p>
                                                    <p className='text-[14px] font-normal  text-gray-400'>{formatCurrency(properties?.current_information?.wifi + properties?.current_information?.cable_bill || 0)}</p>
                                                </div>
                                                <div className='flex items-center justify-between border-t py-2'>
                                                    <p className='text-[14px] font-normal text-gray-400'>{t('gas_labl')}</p>
                                                    <p className='text-[14px] font-normal  text-gray-400'>{formatCurrency(properties?.current_information?.gas || 0)}</p>
                                                </div>
                                                <div className='flex items-center justify-between border-t py-2'>
                                                    <p className='text-[14px] font-normal text-gray-400'>{t('elct_labl')}</p>
                                                    <p className='text-[14px] font-normal  text-gray-400'>{formatCurrency(properties?.current_information?.electricity || 0)}</p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                {/* <div className="pt-8 ">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold text-[20px]'>{t('utility')}:</h2>
                                    </div>
                                    <div className='bg-white rounded-md shadow-md p-3 mt-2'>
                                        <div className='flex items-center pt-2'>
                                            <p className='text-[14px] font-normal text-gray-400'>{t('wifi_labl')}</p>
                                            <p className='text-[14px] font-normal px-3 text-gray-400'>$ {properties?.current_information?.wifi || 0}</p>
                                        </div>
                                        <div className='flex items-center pt-2'>
                                            <p className='text-[14px] font-normal text-gray-400'>{t('gas_labl')}</p>
                                            <p className='text-[14px] font-normal px-3 text-gray-400'>$ {properties?.current_information?.gas || 0}</p>
                                        </div>
                                        <div className='flex items-center pt-2'>
                                            <p className='text-[14px] font-normal text-gray-400'>{t('elct_labl')}</p>
                                            <p className='text-[14px] font-normal px-3 text-gray-400'>$ {properties?.current_information?.electricity || 0}</p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="pt-8 ">
                                    {
                                        <>
                                            <div className='flex items-center'>
                                                <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('rommate_p_activity')}:</h2>
                                            </div>
                                            <div className='grid grid-cols-3 gap-5 pt-2 bg-white rounded-md shadow-md p-3 mt-2'>
                                                {
                                                    properties?.university_details?.extra_curricular_activities?.map((c, i) => (
                                                        <div className='flex flex-col' key={i}>
                                                            <h2 className='text-gray-400'>{c?.activity}</h2>

                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='pt-8'>
                                    <>
                                        <div className='flex items-center pt-8'>
                                            <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('leased_p')}</h2>
                                        </div>
                                        <div className=''>
                                            <h2 className='text-[18px] text-gray-400'>{leasedFind?.title}</h2>
                                        </div>
                                    </>

                                </div>

                            </div >


                        </div>
                    </div>
            }

        </>
    )
}

export default PropertyOffCampus