
import heart from '../assests/images/heart.png'
import rental from '../assests/images/f3.png'
import flash from '../assests/images/f5.png'
import lane from '../assests/images/f6.png'
import payment from '../assests/images/payment.png'
import profile from '../assests/images/profile.png'
import key from '../assests/images/key.png'
import house from '../assests/images/houseD.png'
import leased from '../assests/svg/Group 41515.svg'
import rommate from '../assests/svg/roommates.svg'

export const RentalPanel = [
    { image: "", heading: "go_home", link: "" },
    { image: house, heading: "explore_property", link: "/rental/rental_property" },
    { image: heart, heading: "list_view", link: "/dashboard" },
    { image: rental, heading: "rental_application", link: "/rentalApp" },
    { image: rommate, heading: "rommate_text", link: "/filterRommate" },
    { image: flash, heading: "flash_lane", link: "/flash" },
    { image: lane, heading: "r_eazy", link: "/reazy" },
    { image: payment, heading: "payment_history", link: "/payment" },
    { image: profile, heading: "profile", link: "/profile" },
    { image: "", heading: "logout", link: "logout" },

]

export const ServicerPanel = [
    { image: "", heading: "go_home", link: "" },
    { image: key, heading: "my_rentals", link: "/servicerPanel" },
    { image: heart, heading: "my_lists", link: "/mylist", },
    { image: leased, heading: "lease_upload", link: "/leasedBuilder" },
    { image: rommate, heading: "rommate_text", link: "/roommatesList" },
    { image: flash, heading: "flash_lane", link: "/servicer_flash" },
    { image: lane, heading: "r_eazy", link: "/servicer_reazy" },
    { image: payment, heading: "payment_history", link: "/servicer_payment" },
    { image: profile, heading: "profile", link: "/servicer_profile" },
    { image: "", heading: "logout", link: "logout" },


]


export const RentalPanelHome = [
    { image: "", heading: "go_dashboard", link: "/dashboard" },
    { image: house, heading: "explore_property", link: "/rental/rental_property" },
    { image: heart, heading: "list_view", link: "/dashboard" },
    { image: rental, heading: "rental_application", link: "/rentalApp" },
    { image: rommate, heading: "rommate_text", link: "/filterRommate" },
    { image: flash, heading: "flash_pass", link: "/flash" },
    { image: lane, heading: "r_eazy", link: "/reazy" },
    { image: payment, heading: "payment_history", link: "/payment" },
    { image: profile, heading: "profile", link: "/profile" },
    { image: "", heading: "logout", link: "logout" },

]

export const ServicerPanelHome = [
    { image: "", heading: "go_dashboard", link: "/servicerPanel" },
    { image: key, heading: "my_rentals", link: "/servicerPanel" },
    { image: heart, heading: "my_lists", link: "/mylist", },
    { image: rental, heading: "lease_upload", link: "/leasedBuilder" },
    { image: rommate, heading: "rommate_text", link: "/roommatesList" },
    { image: flash, heading: "flash_lane", link: "/servicer_flash" },
    { image: lane, heading: "r_eazy", link: "/servicer_reazy" },
    { image: payment, heading: "payment_history", link: "/servicer_payment" },
    { image: profile, heading: "profile", link: "/servicer_profile" },
    { image: "", heading: "logout", link: "logout" },


]