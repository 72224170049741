import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import LikeDislikePopup from '../../../utils/Popup/LikeDisLike';
import PostAppointment from '../../../utils/Popup/PostAppointment'
import propImg from '../../../assests/svg/home.svg'
import gp1 from '../../../assests/images/gp1.png'
import gp2 from '../../../assests/images/gp2.png'
import gp3 from '../../../assests/images/gp3.png'
import gp4 from '../../../assests/images/gp4.png'
import Arrow from '../../../assests/svg/arrow.svg'
import { FaFastBackward } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { FilterRommateOnCampus, GetCampusActivities, GetCampusInterest } from '../../../lib/Api/RentalAppApi';
import { Loader } from '../../../utils/loaderButton';
import Select from 'react-select'
import DynamicPopup from '../../../utils/Popup/DynamicPopup';
import Deck from './Tinder';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { GetCountries } from '../../../lib/Api/RantelServiceApi';
import PropertyOnCampus from './PropertyDetailOnCamp';
import CreditConfirm from '../../GenericComp/CreditConfirmation';
import { setUniversity } from '../../../Redux/UserAuthSlice/RommateSlice';
// import ConstData from '../../../contant/Constant.json'
import { IoArrowBackSharp } from 'react-icons/io5';
import OnCampusMobile from './MobileVersion/OnCampusMobile';

const OnCampus = ({ }) => {
  const [intrests, setInterest] = useState([])
  const [activity, setActivity] = useState([])
  const [Intr, setIntr] = useState([])
  const [detail, setDetail] = useState("")
  const [Act, setAct] = useState([])
  const [loadingCon, setLoadingCon] = useState(false)
  const [options, setOptions] = useState([]);
  const [active, setActive] = useState(null)
  const [show, setShow] = useState(false)
  const [showPost, setShowPost] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [listing, setListing] = useState([])
  const [originalLists, setOriginalLists] = useState([])
  const [type, setType] = useState("")
  const [property, setProperty] = useState({})
  const [swapSlider, setSwapSlider] = useState("")
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isCredit, setIsCredit] = useState(false)
  const [purchaseType, setPurchaseType] = useState("")
  const [afterSearch, setAfterSearch] = useState(false)
  const [showList, setShowList] = useState(false)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [savedPositions, setSavedPositions] = useState([]);
  const [points, setPoints] = useState([]);
  const [error, setError] = useState({
    stay: "",
    active: "",
    intrest: "",
    imags: ""
  })
  const containerRef = useRef(null);
  const topRef = useRef(null);

  //  Icon on above property 
  let Icons = [
    { img: gp1, type: "dislike" },
    { img: gp2, link: "/flash" },
    { img: "", type: "backForword" },
    { img: gp3, link: "/reazy" },
    { img: gp4, type: "like" },
  ]

  const { rommate, renterQuestion, universityInfo } = useSelector((state) => state?.rommate)

  const { t } = useTranslation()
  const gradle = [
    { heading: t('gradle_op1'), key: "Freshmen" },
    { heading: t('gradle_op2'), key: "Sophomore" },
    { heading: t('gradle_op3'), key: "Junior" },
    { heading: t('gradle_op4'), key: "Senior" },
    { heading: t('gradle_op5'), key: "Post-Graduate" },
    { heading: t('gradle_op6'), key: "Graduate" },
    { heading: t('gradle_op7'), key: "Other" }
  ]
  const schema = yup.object({
    university_name: yup
      .object({
        value: yup.string().required(t('rommate_uni')),
        label: yup.string().required(t('rommate_uni')),
      })
      .nullable()
      .required(t('rommate_uni')),
    // domm_name: yup.string().required(t('rommate_uni_dorm')),
    domm_name: yup.string(),
    // room: yup.string().required(t('rommate_uni_room')),
    major: yup.string().required(t('rommate_uni_major')),
    grade_level: yup.string().required(t('rommate_uni_grad')),
  });

  const { register, handleSubmit, reset, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const dispatch = useDispatch()

  const HandleInterestChecks = (id) => {
    if (Intr?.includes(id)) {
      let l = Intr.filter((f) => f !== id)
      setIntr(l)
    }
    else {
      setIntr((prev) => ([...prev, id]))
    }

  }
  const handleActivityChecks = (id) => {
    if (Act?.includes(id)) {
      let l = Act.filter((f) => f !== id)
      setAct(l)
    }
    else {
      setAct((prev) => ([...prev, id]))
    }
  }

  let GetActivityInterest = async () => {
    setLoading(true)
    let { resActivity } = await GetCampusActivities()
    let { resInterest } = await GetCampusInterest()
    const ConvertActi = Object.assign({}, ...resActivity)
    const ConvertIntr = Object.assign({}, ...resInterest)
    if (resActivity && resInterest) {
      setLoading(false)
      setActivity(ConvertActi.activities)
      setInterest(ConvertIntr.interests)
    }
    else {
      setLoading(false)
    }
  }

  let getCountries = async () => {
    try {
      setLoadingCon(true)
      let res = await GetCountries()
      if (res) {
        setLoadingCon(false)
        const formattedOptions = res.map(item => ({ label: item.name, value: item.id }));
        setOptions(formattedOptions);
      }
    }
    catch (err) { }
  }



  const OnSubmit = async (data) => {
    // if (Intr?.length === 0) {
    //   setError((prev) => ({
    //     ...prev,
    //     intrest: t('rommate_intr')
    //   }))
    // }
    // else if (Act?.length === 0) {
    //   setError((prev) => ({
    //     ...prev,
    //     active: t('rommate_active')
    //   }))
    // }
    // else {
    setSearchLoading(true)

    let ques = renterQuestion?.OnCampus?.priorites?.map((qu) => {
      return {
        "question_id": qu?.question?.id,
        "option_id": qu?.option?.id
      }
    })


    // topRef.current.scrollIntoView({ behavior: 'smooth' });
    if (topRef.current) {
      topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    let payload = {
      "on_campus": rommate?.on_campus,
      "college_student": rommate?.college_student,
      'university_id': data?.university_name?.value,
      'university_name': data?.university_name?.label,
      'domm_name': data?.domm_name,
      // 'room': data?.room,
      // 'age': data?.age,
      'major': data?.major || universityInfo?.major,
      'grade_level': data?.grade_level,
      'length_of_stay': active === 0 ? "One Semester" : "Two Semester",
      'interests': Intr,
      "priorities": ques,
      'extra_curricular_activities': Act
    }
    let uniInfo = {
      "on_campus": rommate?.on_campus,
      "college_student": rommate?.college_student,
      'university_id': data?.university_name?.value,
      'university_name': data?.university_name?.label,
      'domm_name': data?.domm_name,
      'major': data?.major || universityInfo?.major,
      'grade_level': data?.grade_level,
      'length_of_stay': active === 0 ? "One Semester" : "Two Semester",
    }
    dispatch(setUniversity(uniInfo))
    let { res } = await FilterRommateOnCampus(payload)
    if (res) {
      let obj = Object.assign({}, ...res)
      setSearchLoading(false)
      const reversedRoommates = [...obj.roommates].reverse();
      setListing(reversedRoommates);
      setShowList(true)
      if (obj.roommates?.length === 0) {
        setAfterSearch(true)
      }
      else {
        setAfterSearch(false)
      }
    }

    // }

  }


  //handle Next and Previus Property base on like and dislike button
  const handleNextProperty = (type) => {
    if (type === "like") {
      setSwapSlider("like")
      swipeRight()
    }
    else {
      setSwapSlider("dislike")
      swipeLeft()
    }
  }

  // Function to handle left swipe
  const swipeLeft = async (id) => {
    const numberOfElementsToKeep = 1;
    const filteredData = listing.filter((item, index) => index >= listing.length - numberOfElementsToKeep);
    let obj = Object.assign({}, ...filteredData)
    const updatedData = listing.filter((item, i) => item?.id !== obj?.id)
    let check = originalLists.find((f) => f.id === obj.id)
    if (!check) {
      setOriginalLists((prev) => [...prev, ...filteredData])
    }
    setListing(updatedData)
  };

  // Function to handle right swipe
  const swipeRight = async () => {
    const numberOfElementsToKeep = 1;
    const filteredData = listing.filter((item, index) => index >= listing.length - numberOfElementsToKeep);
    let obj = Object.assign({}, ...filteredData)
    const updatedData = listing.filter((item, i) => item?.id !== obj?.id)
    setListing(updatedData)
    let check = originalLists.find((f) => f.id === obj.id)
    if (!check) {
      setOriginalLists((prev) => [...prev, ...filteredData])
    }
    setShow(true)
    setProperty(obj)
  };

  // Revert property

  const RevertProperty = () => {
    const numberOfElementsToKeep = 1;
    const filteredData = originalLists.filter((item, index) => index >= originalLists.length - numberOfElementsToKeep);
    let obj = Object.assign({}, ...filteredData)
    const lastElement = points.pop();
    setSavedPositions((prev) => [...prev, lastElement])
    setListing((prev) => [...prev, obj]);
    let check = originalLists.filter((f) => f.id !== obj?.id)
    setOriginalLists(check)
  }


  // const handleBack = () => {
  //   setListing([])
  //   setShowList(false)
  // }


  // get api data from server 

  useEffect(() => {
    getCountries()
    GetActivityInterest()

  }, [])

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // //console.log("agte", afterSearch)

  useEffect(() => {
    if (Object.keys(universityInfo)?.length > 0) {
      let payload = {
        university_name: {
          value: universityInfo?.university_id,
          label: universityInfo?.university_name
        },
        domm_name: universityInfo?.domm_name || "", // Ensure all expected fields are included
        major: universityInfo?.major || "",
        grade_level: universityInfo?.grade_level || "",
      }
      reset(payload)
      setActive(universityInfo?.length_of_stay === "One Semester" ? 0 : 1)

    }
  }, [])



  // useEffect(() => {
  //   setListing(ConstData)
  // },[])


  return (
    <div className='overflow-hidden h-full' ref={containerRef} >
      {/* <div ></div> */}
      {detail && <DynamicPopup modalOpen={detail} data={<PropertyOnCampus swipeLeft={(val) => swipeLeft(val)} detail={detail} close={() => setDetail("")} />} close={() => setDetail("")} />}
      {isCredit && <DynamicPopup data={<CreditConfirm purchaseType={purchaseType} close={() => setIsCredit(false)} />} modalOpen={isCredit} close={() => setIsCredit(false)} />}
      {

        <>
          {show && <LikeDislikePopup setShowPost={setShowPost} propertyType="rommate" setPurchaseType={setPurchaseType} permition={show} Toggle={setShow} type={type} property={property} setIsCredit={setIsCredit} />}
          {showPost && <PostAppointment propertyType="rommate" swipeLeft={(val) => swipeLeft(val)} permition={showPost} Toggle={setShowPost} type={type} property={property} />}
          <form className=' shadow-md  rounded-sm' onSubmit={handleSubmit(OnSubmit)}>
            <div className="container mx-auto ">
              <div className="xl:grid lg:grid md:grid hidden grid-cols-4 gap-3">
                <div className='xl:mt-5 lg:mt-5 md:mt-5 border p-5'>
                  <div className='grid grid-cols-1 gap-2'>
                    <div className='flex flex-col'>
                      <label className='font-medium' htmlFor="">{t('rommate_uni')}</label>
                      <Controller
                        name="university_name"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="rounded-md mt-1  focus:outline-none"
                            placeholder={t('search_lab')}
                            isClearable={true}
                            isSearchable={true}
                            isLoading={loadingCon}
                            options={options}
                          />
                        )}
                      />
                      {errors.university_name && (
                        <p className="text-red-500 text-sm ">{errors.university_name.message}</p>
                      )}
                    </div>
                    <div className='flex flex-col'>
                      <label className='font-medium' htmlFor="">{t('rommate_uni_dorm')}</label>
                      <input {...register('domm_name')} type='text' placeholder={t('rommate_uni_dorm')} className='p-2 rounded-md border focus:outline-none' />
                      {/* {errors.domm_name && (
                        <p className="text-red-500 text-sm ">{errors.domm_name.message}</p>
                      )} */}
                    </div>
                  </div>
                  <div className='grid grid-cols-1 gap-2 mt-2 '>
                    <div className='flex flex-col  '>
                      <label className='font-medium' htmlFor="">{t('rommate_uni_major')}</label>
                      <input {...register('major')} type='text' placeholder={t('rommate_uni_major')} className='p-2 rounded-md border focus:outline-none' />
                      {errors.major && (
                        <p className="text-red-500 text-sm ">{errors.major.message}</p>
                      )}
                    </div>
                    <div className='flex flex-col '>
                      <label className='font-medium' htmlFor="">{t('rommate_uni_grad')}</label>
                      <select
                        className='p-2 rounded-md border focus:outline-none'
                        {...register('grade_level')}>
                        <option>{t('please_select')}</option>
                        {
                          gradle?.map((grad, index) => (
                            <option key={index} value={grad?.key}>{grad?.heading}</option>
                          ))
                        }
                      </select>
                      {errors.grade_level && (
                        <p className="text-red-500 text-sm ">{errors.grade_level.message}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2 className='text-[20px] font-semibold my-4'>{t('rommate_uni_stay')}</h2>
                    <div className='flex items-center xl:gap-2 lg:gap-2 md:gap-2  '>
                      <div onClick={() => setActive(0)} className={`${active === 0 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium text-[14px] shadow-md border  rounded-md p-2 cursor-pointer`}>
                        {t('rommate_uni_one_sm')}
                      </div>
                      <div onClick={() => setActive(1)} className={`${active === 1 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium text-[14px] shadow-md xl:mx-5 lg:mx-5 md:mx-5 ml-[10px] border rounded-md p-2 cursor-pointer`}>
                        {t('rommate_uni_two_sm')}
                      </div>
                    </div>

                  </div>
                  <div>
                    <h2 className='text-[20px] font-semibold my-4'>{t('rommate_ex_acti')}</h2>
                    {
                      loading ?
                        <div className='flex justify-start items-start'>
                          <Loader type="loadData" />
                        </div>
                        :
                        <div className='flex flex-col'>
                          <div className='flex flex-wrap items-center '>
                            {
                              activity?.map((c, i) => {
                                let d = Act.find((f) => f === c.id)
                                return (
                                  <div className='flex items-center mx-1' key={i}>
                                    <input id={c?.id} type="checkbox" onChange={() => handleActivityChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                                    <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.activity}</label>
                                  </div>
                                )
                              })
                            }
                          </div>
                          {error && (
                            <p className="text-red-500 text-sm pt-2 ">{error.active}</p>
                          )}
                        </div>
                    }

                    <h2 className='text-[20px] font-semibold my-4'>{t('interested')}</h2>
                    {
                      loading ?
                        <div className='flex justify-start items-start'>
                          <Loader type="loadData" />
                        </div>
                        :
                        <div className='flex flex-col'>

                          <div className='flex flex-wrap items-center'>
                            {
                              intrests?.map((c, i) => {
                                let d = Intr.find((f) => f === c.id)
                                return (
                                  <div className='flex items-center mx-1' key={i}>
                                    <input id={c?.id} name="interst" type="checkbox" onChange={() => HandleInterestChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                                    <label htmlFor={c?.id} name="interst" className='mx-1 text-[16px]'>{c.interest}</label>
                                  </div>
                                )
                              })
                            }
                          </div>
                          {error && (
                            <p className="text-red-500 text-sm pt-2 ">{error.intrest}</p>
                          )}
                        </div>
                    }
                    <div className='flex justify-end py-3'>
                      {
                        <button type='submit' className='font-semibold bg-[#F79F15] w-full px-6 py-1.5 text-white rounded-md'>{searchLoading ? <Loader type="Save" /> : t('search_lab')}</button>
                      }
                    </div>
                  </div>
                </div>
                <div className="w-full col-span-3">
                  {searchLoading ?
                    <div className='flex justify-center items-center'>
                      <Loader type="loadData" />
                    </div>
                    :
                    <div className={`max-w-full h-full w-[800px] mx-auto   `} ref={topRef}>
                      <div className={`flex  mt-4 justify-center items-center`}>
                        {
                          listing && listing?.length > 0 &&
                          Icons.map((icon, i) => (
                            <div className=''>
                              {icon.link ? <Link to={icon?.link}>
                                <img className='cursor-pointer' key={i} src={icon.img} alt={icon.img} />
                              </Link> :
                                icon?.img === "" && originalLists?.length > 0 ?
                                  <div className='border h-[55px] mb-[8px] w-[65px] rounded-md cursor-pointer flex justify-center items-center' onClick={() => RevertProperty()}>
                                    <FaFastBackward size={22} />
                                  </div>

                                  :
                                  <img className='cursor-pointer' onClick={() => handleNextProperty(icon?.type)} key={i} src={icon.img} alt={icon.img} />
                              }
                            </div>
                          ))
                        }
                      </div>
                      {
                        listing && listing?.length > 0 ?
                          <div className={`${listing?.length > 15 && 'mt-[3rem] m-auto'}  h-full `}>
                            <Deck rommateType="oncampus" currentIndex={currentIndex} swapSlider={swapSlider} toggle={setShow} listing={listing} setSavedPositions={setSavedPositions} savedPositions={savedPositions} setPoints={setPoints} setListing={(val) => setListing(val)} setDetail={setDetail} originalLists={originalLists} setOriginalLists={setOriginalLists} setProperty={setProperty} />
                          </div>
                          :
                          loading ? <div className='flex justify-center items-center pt-10'> {`${t('wait_lab')} .....`}</div> :
                            <div className='flex flex-col mt-5'>
                              <div className="flex flex-row justify-center items-center">
                                <div className='xl:flex lg:flex md:flex hidden justify-center items-center rotate-90'>
                                  <img src={Arrow} alt="home" />
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                  <img src={propImg} className='w-[40%]' alt="property_img" />
                                  <h2 className='font-semibold text-[20px] pt-3 max-w-[250px] text-center'>{t('rommate_filter')}</h2>
                                </div>

                              </div>
                              <div className='w-[200px] mx-auto'>
                                <button type='submit' className='border xl:mx-10 lg:mx-10 md:mx-10  w-full mt-5 py-2 px-6 rounded-md text-white   bg-[#F79F15]'>{afterSearch ? t('re-search') : t('search_lab')}</button>
                              </div>
                            </div>

                      }


                    </div>
                  }
                </div>

              </div>


            </div>
          </form>

          {/* Mobile*/}
          <OnCampusMobile />
        </>
      }
    </div>
  )
}

export default OnCampus