import React from 'react'
// import { BsFillTagFill } from 'react-icons/bs'
import vac1 from '../../assests/images/vac1.png'
import vac2 from '../../assests/images/vac2.png'
const Vacancy = () => {
    return (
        <>
            <div className="container lg:mx-auto mt-[3rem] px-5 ">
                <h2 className='text-center font-bold text-[2rem] my-8'>The Fastest Way To Fill Your Vacancy</h2>
                <div className="grid lg:grid-cols-2">
                    <div>
                        <img src={vac1} className='lg:w-[80%] w-full  rounded-[30px] shadow-md' alt="vac_img" />
                    </div>
                    <div className='lg:py-20 py-4'>
                        <h2 className='lg:text-[3rem] text-[2rem] font-bold'>Flash Lane</h2>
                        <p className='lg:max-w-[540px] text-[15px] my-5'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc</p>
                    </div>
                </div>
                <div className="grid lg:grid-cols-2">
                    <div className='lg:py-20 py-4  lg:order-0 order-1'>
                        <h2 className='lg:text-[3rem] text-[2rem] font-bold'>Flash Lane</h2>
                        <p className='lg:max-w-[540px] text-[15px] my-5'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc</p>
                    </div>
                    <div className='lg:order-1 order-0'>
                        <img src={vac2} className='lg:w-[80%] w-full' alt="vac_img" />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Vacancy