
const excludedProperties = ["id", "app_id", "updated_at", "created_at", "deleted_at", "pet_info_id"];

export function compareObjects(obj1, obj2) {
    if (obj1 !== undefined && obj1 !== null ) {
        const keys1 = Object.keys(obj1).filter(key => !excludedProperties.includes(key));
        const keys2 = Object.keys(obj2).filter(key => !excludedProperties.includes(key));

        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];
            const areArrays = Array.isArray(val1) && Array.isArray(val2);
            if ((areArrays && !compareArrays(val1, val2)) || (!areArrays && val1 != val2)) {
                return false;
            }
        }

        return true;
    }
    else {
        return false
    }
}

export function compareArrays(arr1, arr2) {
    if (arr1 !== undefined) {
        if (arr1?.length !== arr2?.length) {
            return false;
        }

        for (let i = 0; i < arr1?.length; i++) {
            if (!compareObjects(arr1[i], arr2[i])) {
                return false;
            }
        }
    }
    else {
           return false 
    }

    return true;
}



// ------------------------------- Place Api---------------------//
