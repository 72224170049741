import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillTagFill } from 'react-icons/bs'
import { GetWebFlashPrices, GetWebReazyPrices } from '../../lib/Api/RentalAppApi'
const Vacancy = () => {
    const [flashPriecs, setFlashPrices] = useState([])
    const [reazyPriecs, setReazyPrices] = useState([])

    const { t } = useTranslation()

    let fetchFlash = async () => {
        // setLoading(true)
        let { res } = await GetWebFlashPrices()
        if (res) {
            // setLoading(false)
            setFlashPrices(res)
        } else {
            // setLoading(false)
        }
    }

    let fetchReazy = async () => {
        // setLoading(true)
        let { res } = await GetWebReazyPrices()
        if (res) {
            // setLoading(false)
            setReazyPrices(res)
        } else {
            // setLoading(false)
        }
    }

    useEffect(() => {
        fetchFlash()
        fetchReazy()
    }, [])



    return (
        <>
            <div className="container lg:mx-auto mt-[3rem] px-5 ">
                <h2 className='text-center font-bold text-[2rem] my-8'>The Fastest Way To Fill Your Vacancy</h2>
                <div className="grid lg:grid-cols-2">
                    <div className='bg-vc bg-no-repeat lg:w-[500px] bg-cover   h-full w-full rounded-xl shadow-md'>
                        <div className='pt-5 px-[20px]'>
                            <h2 className='text-[30px] font-semibold text-center'>{t('welcom_flash')}</h2>
                            <h2 className='text-[15px] text-center cursor-pointer'>{t('skip_sr')}</h2>
                            <h2 className='text-[23px] font-semibold'>{t('how_work')}</h2>
                            <div className=''>
                                <h2 className='text-[15px] '>{t('flash_desc')}</h2>
                            </div>
                            <ul className='my-3'>
                                <li className='flex items-center '>
                                    <input type="checkbox" checked className='w-[20px] h-[20px] text-[#FFC000]' />
                                    <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('view_properties')}</h2>
                                </li>
                                <li className='flex items-center my-2'>
                                    <input type="checkbox" checked className='w-[20px] h-[20px] text-[#FFC000]' />
                                    <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('flash_book')}</h2>
                                </li>
                                <li className='flex items-center my-2 '>
                                    <input type="checkbox" checked className='w-[20px] h-[20px] text-[#FFC000]' />
                                    <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('submit_rent')}</h2>
                                </li>
                            </ul>
                            <h2 className='text-[23px] font-semibold'>{t('pricing')}</h2>
                            <ul className='my-2'>
                                {flashPriecs?.map((list, i) => {
                                    let addDot = list?.unit_amount_decimal / 100
                                    return (
                                        <li key={i} className='border cursor-pointer p-1 px-2  flex justify-between items-center bg-gradient-to-r from-[#FFC000] to-orange-500 h-full rounded-full '>
                                            <div className='flex mx-3 items-center'>
                                                <img src={list?.metadata?.image} className='object-cover w-6 h-6' alt="icon_img" />
                                                <div className='flex flex-col items-start '>
                                                    <h2 className='text-white mx-1 text-[18px]'>{
                                                        list?.metadata?.name
                                                    }</h2>
                                                    <p className='text-[12px] text-white px-2'>{list?.metadata?.desc}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className='text-white px-2'>${addDot}</p>
                                            </div>
                                        </li>


                                    )
                                })}
                            </ul>
                        </div>

                    </div>
                    <div className='lg:py-20 py-4'>
                        <h2 className='lg:text-[3rem] text-[2rem] font-bold'>Flash Lane</h2>
                        <p className='lg:max-w-[540px] text-[15px] my-5'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc</p>
                    </div>
                </div>
                <div className="grid lg:grid-cols-2">
                    <div className='lg:py-20 py-4  lg:order-0 order-1'>
                        <h2 className='lg:text-[3rem] text-[2rem] font-bold'>Flash Lane</h2>
                        <p className='lg:max-w-[540px] text-[15px] my-5'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc</p>
                    </div>
                    <div className='bg-vc2 bg-no-repeat lg:order-1 order-0 lg:w-[500px] bg-cover h-full w-full rounded-xl shadow-md'>
                        <div className='pt-5 px-[20px]'>
                            <h2 className='text-[30px] font-semibold text-center'>{t('welcom_resy')}</h2>
                            <h2 className='text-[15px]'>{t('reazy_desc')}</h2>
                            <h2 className='text-[23px] font-semibold'>{t('how_work')}</h2>
                            <div className=''>
                                <h2 className='text-[15px] '>{t('reeazy_text')}</h2>
                                <h2 className='text-[15px] '>{t('reazy_label')}</h2>
                            </div>
                            <ul className='my-3'>
                                <li className='flex items-center '>
                                    <input type="checkbox" checked className='w-[20px] h-[20px]' />
                                    <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('reazy_text_1')}</h2>
                                </li>
                                <li className='flex items-center my-2'>
                                    <input type="checkbox" checked className='w-[20px] h-[20px]' />
                                    <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('reazy_text_2')}</h2>
                                </li>
                                <li className='flex items-center my-2 '>
                                    <input type="checkbox" checked className='w-[20px] h-[20px]' />
                                    <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('reazy_text_3')}</h2>
                                </li>

                            </ul>
                            <h2 className='text-[23px] font-semibold'>{t('pricing')}</h2>
                            <ul className='my-2'>
                                {reazyPriecs?.map((list, i) => {
                                    let addDot = list?.unit_amount_decimal / 100
                                    return (
                                        <li key={i} className=' cursor-pointer border p-1 px-2  flex justify-between items-center bg-gradient-to-r from-[#B913F0] to-[#E63ECD] h-full rounded-full '>
                                            <div className='flex mx-3 items-center'>
                                                <img src={list?.metadata?.image} className='object-cover w-6 h-6' alt="icon_img" />
                                                <div className='flex flex-col items-start '>
                                                    <h2 className='text-white mx-1 text-[18px]'>{
                                                        list?.metadata?.name
                                                    }</h2>
                                                    <p className='text-[12px] text-white px-2'>{list?.metadata?.desc}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className='text-white px-2'>${addDot}</p>
                                            </div>
                                        </li>
                                    )
                                })
                                }
                            </ul>
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default Vacancy