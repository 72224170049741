
import React from 'react'
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { BiCurrentLocation } from 'react-icons/bi';
import { IoLocation } from 'react-icons/io5';

const PlacePicker = ({ setLatitude, setLongitude, setAddressCityState, close, setDefaultAdress, setLocate }) => {


    // const autocompleteOptions = {
    //     types: ['address'],
    //     componentRestrictions: { country: 'us' }
    // };

    var autocompleteOptions = {
      types: ['(regions)'],
      componentRestrictions: {country: "us"}
     };


    const handlePlaceSelect = (place) => {
        const placeAddress = place?.formatted_address;
        const placeLat = place?.geometry.location.lat();
        const placeLng = place?.geometry.location.lng();
        setDefaultAdress(placeAddress)
        setLocate("")
        setLatitude(placeLat)
        setLongitude(placeLng)

        let city = "";
        let country = "";
        let zipcode = "";
        let street = "";
        let propertyId = "";

        // Iterate through the address components
        place?.address_components.forEach(component => {
            const types = component.types;
            // Check for the types relevant to city, country, and zipcode
            if (types.includes("locality")) {
              city = component.long_name;
            } else if (types.includes("administrative_area_level_1")) {
              country = component.short_name;
            } else if (types.includes("postal_code")) {
              zipcode = component.long_name;
            }
            else if (types.includes("route")) {
              street = component.long_name;
            }
            else if (types.includes("street_number")) {
              propertyId = component.long_name;
            }
          })
        let payload = {
            city,
            state: country,
            zipcode,
            street: `${propertyId} ${street}`
        }
        setAddressCityState(payload)
    }

    const { t } = useTranslation()

    return (
        <>

            <h2 className='text-[20px] font-semibold mt-4'>{t('location')}</h2>
            <div className='flex items-center justify-between my-4'>
                <div className=' bg-[#F5F7F9] rounded-md w-full p-1 mr-2 flex  items-center'>
                    <IoLocation className='text-[#A5A5A5]' />
                    <ReactGoogleAutocomplete
                        apiKey={"AIzaSyBD-xaHdgobMyufM5tW3whuusr8l5J57X0"}
                        options={autocompleteOptions}
                        onPlaceSelected={handlePlaceSelect}
                        // onPlaceSelected={async (place) => {
                        //     setLocate(place)


                        // }}
                        className='w-[350px] bg-[#F5F7F9]
                                            border-transparent  focus:outline-none placeholder:text-[#A5A5A5]'
                        placeholder={t('select_location')}
                    />

                </div>
                <div className=' p-2 rounded-md bg-[#F79F15]' onClick={() => close(false)}>
                    <BiCurrentLocation className='text-white' />
                </div>

            </div>

        </>
    )
}

export default PlacePicker