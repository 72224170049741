import React, { useState } from 'react'
import list from '../../assests/clientImgs/Leased Logo.png'
// import DynamicDropdonw from '../../utils/DaynamicDropdown'
// import { BsSearch } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { addPopup } from '../../Redux/UserAuthSlice/RentalSlice'
import SignUp from '../../pages/AuthPages/SignUp'
import SignIn from '../../pages/AuthPages/SignIn'
// import { ImHome2, ImKey } from 'react-icons/im'
const Banner = () => {
    const [signIn, setSignIn] = useState(false)
    const [signUp, setSignUp] = useState(false)

    const popup = useSelector((state) => state?.rental?.isLoginPop)
    const token = useSelector((state) => state?.userAuth?.loginInfo?.token)
    const user = useSelector((state) => state?.userAuth.userInfo)

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(addPopup(false))
        setSignIn(false)
    }



    const { t } = useTranslation()
    return (
        <>
            {signUp && <SignUp modalOpen={signUp} setSignIn={setSignIn} onCloseModal={() => setSignUp(false)} />}
            {signIn === true || popup === true ? <SignIn modalOpen={signIn || popup} setSignUp={setSignUp} onCloseModal={() => handleClose()} /> : null}
            <div className="bg-[#F5F6FC] w-full h-full pb-7">
                <div className="container mx-auto">
                    <div className="grid lg:grid-cols-2 pt-[3rem] gap-8">
                        <div>
                            <img src={list} className="rounded-xl w-full h-[400px] object-contain" alt="list_image" />
                        </div>
                        <div className=''>
                            {
                                <h2 className='lg:text-[2.5rem] text-[2rem] font-bold px-7'>{t('web_rental_1')}</h2>
                            }

                            <div className='w-full px-7 pt-5'>
                                <div className=' mx-auto rounded-md lg:max-w-[600px] md:max-w-[700px] md:m-auto 
'>
                                    <p className='text-[14px]'>{t('discover_new')}</p>
                                    {!token ?
                                        <div className='flex justify-between items-center pt-2'>
                                            <h2 className='font-bold'>{t('sign_up_post')}</h2>
                                            <button className='px-6 py-2 rounded-md text-white bg-[#F79F15] border' onClick={() => setSignUp(true)}>{t('sign_up_home')}</button>
                                        </div>
                                        :
                                        <div className='pt-5'>
                                            <Link to="/rental/rental_property" className='px-6 w-full py-2 rounded-md text-white bg-[#F79F15] border'>{t('explore_property')}</Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner