import React, { useState, useEffect } from 'react'
import blogImg from '../../../assests/images/blogImg.png'
import person from '../../../assests/svg/Icon ionic-ios-person.svg'
import locat from '../../../assests/svg/Icon material-location-on.svg'
import { BsFlag } from 'react-icons/bs'
import { Loader } from '../../../utils/loaderButton'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
// import { Link } from 'react-router-dom'
// import Slider from 'react-slick';
const PropertyOnCampus = ({ detail }) => {
    const [properties, setProperties] = useState({})
    const [loading, setLoading] = useState(false)
    const [match, setMatch] = useState(false)

    const [swiperHeight, setSwiperHeight] = useState('auto');

    const handleImageLoad = (e, i) => {
        const imageHeight = e.target.clientHeight;
        setSwiperHeight(imageHeight);
    };


    const { t } = useTranslation()


    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                    setProperties(detail)
                }, 2000);
                // }
            } catch (error) {
            }
        })();
    }, [detail])

    const HandleMatch = () => {
        return (
            <div className='max-w-[300px] text-center'>
                <h2 className='text-center font-semibold text-[20px] text-black '>{t('match_info')}</h2>
            </div>
        )
    }

    const url = `https://www.google.com/maps?q=${detail?.property_details?.latitude},${detail?.property_details?.longitude}`

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: false,
    //     autoplaySpeed: 3000,
    // };


    return (
        <>
            {match && <DynamicPopup modalOpen={match} data={<HandleMatch />} close={() => setMatch(false)} />}
            {
                loading ?
                    <div className="flex justify-center items-center text-[120px]">
                        <Loader type="loadData" />
                    </div>
                    :
                    <div className="container mx-auto pt-4">
                        <div className="flex justify-center flex-col items-center">

                            {/* center section  */}

                            <div className="xl:w-[700px]  lg:w-[700px] md:w-full w-[500px] max-w-full col-span-3  xl:mx-auto lg:mx-auto md:mx-auto  xl:px-10 lg:px-10    ">
                                <div className='relative shadow '>

                                    <Swiper
                                        pagination={{
                                            type: 'progressbar',
                                        }}
                                        navigation={true}
                                        centeredSlides={true}
                                        modules={[Pagination, Navigation]}
                                        className="h-[400px] w-full"
                                    // style={{ height: swiperHeight }}
                                    >
                                        {
                                            properties?.images?.length > 0 ?
                                                properties?.images?.map((item, i) => (
                                                    <SwiperSlide key={i} className='bg-[#efefef]'>
                                                        <img
                                                            src={item?.image || blogImg}
                                                            alt="blogImg"
                                                            className='h-full object-contain'
                                                            onLoad={(e) => handleImageLoad(e, i)}
                                                        />
                                                    </SwiperSlide>
                                                ))
                                                :
                                                <SwiperSlide className='bg-[#efefef]'>
                                                    <img src={properties?.user?.user_image || blogImg} alt="blogImg" className='h-full' />
                                                </SwiperSlide>

                                        }

                                    </Swiper>
                                    <div className=' absolute bottom-10 z-40 left-4 '>
                                        <div className='bg-gray-100  rounded-full px-2'>
                                            {properties?.human_readable_time}
                                        </div>
                                    </div>
                                    <div className=' absolute bottom-10 z-40 right-4 '>
                                        <div onClick={() => setMatch(true)} className='bg-red-500 cursor-pointer text-white py-3 rounded-full px-2'>
                                            {properties?.match} {t('matched')}
                                        </div>
                                    </div>

                                    <div className="absolute top-5 z-40  w-full px-4 ">
                                        <div className="flex justify-between items-center cursor-pointer">
                                            <BsFlag className='text-[20px] text-red-500' />
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 '>
                                    <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center   pb-2 '>
                                        <div>
                                            <ul>
                                                <li>
                                                    <div className='flex items-center'>
                                                        <img src={person} alt="detail" className='object-cover' />
                                                        <h2 className='px-2 text-black font-bold xl:text-[25px] lg:text-[25px] md:text-[25px] text-[16px]'>{`${properties?.user?.first_name} ${properties?.user?.last_name}` || "N/A"}</h2>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className={`flex items-center  ${detail?.property_details === null ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                                                        <img src={locat} alt="detail" className='object-cover' />
                                                        <p className='px-2'> {properties?.university_details?.university_name}</p>
                                                        {/* <a href={url} target="_blank" className={`px-2 text-black text-[14px] `} rel="noopener noreferrer">
                                                            {t('view_map')}
                                                        </a> */}
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>

                                    </div>

                                </div>


                                <div className="">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('desc')}</h2>
                                    </div>
                                    <p className='pt-1'> {properties?.description}</p>
                                </div>

                                <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-5 pt-4 '>
                                    <div className='flex flex-col'>
                                        <h2 className=' text-[14px] py-1 font-bold '>{t('rommate_uni_major')} :     </h2>
                                        <p>{properties?.university_details?.major || "N/A"}</p>
                                    </div>
                                    <div className='flex flex-col  '>
                                        <h2 className='text-[14px] py-1 font-bold  '>{t('rommate_uni_grad')} :</h2>
                                        <p> {properties?.university_details?.grade_level || "N/A"}</p>
                                    </div>
                                    <div className='flex flex-col  '>
                                        <h2 className='text-[14px] py-1 font-bold  '>{t('rommate_uni_dorm')} : </h2>
                                        <p>{properties?.university_details?.domm_name || "N/A"}</p>
                                    </div>
                                </div>




                                {/* <div className="pt-8">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold text-[20px]'>{t('owner_info')}</h2>
                                    </div>
                                    <div>
                                        <h2 className='font-semibold text-[18px]'>{t('contact_first_name')}</h2>
                                        <p className='py-2 '> {properties?.current_information?.landlord_first_name || "N/A"}</p>
                                    </div>
                                    <div>
                                        <h2 className='font-semibold text-[18px]'>{t('email')}</h2>
                                        <p className='py-2 '> {properties?.current_information?.landlord_email || "N/A"}</p>
                                    </div>
                                    <div>
                                        <h2 className='font-semibold text-[18px]'>{t('contact_phone')}</h2>
                                        <p className='py-2 '> {properties?.current_information?.landlord_phone_number || "N/A"}</p>
                                    </div>


                                </div> */}

                                <div className="pt-8">
                                    {
                                        properties?.interests?.length > 0 &&

                                        <>
                                            <div className='flex items-center'>
                                                <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('interested')}</h2>
                                            </div>
                                            <div className='grid grid-cols-3 gap-5 pt-2 bg-white rounded-md shadow-md p-3 mt-2'>
                                                {
                                                    properties?.interests?.map((c, i) => (
                                                        <div className='flex flex-col' key={i}>
                                                            <h2 className='text-gray-400'>{c?.interest}</h2>

                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="pt-8">
                                    {
                                        properties?.university_details?.extra_curricular_activities?.length > 0 &&

                                        <>
                                            <div className='flex items-center'>
                                                <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('rommate_p_activity')}</h2>
                                            </div>
                                            <div className='grid grid-cols-3 gap-5 pt-2 bg-white rounded-md shadow-md p-3 mt-2'>
                                                {
                                                    properties?.university_details?.extra_curricular_activities?.map((c, i) => (
                                                        <div className='flex flex-col' key={i}>
                                                            <h2 className='text-gray-400'>{c?.activity}</h2>

                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        </>
                                    }
                                </div>
                                {/* <div className="pt-8">
                                    {
                                        <>
                                            <div className='flex items-center'>
                                                <h2 className='font-semibold text-[20px]'>{t('music')}</h2>
                                            </div>
                                            <div className='grid grid-cols-3 gap-5 pt-2 bg-white rounded-md shadow-md p-3 mt-2'>

                                            </div>
                                        </>
                                    }
                                </div> */}
                                <div className="pt-8">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('rommate_uni_stay')}</h2>
                                    </div>
                                    <div className=' pt-2 bg-white rounded-md shadow-md  p-3 mt-2'>
                                        <h2 className='text-gray-400'> {properties?.length_of_stay === "1" ? t('rommate_uni_one_sm') : t('rommate_uni_two_sm')}</h2>
                                    </div>
                                </div>
                            </div >


                        </div>
                    </div>
            }

        </>
    )
}

export default PropertyOnCampus