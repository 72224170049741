import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartJS from 'chart.js/auto';

const GaugeChart = () => {
    const [credit, setCredit] = useState(600); // Initial credit value
    const dataList = [{ color: "red", name: 'Very Poor' }, { color: "orange", name: 'Poor' }, { color: "yellow", name: 'Fair' }, { color: "#3BA32D", name: 'Good' }, { color: "#3B5C0A", name: 'Excellent' }]

    useEffect(() => {
        ChartJS.register({
            id: 'gaugeNeedle',
            afterDatasetDraw: (chart, args, options) => {
                const ctx = chart.ctx;
                const meta = chart.getDatasetMeta(0);
                const element = meta.data[0];

                const centerX = element.x;
                const centerY = element.y;
                const baseRotation = chart.options.rotation || 0;

                // Get data values from the dataset
                const dataValues = chart.data.datasets[0].data;
                const minCredit = Math.min(...dataValues);
                const maxCredit = Math.max(...dataValues);
                const angleRange = 180; // Angle range to rotate the needle
                const creditRatio = (credit - minCredit) / (maxCredit - minCredit);
                const needleRotation = baseRotation + (creditRatio * angleRange);

                ctx.save();
                ctx.translate(centerX, centerY);
                ctx.rotate(needleRotation);

                // Draw needle
                ctx.beginPath();
                ctx.moveTo(0, 0);
                ctx.lineWidth = 5;
                ctx.lineTo(0, -100); // Increase the height here
                ctx.lineCap = 'round'; // Rounded needle tip
                ctx.strokeStyle = 'green';
                ctx.stroke();

                // Draw center circle
                ctx.beginPath();
                ctx.arc(0, 0, 6, 0, 2 * Math.PI);
                ctx.fillStyle = '#fff';
                ctx.fill();
                ctx.strokeStyle = '#aaa';
                ctx.stroke();

                ctx.restore();
            },
        });
    }, [credit]);

    const data = {
        labels: ['Very Poor', 'Poor', 'Fair', 'Good', 'Excellent'],
        datasets: [
            {
                label: '',
                data: [600, 660, 720, 780, 850],
                backgroundColor: [
                    'red',
                    'orange',
                    'yellow',
                    '#3BA32D',
                    '#3B5C0A',
                ],
                borderColor: [
                    'red',
                    'orange',
                    'yellow',
                    '#3BA32D',
                    '#3B5C0A',
                ],
                borderWidth: 1,
                circumference: 180,
                rotation: 270,
            },
        ],
    };

    return (
        <div className=" flex justify-center flex-col">
            <h2 className='text-center font-bold text-[25px]'>Credit Score Checker</h2>
            <div className='ml-5'>
                <Doughnut
                    data={{ ...data, credit: credit }}
                    options={{
                        aspectRatio: 1,
                        circumference: 180,
                        rotation: 270,
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                    }}
                />
            </div>
            <div className='mt-5'>
                {
                    dataList?.map((list) => (
                        <div className='flex items-center pb-3'>
                            <div style={{ backgroundColor: list?.color }} className={` rounded-md w-[20px] h-[20px] `}></div>
                            <p className='px-2'>{list?.name}</p>
                        </div>
                    ))
                }
            </div>

        </div>
    );
};

export default GaugeChart;
