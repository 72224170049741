import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdDelete, MdEdit } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import { DeleteLeased, GetLeaseds } from '../../../lib/Api/LeasedBuilder'
import { Loader } from '../../../utils/loaderButton'
// import pdf from '../../../assests/images/pdf.png'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import ViewLeased from './ViewLeased'
import DeletePopup from '../../../utils/Popup/DeletePopup'
import { useTranslation } from 'react-i18next'
import PasswordVerify from '../RentalRegister/PasswordVerify'
import HowItsWork from '../../GenericComp/HowItsWork'
const LeasedLists = () => {
    const [show, setShow] = useState(false)
    const [delId, setDelId] = useState("")
    const [type, setType] = useState("")
    const [action, setAction] = useState()
    const [del, setDel] = useState(false)
    const [loading, setloading] = useState(false)
    const [leasedList, setLeasedList] = useState([])
    const navigate = useNavigate()

    const { t } = useTranslation()

    const handleDelPopup = (id) => {
        setDelId(id)
        setDel(true)
    }

    const handleViews = (type, list) => {
        setDelId(type)
        setType("view")
        let option = {
            type: type,
            list: list
        }
        setAction(option)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setDelId("")

    }

    const handleNext = () => {
        // let current = activeIndex + 1
        // setActiveIndex(current)
        navigate('/createBuilder')
    }

    const handleDelete = async () => {
        await DeleteLeased(delId)
        setDelId("")
    }


    const verifyUser = () => {
        sessionStorage.setItem('hasWatchLeaseApp', 'true');
        sessionStorage.setItem('hasVerifyLeased', 'true');
        setShow(false)
        // navigate("/")
    }



    const checkVerify = () => {
        const hasDeniedPermission = sessionStorage.getItem('hasWatchLeaseApp');
        const hasVerify = sessionStorage.getItem('hasVerifyLeased');
        if (!hasDeniedPermission) {
            // Show error message only the first time
            setShow(true)
            setType("")
        }
        else if (!hasVerify) {
            setShow(true)
            setType("")
        }
       
    }
    useEffect(() => {
        let fetchData = async () => {
            setloading(true)
            checkVerify()
            let { res } = await GetLeaseds()
            if (res && res?.length > 0) {
                setloading(false)
                setLeasedList(res)
            }
            else {
                setloading(false)
            }
        }
        if (delId === "") {
            fetchData()
        }
    }, [])

    useEffect(() => {
        const hasVerify = sessionStorage.getItem('hasVerifyLeased');
        if (!hasVerify) {
            setShow(true)
        }
    }, [show])


    if (show && type === "") {
        return (<DynamicPopup modalOpen={show} data={<PasswordVerify close={() => verifyUser()} />}
            close={() => setShow()}
        />)
    }

    else {

        return (
            <>
              
                {show && type === "view" && <DynamicPopup data={type === "view" ? <ViewLeased t={t} action={action} handleClose={() => handleClose()} /> : ""} close={() => setShow()} modalOpen={show} />}
                {del && <DeletePopup permition={del} Toggle={() => setDel()} callback={handleDelete} />}
                {
                 loading ? <Loader type="loadData"/> :
                !loading && leasedList?.length === 0 ? <HowItsWork handleNext={handleNext} /> :
                    <div className='lg:p-8 p-2 relative'>
                        <div className="bg-white rounded-md  ">
                            <div className="relative overflow-x-auto min-h-[400px] overflow-y-auto shadow-md sm:rounded-lg">
                                <table className="w-full  text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-white uppercase bg-gray-500">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                {t('property')}
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t('location')}
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t('rent_lbl')}
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                {t('Sec_dp')}
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-center">
                                                {t('action')}

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading && leasedList && leasedList?.length > 0 ? leasedList?.map((list, i) => (
                                            <tr key={i} className="bg-white border-b text-black ">
                                                <td scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                                                    <img src={list?.property?.image} alt="img" className="rounded-md object-cover" style={{ width: '90px', height: '90px' }} />
                                                </td>
                                                <td scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                                                    {list?.property?.complete_address}
                                                </td>
                                                <td scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                                                    ${list?.property?.rent}
                                                </td>
                                                <td scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                                                    ${list?.property?.security_deposit}
                                                </td>
                                                <td className="px-6 xl:pt-5 lg:pt-5 md:pt-5  whitespace-nowrap">
                                                    <div className=' flex items-center justify-center'>
                                                        <AiFillEye onClick={() => handleViews('view', list)} className="text-[20px] mx-2 cursor-pointer" />
                                                        <MdEdit onClick={() => handleViews('edit', list)} className='text-[20px] mx-2 cursor-pointer' />
                                                        <MdDelete onClick={() => handleDelPopup(list?.id)} className='text-[20px]  cursor-pointer text-red-500' />
                                                    </div>
                                                </td>

                                            </tr>

                                        ))
                                            :

                                            loading ? <div className='flex justify-start items-start'> <Loader type={"loadData"} /> </div> : <div className='p-5'>{t('record_not_found')}</div>

                                        }

                                    </tbody>
                                </table>
                            </div>



                        </div>

                        <Link to="/createBuilder" className=' bg-[#F79F15] text-white text-[16px] fixed bottom-10 left-[25%] rounded-md px-4 py-1'>
                            + {t('add_new_property')} </Link >
                    </div >
                }
            </>
        )
    }
}

export default LeasedLists