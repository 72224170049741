import React, { useEffect, useState } from 'react'
import { Countdown } from '../../hooks/TimeCountDown'
import { useTranslation } from 'react-i18next'
import { Truncate, Truncate2 } from '../../utils/TrucateString'
import blog from '../../assests/images/blogImg.png'
import person from '../../assests/svg/Icon ionic-ios-person.svg'
import locat from '../../assests/svg/Icon material-location-on.svg'
import desc from '../../assests/svg/Icon material-description-yellow.svg'
import { Loader } from '../../utils/loaderButton'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddRentalAppUser } from '../../Redux/UserAuthSlice/RentalSlice'
import BookAppointment from '../../components/RenatalPanelComp/Dashboard/BookAppointment'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
// import PostAppointment from '../../utils/Popup/PostAppointment'

const Flashpass = ({ properties, loading, FilterProperty }) => {
  const [savedListLoader, setSavedListLoader] = useState(false)
  const [show, setShow] = useState(false)
  const [property, setProperty] = useState({})
  const [type, setType] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAcept = (item) => {
    setShow(true)
    setProperty(item)
  }

  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }

  const handleClose = () => {
    setShow(false)
    FilterProperty()
  }

  // useEffect(() => {
  //   if(properties?.length > 0 && show === false){
  //     handleClose()
  //   }
  // },[properties?.length > 0 , show === false ])

  const { t } = useTranslation()
  return (
    <>
    {show && <DynamicPopup modalOpen={show} data={<BookAppointment  property={property} />} />}

      <div className='grid lg:grid-cols-2 md:grid-cols-2  gap-4 mt-5'>
        {!loading && properties?.map((list, index) => {
          return (
            <div key={index} className='flex '>
              <div className={`rounded-md bg-white border-2  shadow-md pb-4 mb-4 w-full p-2 relative`} >
                <div className=' w-full '>
                  <div>
                    <div className='relative'>
                      <img src={list?.image || blog} alt="blog" className='object-cover w-full h-[300px]' />
                      <div className='flex justify-between w-full items-center absolute top-0  pt-5 px-2'>
                        <div className='bg-gray-100 text-[16px] rounded-full px-2'>
                          {list?.human_readable_time}
                        </div>
                        <div className='bg-gray-100 text-[16px] rounded-md px-2'>
                          {list?.flashlane?.days > 0 ? <div className='flex items-center gap-2' >{list?.flashlane?.days}d
                            <Countdown selected={list?.flashlane?.time || "00:00:00"} />
                          </div>
                            :
                            <Countdown selected={list?.flashlane?.time || "00:00:00"} />
                          }
                        </div>
                      </div>
                      <div className="absolute bottom-5 left-2">
                        <div className='bg-orange-50 h-[30px] flex gap-2 items-center text-[16px] rounded-md px-2'>
                          <h2 className=' text-[#F79F15]'>{list?.applications || 0} {t('applications')} </h2>
                          <span className='border-[#F79F15] border h-[20px] w-[2px]'></span>
                          <h2 className=' text-[#F79F15]'>{list?.appointment || 0} {t('appointments')} </h2>
                        </div>
                      </div>
                    </div>
                    <div className=' w-full'>
                      <div className='flex justify-between  px-1 pt-3 pb-2'>
                        <div>
                          <ul>
                            <li>
                              <div className='flex items-center'>
                                <img src={person} alt="detail" className='object-cover' />
                                <h2 className='px-2 text-black text-[16px]'>{Truncate2(list?.owner_name || list?.realtor_name, 15)}</h2>
                              </div>
                            </li>
                            <li>
                              <div className='flex items-center'>
                                <img src={locat} alt="detail" className='object-cover' />
                                <h2 className='px-2 text-black text-[14px]'> {`${list?.city} ${list?.state}`}</h2>
                              </div>
                            </li>
                            <li>
                              <div className='flex items-center'>
                                <img src={desc} alt="detail" className='object-cover' />
                                <h2 className='px-2 text-black text-[14px]'>{Truncate(list?.description, 10)}</h2>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <h2 className='text-[#F79F15] text-[20px]'>${list?.rent}<span className='text-[18px] text-black'>/ Month</span></h2>
                          <p className='text-black text-[16px]'>Sec Deposit ${list?.security_deposit}</p>
                        </div>

                      </div>
                      <div className={` w-full pt-4`}>
                        <div className='px-4 flex justify-between items-center gap-4'>
                          <button onClick={() => handleMessage(list)} className='px-6 py-1 w-full rounded-md bg-blue-500 text-white'>{
                            property?.id === list?.id && savedListLoader && type === "accept" ? <div className='flex items-center justify-center' ><Loader type="single" /> </div> : t('message')
                          }</button>
                          <button onClick={() => handleAcept(list)} className='px-6 py-1 w-full rounded-md bg-[#B913F0] text-white'>{
                            property?.id === list?.id && savedListLoader && type === "accept" ? <div className='flex items-center justify-center' ><Loader type="single" /> </div> : t('z_book')
                          }</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Flashpass